.table-header {
  &__w {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 16px;
    font-size: 11px;
    font-weight: $font-w-bold;
    letter-spacing: 1px;
    background-color: #f5f5f5;
    color: $c-gray;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;

    &-no-toppad {
      padding: 0px 20px;
    }
  }
}
