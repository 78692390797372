.cover-page {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  width: 400px;
  .accordion {
    border-width: 1px 1px 0px 1px;
    border-color: #d2d2d2;
    border-style: solid;
    border-radius: 0;
    &__title {
      border-bottom: 1px solid #d2d2d2;
    }
    &__body {
      border-radius: 0;
    }
  }
  &__wrapper {
    padding: 15px;
    &-logo {
      padding-bottom: 10px;
      width: 50%;
    }
    a {
      width: 87px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background-color: #f2f2f2;
      padding: 7px;
      color: #255eba;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      img {
        padding-right: 5px;
      }
    }
  }
  &__display-flex {
    display: flex;
  }
  &__form-checkbox {
    margin-top: 7px;
    margin-right: 5px;
  }
  &__checkbox {
    &-w {
      a {
        display: flex;
        align-items: center;
        border-radius: 4px;
        background-color: #f2f2f2;
        padding: 7px;
        color: #255eba;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        img {
          padding-right: 5px;
        }
      }
      padding: 19px 8px 19px 16px;
      background-color: rgb(255, 255, 255);
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      &:last-child {
        border-bottom: none;
      }
      div:last-child {
        margin-left: auto;
        padding-left: 25px;
      }
      label {
        display: flex;
        align-items: center;
        font-weight: 500;
        &:before {
          height: 16px;
          width: 16px;
          border-width: 1px;
        }
      }
      &-last {
        div:last-child {
          margin-left: 0;
        }
        border: 1px solid #d2d2d2;
        padding-bottom: 40px;
        a {
          display: flex;
          align-items: center;
          border-radius: 4px;
          background-color: #f2f2f2;
          padding: 7px;
          color: #255eba;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          img {
            padding-right: 5px;
          }
        }
      }
    }
  }
}
