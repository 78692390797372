html {
  height: 100%;
}

body {
  font-family: $font-main;
  font-weight: $font-w-light;
  background-color: #f4f4f4;
  position: relative;
}

.pdf-only {
  display: none;
}
