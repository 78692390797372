@import './goalTiles';
@import './fundedScoreTile';
@import './goalRow';
@import './goalModal';
@import './goalChange';
@import './addGoal';
@import './financialPlanning';
@import './fundedScoreModal';
@import './selectPlanningSoftwareModal';
@import './recentComment';
@import './editCommentModal';
@import './fundedScoreRow';
@import './fundedScoreHistory';
@import './fundedScoreHistoryComments';
@import './fundedScoreGraph';
@import './linkEmoney';

.financial-goals {
  &__goals-w {
    margin-top: 18px;
  }

  &__header {
    &--results,
    &--description,
    &--name {
      flex: 2;
    }

    &--funded-name {
      flex: 1;
    }

    &--toggle {
      flex: 1;
      text-align: right;
    }

    &--updated,
    &--amount {
      flex: 1;
      text-align: right;
    }

    &--period {
      flex: 1;
      text-align: right;
    }

    &--actions {
      flex: 1;
      text-align: right;
    }
  }
}
