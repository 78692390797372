.funded-score-edit-comment {
  p {
    margin: 10px auto;
    color: $c-nero;
  }

  &__graph {
    font-size: 14px;
    line-height: 16px;
    border-bottom: solid 1px $c-gray-light;
    margin: 24px 24px;
  }

  &__content {
    margin: 24px 24px;
    textarea {
      width: 100%;
      height: 95px;
      font-size: 14px;
      color: $c-nero;
      border-radius: 6px;
      border: 1px solid #d2d2d2;
      padding: 5px;
      font-family: $font-main;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px 24px;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn.btn__action:not(:first-of-type) {
        margin-left: 5px;
      }
    }
  }
}
