@import 'mm-results';
@import 'mm-graphic';
@import 'mm-description';
@import 'mm-history';
@import 'mm-add';
@import 'mm-modal';

.moneymind {
  display: flex;

  &-heading {
    &__plus {
      margin: 0 5px;
      margin-top: -3px;
    }
  }
}
