.holdings {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 25px 0 15px 0;

    &-left {
      &-title {
        font-size: 20px;
        padding-right: 20px;
      }

      &-update {
        font-size: 12px;
        color: $c-gray;
      }
    }

    &-right {
      &-toggle {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        &-link {
          margin-right: 5px;
        }
      }
    }
  }

  &__table-header {
    display: flex;
    font-size: 11px;

    div {
      margin: auto 0;
    }

    &-label {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    &-symbol {
      width: 15%;
    }

    &-security {
      width: 40%;
    }

    &-allocation {
      width: 20%;
    }

    &-value {
      width: 15%;
    }

    &-actions {
      width: 10%;
    }

    &-arrow {
      &--down {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $c-gray;
        margin: 5px;
      }

      &--up {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $c-gray;
        margin: 5px;
      }
    }
  }

  &__override-modal {
    &-error {
      color: #f12938;
    }
  }

  &__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin-top: 24px;

    li {
      margin-right: 4px;
      cursor: pointer;
      height: 24px;
      width: 24px;
      border: 1px solid #d2d2d2;
      border-radius: 2px;
      background-color: #ffffff;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border-color: $c-blue-light;
      }
    }

    span {
      margin: 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 1px solid transparent;

      &:first-child {
        margin-left: 0;
      }
    }

    &-section:hover {
      border: 1px solid $c-blue-light;
      border-radius: 2px;
    }

    &-show-all {
      font-size: 14px;
      color: $c-blue;
    }
  }
}
