.hc-comment-modal {
  p {
    margin: 10px auto;
    color: $c-nero;
  }

  &__content {
    padding: 24px;

    textarea {
      width: 100%;
      height: 95px;
      font-size: 14px;
      font-family: $font-main;
      color: $c-nero;
      border-radius: 6px;
      border: 1px solid #d2d2d2;
      padding: 5px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px 24px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn.btn__action:not(:first-of-type) {
        margin-left: 5px;
      }
    }
  }
}
