.hc-new-meeting {
  &__comment {
    height: 100%;
    resize: none;
    border-radius: 4px;
    border: 1px solid $c-gray-light;
    font-family: $font-main;
    font-size: 14px;
    word-break: break-word;
    &::placeholder {
      font-size: 14px;
      padding: 3px;
    }
    &-label {
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 14px;
    }
    &-w {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0px 0;
    }
    &-limit {
      cursor: pointer;
      color: #8a8888;
      font-size: 14px;
      text-align: right;
      margin: -20px 4px 4px 0px;
      &-over {
        color: $c-red-dark;
      }
    }
  }
  &__row {
    flex: 1;
    margin: 0 24px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
  &__card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    height: 120px;
    width: 200px;
    min-width: 200px;
    border-radius: 6px;
    border: 1px dashed $c-gray-light;
    transition: box-shadow 300ms;
    background-color: #f4f4f4;
    flex-direction: column;
    &:hover {
      border: 1px solid $c-gray-light;
    }
    &-index {
      color: $c-nero;
      opacity: 0.3;
      font-size: 32px;
      line-height: 38px;
    }
    &-select-text {
      color: #255eba;
      font-size: 14px;
      line-height: 16px;
    }
    &-w {
      border-radius: 6px;
      width: 200px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.45);
      }
    }

    &-title {
      font-size: 16px;
      color: white;
      font-weight: $font-w-bold;
      line-height: 19px;
      text-align: center;
    }

    &-icon {
      position: absolute;
      bottom: 5px;
      right: 5px;
      opacity: 0.5;

      svg path {
        fill: $c-white;
      }
    }
  }
  &__score-comment-text-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    > textarea {
      height: 100%;
    }
    &--error {
      @extend .hc-new-meeting__score-comment-text-area;
      border: 1px solid #f12938;
    }
  }
}
