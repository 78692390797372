$c-name: '.header';

#{$c-name} {
  position: fixed;
  height: 50px;
  z-index: $header-nav-zindex;
  top: 0;
  right: 0;
  left: 0;
  padding: 4px 10px;
  border-bottom: 1px solid #d2d2d2;
  background-color: $c-blue;

  &__w {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-w__left,
  &-w__right {
    display: flex;
    align-items: center;
    flex: 0 auto 50%;

    svg path {
      fill: white;
    }
  }

  &__divider {
    position: relative;
    padding: 12px 0;
    margin: 0 12px;

    &:after {
      content: '';
      position: absolute;
      height: 24px;
      width: 1px;
      top: 0;
      opacity: 0.4;
      background-color: white;
    }
  }
  &-w__left {
    justify-content: flex-start;

    & .header__divider {
      display: none;
      @media (min-width: 450px) {
        display: inline-block;
      }
    }
  }
  &-w__right {
    justify-content: flex-end;
  }

  &__logo {
    display: inline-block;
    max-width: 165px;
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }

  &__icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    > span {
      color: white;
      font-family: inherit;
      margin-left: 5px;
      font-size: 14px;
      font-weight: $font-w-light;
      line-height: 1;
    }
  }

  &__advisor-messages {
    padding: 0 8px;
  }

  &__help {
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    padding: 8px;
    background-color: transparent;
    transition: background-color 0.3s;

    &--active {
      background-color: #4a78cf;
    }

    img {
      max-width: 18px;
    }
  }

  &__profile {
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    padding: 4px 6px;
    background-color: transparent;
    transition: background-color 0.3s;

    &--active {
      background-color: #4a78cf;
    }

    &-icon span {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 5px;
      background-color: #4a78cf;
      border: 1px solid #d2d2d2;
      box-shadow: 0 0 0px 0px white, 0 0 0 0px #d3d3d3;
      transition: box-shadow 0.3s;

      svg {
        position: absolute;
      }
    }
  }

  &__clients {
    border-radius: 6px;
    padding: 6px;
    background-color: transparent;
    transition: background-color 0.3s;

    &:hover {
      background-color: #4a78cf;
    }

    img {
      max-width: 18px;
    }
  }

  &__notification {
    &-icon {
      position: relative;
      border-radius: 6px;
      padding: 6px;
      margin-right: 5px;
      background-color: transparent;
      transition: background-color 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #4a78cf;
      }
      &--unread {
        position: absolute;
        background-color: #f12938;
        color: white;
        padding: 3px;
        justify-content: center;
        border-radius: 50%;
        font-size: 12px;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        top: 0px;
        right: 0px;
        font-weight: 500;
      }
      img {
        max-width: 28px;
      }
    }
    &-message {
      font-size: 14px;
      color: $c-nero;
      line-height: 16px;
    }
    &-name {
      font-weight: $font-w-bold;
    }
    &-body {
      margin-left: 10px;
    }
    &-date {
      color: $c-gray;
      font-size: 12px;
      font-weight: $font-w-bold;
      line-height: 15px;
      margin-top: 8px;
    }
    &-dropdown {
      position: absolute;
      z-index: 900;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      right: 0;
      border: 1px solid #d2d2d2;
      border-radius: 6px;
      box-shadow: 0 4px 7px 0 rgba(184, 184, 184, 0.5);
      top: 55px;
      width: 400px;
      max-height: 500px;
      cursor: initial;
      &-null {
        padding: 16px;
        text-align: center;
        font-size: 16px;
        color: $c-blue;
        font-weight: 500;
      }
      &-list {
        box-shadow: 0px 1px 0px $c-gray-light;
        max-height: 466px;
        overflow-y: scroll;
        width: 100%;
      }
      &-item {
        padding: 14px;
        display: flex;
        flex-direction: row;

        &:not(:last-child) {
          border-bottom: 1px solid #d2d2d2;
        }
        &--unread {
          background-color: #f2f5fa;
        }
      }
      &-name {
        font-weight: 700; // $font-w-bold;
      }
      &-avatar {
        color: $c-white;
        background-color: $c-blue-light;
        border: 1px solid $c-white;
        border-radius: 18px;
        height: 36px;
        min-width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        img {
          margin-right: 0;
        }
      }
      &-mark-read {
        height: 34px;

        .btn__link {
          font-weight: 500;
          text-decoration: none;
        }
      }
      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &:after {
        top: -19px;
        right: 11px;
        border-color: transparent transparent white transparent;
        border-width: 10px;
      }

      &:before {
        top: -22px;
        right: 10px;
        border-color: transparent transparent #d2d2d2 transparent;
        border-width: 11px;
      }
    }
    &-contact-avatar {
      flex: 0 0 63px;
      display: flex;
      justify-content: flex-start;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 900;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    top: 60px;
    right: 0;
    width: 150px;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    box-shadow: 0 4px 7px 0 rgba(184, 184, 184, 0.5);

    &--xl {
      width: 165px;
    }

    img {
      margin-right: 8px;
      max-width: 20px;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      top: -19px;
      right: 11px;
      border-color: transparent transparent white transparent;
      border-width: 10px;
    }

    &:before {
      top: -22px;
      right: 10px;
      border-color: transparent transparent #d2d2d2 transparent;
      border-width: 11px;
    }

    li {
      width: 100%;
      flex: 0 1 100%;

      &:not(:last-child) {
        border-bottom: 1px solid #d2d2d2;
      }

      a,
      .header__dropdown-link {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 6px;
        text-decoration: none;
        transition: background-color 0.3s;

        &:hover {
          cursor: pointer;
          background-color: #f4f4f4;
        }

        span {
          color: $c-nero;
          font-family: inherit;
          font-size: 14px;
          font-weight: $font-w-light;
        }
      }
    }
  }
}
