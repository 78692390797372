.delete-goal-modal {
  &__text {
    padding: 24px;
    font-size: 14px;
    line-height: 24px;
    color: #231f20;
  }
  &__button-w {
    display: flex;
    justify-content: flex-end;
    padding: 4px 24px 16px 0;
  }
}
