.hc-card-row {
  &__wrapper {
    flex: 1;
  }
  &__w {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    &--no-padding {
      padding: 10px 0px 20px;
      margin-right: 20px;
    }

    &--border {
      border-bottom: solid 1px $c-gray-light;
    }
  }

  &__tasks {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 120px;
  }

  &__comment {
    font-size: 14px;
    font-family: $font-main;
    line-height: 18px;
    padding: 24px;
    text-align: left;
    word-break: break-word;
    &--null {
      color: $c-gray;
    }
  }
  &__score {
    display: flex;
    flex-direction: column;
  }
}
