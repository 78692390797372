.file {
  &__download-w {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &__download-bar {
    flex: 1;
    background-color: $c-icons;
    width: 150px;
    border-radius: 5px;
    height: 8px;
    overflow: hidden;
    margin-left: 5px;

    &--active {
      height: 100%;
      background-color: #255eba;
    }
  }

  &__created-date {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    color: $c-titles;
    font-size: 14px;
    line-height: 16px;
  }
}
