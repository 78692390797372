.strat-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.client-accounts {
  display: flex;

  label {
    cursor: pointer;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
  }

  &__account-wrapper {
    display: flex;
    flex-direction: space-between;
    margin: 10px 0px;
    cursor: pointer;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #d2d2d2;
    width: 360px;
    height: 145px;

    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    }

    &-left {
      width: 60%;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    &-right {
      width: 40%;
      display: flex;
      justify-content: flex-end;

      &-icons {
        width: 85%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        img {
          width: 20px;
          height: 20px;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }

  &__radio {
    padding-right: 8px;
  }

  &__line {
    width: 36px;
    display: flex;
    flex-direction: column;

    div {
      flex: 1;
    }
  }

  &__strategy {
    display: flex;
    flex: 1;
    border-radius: 6px;
    flex-direction: column;
    margin: 10px 0px;
    height: 145px;
    min-width: 360px;
    position: relative;
    background-color: white;

    &-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: default;
    }

    &-default {
      border: 2px dashed #b9bbbc;
      color: #898a8d;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    &-unavailable {
      @extend .client-accounts__strategy-default;
      border: 1px solid #f12938;
      position: relative;
      .strategy__data-image {
        position: absolute;
        right: 2px;
        top: 0px;
      }
    }

    &-over {
      background-color: #f2f5fa;
      border: 1px solid #255eba;
      color: #231f20;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-align: center;
    }

    &-selected {
      display: flex;
      justify-content: center;
      border: 1px solid #255eba;

      .strategy__data {
        display: flex;
        padding-top: 8px;

        &-image {
          position: absolute;
          right: 2px;
          top: 0px;
        }

        &-reg {
          flex: 2;
          padding: 0px 8px 0px 14px;
        }

        &-amount {
          flex: 2;
        }

        &-perc {
          flex: 1;
          padding: 0px 14px 0px 8px;
        }

        &-header {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          border-bottom: 1px solid rgb(210, 210, 210);
          padding: 15px 15px 8px 15px;

          img {
            padding-right: 10px;
          }

          &-name {
            color: #231f20;
            font-size: 14px;
            line-height: 16px;
            flex: 2;
            // padding-right: 20px;
          }

          &-cash__wrapper {
            display: flex;
            flex: 3;
            text-align: right;

            p {
              color: #255eba;
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              text-align: right;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }

  &__strategy-wrapper {
    display: flex;
    flex: 1;
  }

  &__name {
    color: #231f20;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 5px;
  }

  &__acc-number {
    color: #8a8888;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 10px;
  }

  &__amount {
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 5px;
  }

  &__reg-type {
    span {
      padding: 1px 12px;
      background-color: $c-blue-lightest;
      color: #231f20;
      font-size: 10px;
      line-height: 12px;
      border-radius: 4px;
    }
  }
}

.round {
  position: relative;
  margin-right: 8px;
  align-self: center;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 16px;
  width: 16px;
  left: 0;
  position: absolute;
  top: 0;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 4px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 8px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #8ccc33;
  border-color: #8ccc33;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.border-top {
  border-top: 2px solid #d2d2d2;
}

.border-bottom {
  border-bottom: 2px solid #d2d2d2;
}

.border-right {
  border-right: 2px solid #d2d2d2;
}

.no-border-right {
  border-right: 0px;
}

.border-left {
  border-left: 2px solid #d2d2d2;
}
