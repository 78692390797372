.upload-modal {
  position: relative;
  margin: 24px;
  padding: 81px 0;
  border: 2px dashed #cccccc;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__icon {
    width: 96px;
    color: $c-blue;
  }
  &__text {
    margin-top: 5px;
    color: #231f20;
    font-size: 20px;
    font-weight: $font-w-bold;
  }
  &__input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  &__divider-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8a8888;
    font-size: 15px;
    margin-bottom: 15px;
  }
  &__line {
    height: 1px;
    background-color: #cccccc;
    width: 120px;
    margin: 0 16px;
  }
  &__button-w {
    margin: auto;
    margin-bottom: 32px;
    height: 32px;
    width: 184px;
    border-radius: 4px;
    background-color: #255eba;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  &__button {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
  }
}

.edit-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 375px;
  &__editor {
    margin-top: 24px;
    position: relative;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    width: 100% !important;
    max-width: 600px !important;
  }
  &__overlay {
    position: absolute;
    height: 300px;
    width: 300px;
    pointer-events: none;
    margin-bottom: 25px;
    opacity: 0.5;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    &--horizontal-line {
      position: absolute;
      margin: 0;
      width: 100%;
      top: 33%;
      background-color: #cdcdcd;
    }
    &--horizontal-line2 {
      position: absolute;
      margin: 0;
      width: 100%;
      top: 66%;
      background-color: #cdcdcd;
    }
    &--vertical-line {
      position: absolute;
      margin: 0;
      height: 100%;
      left: 33%;
      background-color: #cdcdcd;
    }
    &--vertical-line2 {
      position: absolute;
      margin: 0;
      height: 100%;
      left: 66%;
      background-color: #cdcdcd;
    }
  }
  &__message {
    margin-top: 10px;
    font-size: 14px;
  }
  &__controls {
    height: 64px;
    border-top: 1px solid #d2d2d2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__delete-btn {
    cursor: pointer;
    margin-left: 22px;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
  &__delete-icon {
    color: $c-gray;
    margin-right: 6px;
  }
  &__delete-text {
    color: #255eba;
    position: relative;
    top: -7px;
    font-size: 14px;
    font-weight: $font-w-bold;
  }
  &__btn-w {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 24px;
    font-size: 14px;
  }
  &__image-result {
    margin: auto;
    border-radius: 50%;
    height: 200px;
    width: auto;
    border: 1px solid gray;
    margin-top: 50px;
  }
}
