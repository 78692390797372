.add-goal {
  &-w {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 14px;
    color: $c-active-btn;
    font-weight: $font-w-bold;
    align-items: center;
  }

  &__btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
}
