.message-thread-list {
  &-w {
    overflow-y: auto;
    flex: 1;
  }

  &-error,
  &-loading {
    padding: 8px 16px;
  }

  &-item {
    &-w {
      padding: 14px;
      border-bottom: 1px solid $c-gray-light;

      &--dashboard {
        padding: 15px;
      }

      &:hover {
        background-color: $c-blue-lightest;
        cursor: pointer;
      }
    }

    &__row {
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &--multItem {
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    &__subject {
      font-weight: 500;
      color: $c-nero;
      line-height: 16px;
      margin-bottom: 7px;
      font-size: 15px;
      padding-right: 5px;
      word-break: break-word;

      &--dashboard {
        font-size: 16px;
        width: 100%;
        max-width: 477px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 19px;
        margin-bottom: 5px;
      }
    }

    &__household,
    &__date {
      font-weight: 400;
      font-size: 12px;
      color: $c-gray;

      &--dashboard {
        color: $c-nero;
      }

      &--emphesis {
        font-weight: 500;
      }
    }

    &__dot {
      font-size: 18px;
      padding: 0 5px;
    }

    &--selected {
      background-color: $c-blue-lightest;
      cursor: pointer;
    }
  }
}
