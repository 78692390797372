.quick-action-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__item {
    color: $c-blue;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &-img-w {
      max-width: 24px;

      img {
        width: 100%;
      }
    }
    &-link {
      color: $c-blue;
    }

    &-label {
      padding-left: 6px;
      font-size: 14px;
      font-weight: $font-w-bold;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &--disabled {
        color: gray;
        padding-left: 6px;
        font-size: 14px;
        font-weight: $font-w-bold;
      }
    }

    &-tooltip img {
      margin: 2px 0 0 4px;
    }
  }
}
