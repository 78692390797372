$color1: #f4f4f4;
$color2: #255eba;

.form-group {
  width: 100%;
  margin-right: 24px;
  margin-bottom: 10px;

  &__error-box {
    height: 10px;
  }

  &__max-length {
    font-size: 12px;
    float: right;
  }
}

.w-50 {
  width: 50%;
}

.w-90 {
  width: 92%;
}

.form-inline {
  display: flex;
  justify-content: space-between;
}

.form-input {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 9px 10px;
  color: #231f20;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
  display: inline-block;

  &__readonly {
    background: #f0f0f0 !important;
    color: #8a8a8a;
    border: 1px solid #c2c2c2;
    box-shadow: none !important;
  }

  &__no-resize {
    resize: none;
  }

  &__padding {
    padding-left: 20px;
  }

  &__changed,
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
  }

  &__money {
    position: relative;

    &:before {
      content: '$';
      color: $c-gray;
      position: absolute;
      left: 13px;
      bottom: -9px;
      transform: translate(-50%, -50%);
    }
  }

  &__percentage {
    position: relative;

    input {
      padding-left: 10px;
    }

    &:before {
      content: '%';
      color: $c-gray;
      position: absolute;
      right: 17px;
      bottom: -7px;
      transform: translate(-50%, -50%);
    }
  }
}

.form-input-Error {
  border-color: red;
  box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0);
  font-size: 12px;

  &__message {
    font-size: 12px;
    color: red;
    font-style: italic;
  }
}

.date-input-Error {
  border: 1px solid red;
}

.form-radio {
  margin-left: 5px;

  input[type='radio'] {
    display: none;

    + .radio-label {
      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken($color1, 25%);
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        top: -0.2em;
        margin-right: 0.5em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.form-label {
  color: #231f20;
  font-size: 14px;
  line-height: 14px;
  display: block;
  padding-bottom: 6px;

  &_mandatory {
    font-size: 14px;
    cursor: pointer;

    > ::after {
      content: '*';
      font-size: 14px;
      position: relative;
      left: 2px;
      color: $c-red-dark;
    }
  }
}

.form-label-radio {
  color: #231f20;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  display: inline-block;
  padding-bottom: 10px;
}

.form-cancel-btn {
  color: #898a8d;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-right: 24px;
  cursor: pointer;
}

.form-submit-btn {
  height: 32px;
  width: 84px;
  border-radius: 4px;
  background-color: #255eba;
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  margin-right: 24px;
}

.modal-body {
  padding: 24px 0px 24px 24px;
}

.data-right {
  text-align: right;
  margin-right: 24px;
}
