.task {
  &__null {
    &-w {
      position: relative;
    }

    &-row {
      display: grid;
      grid-template-columns: 3.5fr 1fr minmax(50px, 120px) 1.3fr minmax(
          50px,
          100px
        );
      grid-column-gap: 10px;
      padding: 20px 15px;
    }

    &-text {
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 42%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h3 {
        color: $c-blue;
        margin-bottom: 12px;
        font-weight: $font-w-bold;
        font-size: 16px;
      }

      p {
        font-size: 12px;
      }
    }

    &-search-text {
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 42%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      h3 {
        color: $c-blue;
        margin: 15px 0;
        font-weight: $font-w-bold;
        font-size: 16px;
      }

      span {
        font-size: 12px;
        color: #8a8888;
        font-size: 14px;
        text-align: center;
        max-width: 340px;
      }
    }

    &-desc,
    &-assigned,
    &-date,
    &-status,
    &-show {
      height: 15px;
      background-color: #f3f3f3;
      filter: blur(4px);
    }

    &-desc {
      width: 300px;
    }

    &-status {
      width: 60px;
    }

    &-assigned {
      width: 140px;
    }

    &-date {
      width: 60px;
    }

    &-show {
      width: 60px;
    }
  }
}
