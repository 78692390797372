.im-benchmarks {
  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 30px;
    line-height: 34px;
  }

  &__notification {
    display: inline-block;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    width: 100%;
    &-title {
      margin-top: 34px;
    }
    &-message {
      margin-top: 12px;
      font-size: 16px;
      line-height: 24px;
      span::before {
        content: '\A';
        white-space: pre;
      }
    }
  }

  &__scroller {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 12px;
  }

  &__container {
    cursor: pointer;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 16px 16px;
    min-height: 256px;
    width: 224px;
    border: solid 1px #e6ecf3;
    margin: 0px 6px;

    &--opaque {
      opacity: 0.3;
    }
    &--active {
      border: 1px solid #22263f;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    }
    &--hovering {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    }
  }

  &__checkmark {
    text-align: right;
    position: absolute;
    right: 0;
    span {
      height: 24px;
      width: 24px;
      display: inline-block;
      border-radius: 50%;
      background-color: #1ea382;
    }
  }

  &__legend {
    display: flex;
    flex-direction: column;
    width: 192px;
    border-top: solid 1px #e6ecf3;
  }
  &__bullet {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    span {
      margin-top: 13px;
      display: inline-block;
      font-size: 12px;
      &:last-child {
        flex-grow: 3;
        text-align: right;
      }
    }
  }

  &__radio {
    &-custom {
      span:first-of-type {
        background: white;
      }
    }
  }

  &__dot {
    position: relative;
    top: 2px;
    height: 12px;
    width: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
    &--equity {
      background-color: #3b98e2;
    }
    &--fixed {
      background-color: #61b75e;
    }
    &--cash {
      background-color: #e4e6f5;
    }
    &--alternative {
      background-color: #f4a83e;
    }
    &--other {
      background-color: #89579d;
    }
  }
}
