.back-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  &__icon {
    path {
      fill: $c-blue;
    }
  }
  &__text {
    color: $c-blue;
    font-weight: $font-w-bold;
  }
}
