.hc-send-link-modal {
  padding: 21px 34px 43px 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__dropdown-w {
    width: 100%;
    height: 44px;
  }
  &__link-text {
    margin-top: 23px;
    margin-bottom: 32px;
    color: $c-nero;
    font-size: 18px;
    font-weight: $font-w-bold;
  }
  &__content-w {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__button-w {
    text-align: center;
    width: 160px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 160px;
    width: 160px;
    border: 1px solid $c-gray-light;
    border-radius: 6px;
    background-color: $c-white;
    text-align: center;
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }
  &__button-icon {
    height: 100px;
  }
  &__button-text {
    margin-top: 6px;
    font-size: 14px;
    color: $c-nero;
    font-weight: $font-w-bold;
  }

  &__primary-status {
    font-size: 12px;
    color: $c-gray;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  &__secondary-status {
    font-size: 12px;
    color: $c-gray;
  }
}
