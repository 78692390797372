.household {
  height: 100%;

  &-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header {
    position: fixed;
    z-index: 808;
    height: 37px;
    top: 51px;
    right: 0;
    left: 0;
    background-color: white;
    padding: 4px 8px;
    border-bottom: 1px solid #d2d2d2;
    margin-top: -1px;

    nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    img {
      max-width: 24px;
      margin-right: 5px;
    }
  }

  &__menu {
    cursor: pointer;
    margin-right: 12px;
    img {
      max-width: 24px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    &_refresh {
      &:hover {
        cursor: pointer;
      }
      svg {
        path {
          fill: $c-gray;
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
    &-gear {
      margin-left: 8px;
    }
  }

  &__name {
    color: $c-nero;
    font-weight: $font-w-bold;
    font-size: 16px;
  }

  &__body {
    margin-top: 86px;
    display: flex;
    min-height: 100%;

    .nav-menu--collapsed + &-content-w,
    &-content-w {
      transition: all 300ms;
      width: calc(100% - 42px);
      margin-left: 42px;
      &--spacer {
        transition: all 300ms;
        width: calc(100% - 450px);
        margin-right: 450px;
      }
    }

    @media ($media-medium) {
      &-content-w {
        width: calc(100% - 290px);
        margin-left: 290px;
        margin-right: unset;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
    }
  }
}
