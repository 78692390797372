.goal-change-modal {
  &__header {
    background-color: $c-white-smoke;

    &--name {
      flex: 1.5;
      text-align: left;
    }

    &--period {
      flex: 1;
      text-align: left;
    }

    &--amount {
      flex: 1;
      text-align: right;
    }
  }

  &__details {
    width: 100%;

    &--select-goal {
      margin-top: 15px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &--buttons {
      margin-top: 30px;
    }
  }
}
