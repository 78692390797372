.hc_null {
  &-threedot {
    width: 100%;
  }

  &-box {
    width: 33px;
    height: 33px;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: #d3d2d2;
  }

  &-more {
    margin-right: 65px;
    width: 24px;
    opacity: 0.4;
  }

  &-lorem {
    width: 100%;
    text-align: center;

    span {
      margin-top: 8px;
      background-color: #d3d2d2;
      display: block;
      height: 10px;
    }
  }

  &-graph {
    height: 100%;
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  &-card {
    background-color: #f5f5f5;
  }
}
