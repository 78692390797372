.data-right {
  padding-top: 30px;
}

.delete-body {
  color: #231f20;
  font-size: 14px;
  line-height: 18px;
}

.proposal-work {
  &__message {
    &-wrapper {
      display: flex;
      padding-bottom: 18px;
    }

    border-radius: 3px;
    display: flex;
    align-items: center;
    color: #231f20;
    font-size: 14px;
    padding: 0px 8px;
    line-height: 16px;
    background-color: rgba(138, 136, 136, 0.1);
    border: 1px solid #8a8888;

    &-error {
      background-color: rgba(241, 41, 56, 0.1);
      color: #f12938;
      border: 1px solid #f12938;
    }

    &__icon {
      padding: 4px 0px;
      padding-right: 10px;
    }

    &__link {
      font-weight: 500;
      line-height: 17px;
      text-decoration: underline;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    .accordion__title {
      flex: 1 1;
      min-width: 213px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &--current {
      display: flex;
      align-items: center;
    }

    &--border {
      border-bottom: solid #d2d2d2 1px;
      justify-content: space-between;
    }
  }

  &__add-account {
    cursor: pointer;
    margin-right: 16px;

    a {
      align-items: center;
      display: flex;
    }

    span {
      color: #255eba;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
    }
  }

  &__title-text {
    display: flex;
    align-items: center;

    img {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }

  &__title-arrow {
    position: relative;
    margin-top: unset;
    margin-left: 10px;
  }

  &__header {
    border-bottom: 1px solid #d2d2d2;

    &-text {
      color: #898a8d;
      font-size: 14px;
      line-height: 16px;
    }

    &-options {
      display: flex;
      justify-content: space-around;
      width: 60%;
      color: #255eba;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;

      div {
        cursor: pointer;
      }

      &-disabled {
        opacity: 0.5;
      }
    }
  }

  &__account-wrapper {
    padding: 24px;
    background-color: #fafafa;
    border-radius: 6px;
  }

  &__proposed-w {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fafafa;
    border-radius: 6px;
    min-height: 282px;
  }

  &__accordion-w {
    height: 100%;
    width: 100%;
  }
}
