.client-score-history-graph {
  &__chart-tooltip {
    position: absolute;
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
    padding: 0px 4px 0px 4px;

    &-number {
      color: white;
      height: 22px;
      width: 26px;
      border-radius: 50%;
      line-height: 22px;
      vertical-align: middle;
      text-align: center;
      margin-top: -4px;
      font-size: 14px;
      &-empty {
        background-color: $c-gray-light;
      }
      &-primary {
        background-color: $c-green-lighter;
      }
      &-secondary {
        background-color: $c-yellow;
      }
    }

    &--left {
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      &::after {
        display: block;
        content: '';
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid white;
      }
    }

    &--right {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      &::after {
        display: block;
        content: '';
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid white;
      }
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      font-size: 14px;
      line-height: 17px;
      color: $c-nero;
      font-weight: 700;
      margin-top: 10px;
    }
    &-label {
      display: flex;
      flex-direction: column;
      padding-right: 5px;
    }
    &-name {
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      color: $c-nero;
    }
    &-date {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: $c-gray-light;
    }

    &-number {
      margin-bottom: 5px;
      padding-top: 2px;
      height: 26px;
    }
  }
}
