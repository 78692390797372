.holdings-row {
  &__w {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }

  &__position {
    display: flex;
    font-size: 14px;

    p {
      padding: 0 5px 0 0;
      margin: auto 0;
    }

    &-symbol {
      width: 15%;
    }

    &-security {
      width: 40%;
    }

    &-allocation {
      display: flex;
      align-items: center;
      padding: 0px 50px 0 0;
      width: 20%;
    }

    &-value {
      width: 15%;

      p {
        font-weight: bold;
      }
    }

    &-actions {
      width: 10%;
    }
  }
}
