.ivf-geography {
  &__option {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8px;
    &-checkbox {
      position: absolute;
      top: 16px;
      right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $c-gray-light;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #f5f5f5;
      img {
        width: 16px;
        display: none;
      }
      &--checked {
        background-color: $c-green;
        border: 1px solid $c-green;
        img {
          display: flex;
        }
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      border: 1px solid $c-gray-light;
      border-radius: 6px;
      padding: 24px 56px;
      position: relative;
      &:hover {
        box-shadow: 0 4px 8px 0 rgba(35, 31, 32, 0.18);
      }
      &--active {
        border: 1px solid $c-blue;
        position: relative;
      }
    }
    &-w {
      display: flex;
      width: 100%;
    }
    &-img {
      border: 1px solid $c-gray-light;
      border-radius: 2px;
      overflow: hidden;
      max-width: 140px;
    }
    &-title {
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      margin: 16px 0 4px;
    }
    &-definition {
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      color: $c-gray;
    }
    &-bar {
      width: 1px;
      height: 40px;
      background-color: $c-gray-light;
    }
  }
  &__pros-cons {
    flex: 1;
    display: flex;
    flex-direction: column;
    &-w {
      min-height: 220px;
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 32px 40px;
      border: 1px solid $c-gray-light;
      border-radius: 6px;
      justify-content: center;
    }
    &-divider {
      width: 1px;
      height: 52px;
      background-color: $c-gray-light;
      margin: 0 40px;
      align-self: center;
    }
    &-title {
      color: $c-blue;
      font-size: 18px;
      font-weight: $font-w-bold;
      line-height: 23px;
      margin-bottom: 4px;
    }
    &-description {
      list-style-type: disc;
      margin: 4px 0;
      font-size: 15px;
      line-height: 22px;
      color: $c-nero;
    }
  }
}
