.nds-modal {
  &__content {
    padding: 20px;
    font-size: 16px;
    line-height: 25px;
    color: $c-nero;
  }

  &--button {
    padding: 20px;

    .btn {
      padding: 16px 24px;
    }
  }

  .tile-w {
    padding: 20px;
  }

  .tile__header {
    font-size: 20px;
    border-bottom: none;
  }
}
