$minsize-container: 600px;
// Tablet 720px area
$media-medium: 'min-width: 900px';
// Desktop 1200px - 1920px
$media-large: 'min-width: 1200px';

$media-xxsmall-height: 568px;
$media-xsmall-height: 667px;
$media-medium-height: 736px;
$media-large-height: 812px;
$media-xlarge-height: 900px;
$media-xxlarge-height: 1080px;

$media-xsmall-width: 300px;
$media-small-width: 550px;
$media-medium-width: 768px;
