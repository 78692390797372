.goal-modal {
  &__form-row {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
  }

  &__form-column {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    flex: 1;
  }

  &__form-label {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 5px;
    font-weight: $font-w-bold;
    line-height: 14px;
    font-size: 14px;
  }

  &__select {
    margin-top: 6px;

    .goal-modal__control {
      flex-wrap: nowrap;
      background-color: $c-white;
      border-color: $c-borders;
      border-radius: 6px;
    }

    &--error {
      font-size: 14px;
      margin-top: 6px;
      border-radius: 6px;
      border: 1px solid red;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__date-picker {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
    margin-top: 6px;
    border-radius: 6px;
    border: 1px solid $c-borders;

    input {
      border-radius: 5px;
    }
  }
}
