.pop-over {
  &-w {
    position: absolute;
    top: 74%;
    left: -25%;
    z-index: 600;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 350px;
    height: auto;

    &__modal {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      background: $c-blue;
      border-radius: 4px;
      color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
      padding: 15px;
      margin-top: -20px;
    }

    &__dropIcon {
      background: url('../../images/icons/blue-water-drop.png') no-repeat center;
      background-size: contain;
      width: 22px;
      height: 32px;
    }

    &__body {
      padding: 10px 10px 22px 10px;
      font-size: 14px;
      line-height: 1.3;
    }

    &__close {
      background: url('../../images/icons/ic_close.png') no-repeat center;
      background-size: contain;
      width: 18px;
      height: 18px;
      position: absolute;
      right: 5px;
      top: 5px;

      &:hover {
        background-color: #4a78cf;
        cursor: pointer;
      }
    }
  }
}
