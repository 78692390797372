.strategy-search {
  &__filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__filters-w {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__filter-buttons {
    &-w {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    &-clear {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #22263f;
      font-size: 16px;
    }

    &-counter {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: #2178c4;
      border-radius: 22px;
      padding: 3px 5px;
      min-width: 24px;
      min-height: 20px;
      margin-left: 8px;
    }

    &-cancel-apply-w {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      padding-left: 16px;
      font-size: 16px;

      &:before {
        content: '';
        position: absolute;
        left: 0px;
        height: 40px;
        width: 1px;
        background-color: #e6ecf3;
      }

      &--popup {
        justify-content: flex-end;
        margin: 0;
        padding: 0;

        &:before {
          display: none;
        }
      }
    }

    &-cancel {
      cursor: pointer;
      color: #2178c4;
      // margin-right: 16px;
      padding: 10px 20px;
    }

    &-apply {
      cursor: pointer;
      color: white;
      background: #2178c4;
      border-radius: 4px;
      padding: 10px 20px;
    }

    &-disabled {
      color: rgb(187, 187, 187);
      padding: 10px 20px;
    }
  }

  &__filter {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 200px;
    box-shadow: 0px 2px 0px #e4e5e7;
    border: 1px solid #e4e5e7;
    padding: 10px 15px;
    line-height: 1;
    border-radius: 4px;
    cursor: pointer;
    color: #22263f;
    transition: border 300ms;

    &--spacer {
      box-shadow: none;
      border: none;
      cursor: none;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &--active-filters {
      border: 1px solid transparent;
      background: #2178c4;
      color: white;
    }

    &--open {
      border: 1px solid #2178c4;
      &-paddleft {
        left: 220px;
      }
    }

    &-count {
      margin: 0 3px;
    }
  }

  &__filter-arrow {
    transform: rotate(90deg);
    color: #2178c4;
    transition: transform 300ms, color 300ms;

    &--open {
      transform: rotate(270deg);
    }

    &--active-filters {
      color: white;
    }
  }
}
