.holdings-card {
  &__w {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto auto auto auto;
    margin: auto;
    position: relative;
  }

  &__card {
    padding: 15px;
    background-color: $c-white;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-text {
      &-value {
        font-size: 18px;
        color: $c-titles;
        font-weight: $font-w-bold;
        padding: 10px 0;
      }

      &-overridden {
        font-size: 12px;
        color: $c-gray;
        font-weight: $font-w-light;

        &--link {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &--thin {
        font-size: 15px;
        color: $c-gray;
        font-weight: $font-w-light;
        margin-bottom: 3px;
      }

      &--right {
        text-align: right;
      }

      &--bold {
        font-size: 18px;
        color: $c-titles;
        font-weight: $font-w-bold;
        margin-bottom: 3px;
      }
    }
  }
}
