.goal-tiles {
  &__w {
    margin-bottom: 20px;
  }

  &__header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 15px;

    &-text {
      font-size: 20px;
      font-weight: 500;
      line-height: 40px;
    }
  }

  &__placeholder {
    &-w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $c-gray-light;
    }

    &-item {
      margin: 20px 25px;

      span {
        height: 10px;
        width: 80px;
        background-color: $c-gray-light;

        &:last-child {
          margin-top: 10px;
          padding: 10px 0;
          width: 56px;
        }
      }

      &-column {
        display: flex;
        flex-direction: column;
      }

      &-row {
        display: flex;
        width: 43.7%;
        justify-content: space-between;
      }
    }

    &-period {
      padding: 5px 0;
      width: 80px;
      background-color: $c-gray-light;
    }

    &-amount {
      padding: 5px 0;
      width: 50px;
      background-color: $c-gray-light;
      margin-right: 40px;
    }

    &-actions {
      padding: 12px 0;
      width: 24px;
      background-color: $c-gray-light;
    }
  }
}
