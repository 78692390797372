.ymm-history {
  &__protection {
    color: $c-protection;
  }

  &__container-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__happiness {
    color: $c-happiness;
  }

  &__commitment {
    color: $c-commitment;
  }

  &__disabled {
    color: #444;
    opacity: 0.3;
  }

  &__plus {
    margin: 0 5px 5px;
  }

  &__btn {
    background-color: $c-active-btn;
    color: $c-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;

    &--text {
      font-size: 14px;
      font-weight: $font-w-bold;
      line-height: 17px;
      margin-left: 5px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-review {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-select {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;

      label {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;

        span {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }

  &__results {
    &-w {
      display: flex;
      padding: 14px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid $c-gray-light;
      }
    }

    &-tab {
      cursor: pointer;
      font-size: 14px;
      font-weight: $font-w-bold;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100px;
      width: 100%;
      top: 5px;
      position: relative;
      padding-bottom: 10px;

      h4 {
        font-size: 14px;
        margin-right: 20px;
        font-weight: $font-w-bold;
      }
    }

    &-selected {
      color: $c-blue-dark !important;
      border-bottom: 2px solid $c-blue-light;
    }

    &-icon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 250px;
      width: 100%;
    }

    &-results {
      display: flex;
      align-items: center;
      max-width: 480px;
      width: 100%;
    }

    &-updated {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 300px;
      width: 100%;

      &-date {
        font-size: 14px;
        margin-right: 20px;
        font-weight: $font-w-bold;
      }
    }
  }

  &__active-badge {
    display: none;

    &:after {
      content: 'Active';
      background-color: $c-green;
      color: white;
      font-size: 10px;
      border-radius: 4px;
      padding: 2px 6px;
    }

    &--active {
      display: flex;
    }
  }

  &__active-img {
    max-width: 24px;
    width: 100%;
    margin-right: 5px;
  }

  &__graph {
    position: relative;
    width: 85px;
    display: flex;
    height: 7px;
    border-radius: 4px;
    background-color: #e2e2e2;

    &-name {
      font-size: 14px;
      margin-right: 5px;
      font-weight: $font-w-bold;
    }

    &-w {
      display: inline-flex;
      flex-direction: column;
      max-width: 170px;
      width: calc(95% / 4);

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &-label {
      font-size: 11px;
      margin-top: 3px;
    }

    &-score {
      font-size: 14px;
      margin-right: 15px;
      min-width: 40px;
      font-weight: $font-w-bold;
    }
  }
}
