.assignee-select {
  position: relative;
  width: 300px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  &--absolute {
    position: absolute;
    z-index: 20;
    margin-top: 10px;
  }
  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &:after {
    top: -19px;
    border-color: transparent transparent white transparent;
    border-width: 10px;
  }
  &:before {
    top: -22px;
    border-color: transparent transparent #d2d2d2 transparent;
    border-width: 11px;
  }
  &--left {
    &:after {
      left: 30px;
    }
    &:before {
      left: 29px;
    }
  }
  &--right {
    &:after {
      right: 14px;
    }
    &:before {
      right: 13px;
    }
  }
  &--middle {
    &:after {
      left: 150px;
    }
    &:before {
      left: 149px;
    }
  }
  &__contact-w {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d2d2d2;
    padding: 6px;
  }
  &__contact-name {
    color: #231f20;
    font-size: 14px;
    font-weight: $font-w-bold;
    margin-left: 10px;
  }
  &__actions-w {
    margin: 8px 16px 8px 0;
    text-align: end;
  }
}
