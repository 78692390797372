@import './acctNullState';
@import './clientProfile';
@import './clientStrategies';
@import './clientTabs';
@import './combineAccount';
@import './coverPage';
@import './currentAccount';
@import './currentAllocationTile';
@import './documentName';
@import './dotOval';
@import './header';
@import './honestConversation';
@import './moneyMind';
@import './proposalWork';
@import './Skeleton';
@import './targetAllocationTile';
@import './viewFinder';

.portfolio-builder {
  $self: &;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 56px;

    &-body {
      width: 100vw;
    }

    &-tile1 {
      display: flex;
      flex-direction: column;
      min-width: 850px;
      max-width: 80%;
      margin: auto;
      margin-top: 60px;
      padding: 0 30px;
      margin-bottom: 30px;
    }

    &-tile2 {
      background: white;
      border: 1px solid #d2d2d2;
      position: fixed;
      right: 0;
      top: 173px;
      overflow: hidden;
    }

    &-allocation {
      display: flex;
      margin: 16px 0px;

      &-current {
        display: flex;
        flex: 1;
        margin-right: 16px;
      }

      &-proposed {
        display: flex;
        flex: 2;
      }
    }
  }
}
