.goal-row {
  &__row {
    &:not(:last-child) {
      border-bottom: 1px solid $c-borders;
    }
  }

  &__tag {
    padding: 4px 0px;
    font-size: 10px;
    font-weight: $font-w-bold;
    letter-spacing: 0.7px;
    text-align: center;
    color: $c-nero;
    text-transform: capitalize;
    border-radius: 4px;
    background-color: #f2f5fa;
    display: inline-block;

    &-pending-btn {
      background: #ffffff;
      color: $c-gray;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 3px;
      border: solid 1px $c-orange;
      cursor: pointer;

      :hover {
        background: #fafafa;
      }
    }

    &-priority {
      background: #ffffff;
      color: $c-gray;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 3px;

      &--wishes {
        border: solid 1px $c-yellow;
      }

      &--wants {
        border: solid 1px $c-green;
      }

      &--needs {
        border: solid 1px $c-red-dark;
      }
    }
  }

  &__table {
    &-name {
      flex: 1.5;
      padding: 0 10px;
      font-size: 14px;
      color: $c-nero;
      padding: 8px 0;

      &-header {
        font-size: 12px;
        color: $c-gray;
      }
    }

    &-description {
      flex: 1.5;
      font-size: 12px;
      color: $c-gray;
      padding: 8px 8px;

      &-content {
        line-height: 16px;
        background: #fafafa;
        border: solid 1px $c-gray-light;
        border-radius: 4px;
      }
    }

    &-period {
      flex: 1;
      font-size: 12px;
      text-align: right;
      color: $c-gray;

      &--date {
        color: $c-nero;
        font-size: 16px;
        font-weight: bold;
      }
    }

    &-priority {
      flex: 0.6;
      font-size: 12px;
      text-align: right;
      color: $c-gray;
      padding: 0 10px;
    }

    &-amount {
      flex: 1;
      text-align: right;
      justify-content: flex-end;
      font-size: 14px;
      color: $c-nero;

      &--date {
        font-size: 14px;
        font-weight: bold;
      }

      &-header {
        font-size: 12px;
        color: $c-gray;
      }
    }

    &-actions {
      box-sizing: border-box;
      flex: 1;
      text-align: right;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &-pending {
        flex: 0.4;
        text-align: right;
        font-size: 14px;
      }
    }
  }

  &__name-m {
    flex: 1.5;
  }

  &__period {
    flex: 1;
    font-size: 14px;
    text-align: left;
    color: $c-nero;

    &-left {
      text-align: left;
    }
  }

  &__pending-change {
    text-decoration: line-through;
    background-color: white;
    font-weight: 400;
    color: $c-gray;
  }

  &__pending-change-type {
    text-decoration: line-through;
    font-weight: 400;
    color: $c-gray;
  }

  &__pending-button {
    text-decoration: line-through;
  }
}
