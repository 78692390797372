.profile-picture {
  &__w {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    height: 48px;
    width: 48px;

    img {
      display: block;
      margin: auto;
      width: 100%;
    }

    &--primary {
      background-color: #8790a3;
    }

    &--secondary {
      background-color: #f4f4f4;
    }
  }

  &__initials {
    font-size: inherit;

    &--primary {
      color: rgba(255, 255, 255, 0.8);
    }
    &--secondary {
      color: rgba(35, 31, 32, 0.5);
    }
  }
}
