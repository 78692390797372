.a-settings {
  &__row-w {
    display: flex;
    flex-direction: column;
    min-height: 620px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid $c-gray-light;
    font-size: 14px;

    &-toggle-w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 125px;
    }

    &--header {
      background: $c-white-smoke;
    }
    &--content {
      padding: 16px 16px;
    }

    &--btns {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;
    }

    &--col {
      padding: 16px 20px;

      > *:first-child {
        display: flex;
        align-items: center;
        max-width: 390px;
        width: 100%;
        margin-right: 10px;
      }
      > *:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      h5 {
        margin-left: 15px;
        font-weight: $font-w-bold;
      }

      &-h {
        background-color: $c-white-smoke;
        color: $c-gray;
        font-size: 11px;
      }
    }

    &--cols {
      padding: 16px 20px;

      > * {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 215px;
      }

      > *:first-child {
        display: flex;
        align-items: center;
        max-width: 325px;
        width: 100%;
        margin-right: 10px;
      }
      > *:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100px;
      }

      h5 {
        margin-left: 15px;
        font-weight: $font-w-bold;
      }

      &-h {
        background-color: $c-white-smoke;
        color: $c-gray;
        font-size: 11px;
      }
    }
  }

  &__link-crm-row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  &__heading {
    font-size: 16px;
    color: $c-nero;
    span {
      color: $c-gray;
      font-size: 12px;
      display: block;
      margin: 16px 0 8px;
    }
  }

  &__checkbox-w {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__summary-text {
    font-size: 14px;
    color: $c-nero;
  }

  &__header-text {
    font-weight: $font-w-bold;
    color: $c-nero;
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__second-header-text {
    font-size: 12px;
    color: $c-gray;
    margin-bottom: 16px;
  }

  &__header-w {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__header {
    font-size: 12px;
    margin-top: 30px;
    &-label {
      margin-top: 0px;
      padding-right: 10px;
      &:last-child() {
        margin: 0px 3px 0px 0px;
      }
    }
  }
}
.no-border {
  border: none;
}
.toggle-space {
  width: 34px;
}
