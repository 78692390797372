.email-preview-modal {
  padding: 16px 24px;

  &__line {
    margin: 16px 0;
    border-bottom: 1px solid #cccccc;
  }

  &__box {
    padding: 20px;
    max-height: 400px;
    overflow: auto;
    background-color: #e6e6e6;
    &--white-bg {
      background-color: white;
    }
  }

  &__row {
    color: $c-nero;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    line-height: 18px;
    padding: 2px 0;

    &-label {
      width: 100px;
    }

    &-value {
      font-weight: $font-w-bold;
    }

    &-error {
      font-weight: $font-w-bold;
      color: $c-red-bright;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    margin-right: 25px;
    width: 100%;
    color: $c-blue;
    &--space-between {
      justify-content: space-between;
    }
  }

  &__copy {
    position: relative;
    top: -7px;
    left: 5px;
    font-weight: $font-w-bold;
    font-size: 14px;
    cursor: pointer;
  }

  &__email-sent-date {
    font-size: 12px;
    color: #898a8d;
    margin-bottom: 10px;
  }

  &__loader {
    color: $c-nero;
    padding: 48px 0;
    text-align: center;
  }

  &__loader-title {
    margin-top: 24px;
  }
}
