.side-menu {
  position: fixed;
  right: 0;
  width: 400px;
  height: 100%;
  background: $c-white;
  border: solid $c-gray-light 1px;
  box-shadow: 0 0 15px -5px $c-gray;

  &__body {
    padding: 15px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    height: auto;
    padding: 15px;
    box-shadow: none;

    &-delete-icon {
      width: 17px;
      margin-right: 5px;
    }
  }

  &__close-icon {
    cursor: pointer;
  }
}
