.c-dash__hc {
  width: 100%;

  &-plus-icon {
    height: 20px;
    margin-right: 6px;
  }

  &-no-scores-w {
    width: 100%;
    text-align: center;
  }

  &-no-scores {
    display: inline-block;
    text-align: center;
    max-width: 300px;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  &-no-scores-icon-w {
    display: flex;
    justify-content: space-between;
  }

  &-no-scores-text {
    color: #231f20;
    font-size: 14px;
    margin: 17px 0 16px 0;
  }

  &-icon {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 7px 17px;
    border-radius: 4px;

    img {
      max-width: 18px;
    }

    &-w {
      display: flex;
      justify-content: space-between;
      margin-left: 27%;
      padding: 21px 25px;
    }

    &--protection {
      background-color: $c-protection;
    }
    &--commitment {
      background-color: $c-commitment;
    }
    &--happiness {
      background-color: $c-happiness;
    }
  }

  &-score {
    padding: 20px 25px;
    padding-top: 31px;

    &-client {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #d2d2d2;
      }
    }

    &-item {
      min-width: 118px;

      // average score
      > span {
        margin-left: 10px;
        font-weight: $font-w-light;
      }

      &:not(:first-child) {
        text-align: center;
        min-width: 50px;
      }
    }
  }
}
