.moneymind__description {
  margin-top: 15px;

  &-w {
    font-weight: $font-w-bold;

    span {
      font-weight: $font-w-bold;
      color: $c-gray;
    }
  }

  &-content {
    padding: 24px 16px 0;
    min-height: 410px;
    font-size: 14px;
    line-height: 18px;

    &-blended {
      min-height: 887px;
    }

    p {
      margin: 0;

      ol {
        margin: 15px;
        position: relative;

        li {
          margin-bottom: 3px;

          &:before {
            content: '\2022';
            position: relative;
            top: 1px;
            left: 2px;
            margin-right: 5px;
          }
        }
      }

      ul {
        margin: 25px auto;

        li {
          margin: 15px auto;
          display: flex;

          b {
            min-width: 95px;
            color: $c-gray;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.7px;
            margin: 3px 15px 0 0;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
