.modal {
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $modal-zindex;
    background-color: rgba(0, 0, 0, 0.6);

    &--gray-light-bg {
      background-color: $c-gray-light;
    }

    &--im-bg {
      background-color: rgba(34, 38, 63, 0.65);
    }
  }

  &__box-w {
    width: 100%;
    color: $c-gray;

    @media (min-height: $media-xxsmall-height) and (max-height: $media-medium-height) {
      margin: 50px auto;
    }

    @media screen and (min-height: $media-medium-height) and (max-height: $media-large-height) {
      margin: 100px auto;
    }

    @media (min-height: $media-large-height) {
      margin: 200px auto;
    }

    @media (max-height: $media-xxsmall-height) {
      margin: 50px auto;
    }

    transition: margin-top 0.8s;

    &--center {
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__btn {
    &--close {
      display: flex;
      justify-content: flex-end;

      & svg {
        path {
          fill: $c-icons;
        }
      }
    }
  }

  &__icon {
    cursor: pointer;
  }

  &__content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    color: $c-titles;
    font-size: 14px;
    font-weight: $font-w-bold;

    span {
      font-weight: bold;
    }
  }

  &__buttons {
    margin-top: 40px;
    justify-content: flex-end;
    display: flex;

    &--w {
      margin-top: 0;
      padding: 16px 25px 25px 25px;
    }

    &--center {
      justify-content: center;
      margin-top: 0;
    }
  }

  &__graphic {
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: translateY(-5%);
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    color: $c-gray;
    background: $c-white-smoke;
    border-radius: 10px;
    width: 120px;
    height: 120px;
    font-weight: 200;
    font-size: 12px;
    text-align: center;

    &-center-text {
      width: 120px;
      height: 120px;
      position: absolute;
      top: 48%;
      z-index: 1;
    }
  }
}
