.ivf-update-answer-modal {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;

  &__instructions {
    line-height: 22px;
    font-size: 15px;
    color: $c-nero;
  }

  &__priority-types {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
  }

  &__priority-descriptions {
    display: flex;
    flex-direction: column;
    flex: 2;
    &-icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $c-nero;
      text-align: left;
      font-size: 16px;
      flex: 2;
      svg {
        height: 20px;
        width: 20px;
      }
    }

    &-copy {
      line-height: 40px;
      display: flex;
      color: $c-nero;
      font-size: 15px;
    }
  }

  &__priority-slider {
    flex-direction: row;
    margin-top: 20px;
    &-endcap {
      height: 20px;
      width: 20px;
      margin: 0px 10px 0px 10px;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  &__buttons {
    margin-top: 40px;
    text-align: right;
  }
}
