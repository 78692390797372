.delete-action-modal {
  &__content {
    padding: 24px;
  }
  p {
    margin: 10px auto;
    color: $c-nero;
    font-size: 14px;

    &:first-of-type {
      margin-bottom: 25px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px;
  }
}
