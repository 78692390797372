.strategy-tooltip {
  position: relative;

  &-label-name {
    font-weight: $font-w-bold;
  }

  &-trigger {
    display: inline-block;
    text-decoration: none;
  }

  &-bubble {
    position: absolute;
    z-index: $tooltip-zindex;

    &::after {
      content: '';
      position: absolute;
    }
  }

  &-body {
    width: 260px;
    max-width: 260px;
    padding: 14px;
    border: solid 1px #2178c4;
    border-radius: 4px;
    box-shadow: 0px 3px #e8e8e8;
    background: white;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }

  &-position {
    &--top {
      bottom: 100%;
      left: 50%;
      padding-bottom: 9px;
      transform: translateX(-50%);

      &-right {
        bottom: 100%;
        left: 0%;
        padding-bottom: 9px;
      }

      &-left {
        bottom: 100%;
        right: 0%;
        padding-bottom: 9px;
      }
    }

    &--bottom {
      top: 100%;
      left: 50%;
      padding-top: 9px;
      transform: translateX(-50%);

      &-right {
        top: 100%;
        left: 0%;
        padding-top: 9px;
      }

      &-left {
        top: 100%;
        right: 0%;
        padding-top: 9px;
      }
    }

    &--left {
      top: 50%;
      right: 100%;
      padding-right: 9px;
      transform: translateY(-50%);
    }

    &--right {
      top: 50%;
      left: 100%;
      padding-left: 9px;
      transform: translateY(-50%);
    }
  }
}
