.funded-score-historical-graph {
  position: relative;
  height: 300px;
  width: 100%;
  padding-right: 15px;

  &__chart {
    height: 300px;
  }
}
