.hc-scoring-email-modal {
  p {
    color: $c-nero;
  }

  &__email {
    &-img-container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 170px;
      padding-top: 20px;
      background: #e6e6e6;

      img {
        height: 150px;
      }
    }

    &-w {
      line-height: 14px;
      padding: 20px 30px;
      color: #696969;
    }

    &-w a {
      font-weight: $font-w-bold;
    }

    &-w p {
      margin-top: 10px;
      color: #696969;
      font-size: 12px;
    }

    &-w p.name-label {
      color: $c-charcoal;
      font-weight: $font-w-bold;
      font-size: 16px;
      margin-bottom: 20px;
    }

    &-w p.token {
      color: #255eba;
      font-size: 26px;
      font-weight: bold;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    &-w p.signature {
      margin: 15px 0 20px 0;
    }

    &-w span {
      font-weight: 500;
      color: #696969;
    }

    &-w a {
      color: #15c;
      text-decoration: underline;
    }

    &-image {
      width: 250px;
    }
  }
}
