@import './householdDisplay';
@import './householdProgress';
@import './householdTextDisplay';
@import './householdTotalProgress';
@import './institutionFilter';

.households {
  &-w {
    margin-top: 50px;
  }

  &__list {
    max-height: 800px;
    overflow: auto;

    > a {
      transition: background-color 300ms;
    }

    > a:hover {
      background: $c-gray-lighter;
    }
  }

  &__search-w {
    padding: 7px 16px;
  }

  &__arrow {
    &--down {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $c-gray;
      margin: 5px;
    }

    &--up {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $c-gray;
      margin: 5px;
    }
  }

  &__column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--name {
      flex: 8;
      justify-content: flex-start;
    }

    &--mm,
    &--hc,
    &--iv,
    &--pal,
    &--goals {
      flex: 2;
    }

    &--net-worth,
    &--last-login {
      flex: 4;
    }

    &--right-aligned {
      justify-content: flex-end;
      position: relative;
    }

    &--score,
    &--tasks {
      flex: 3;
    }

    &--progress {
      flex: 4;
    }

    &__warning {
      cursor: pointer;
      height: 8px;
      width: 8px;
      border-radius: 8px;
      position: relative;
      background: #f49500;
    }
  }

  &__label-w {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
}
