.score-comment {
  &-text-area {
    display: flex;
    margin-bottom: 23px;
    flex-direction: column;
    height: 68px;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    &--error {
      @extend .score-comment-text-area;
      border: 1px solid #f12938;
    }
    &__input {
      padding: 8px 8px 0 8px;
      height: 42px;
      font-size: 14px;
      color: #231f20;
      font-family: $font-main;
      border-radius: 4px;
      border: none;
      resize: none;
      &:disabled {
        background-color: #f5f5f5;
        opacity: 0.5;
      }
    }
    &__limit {
      color: #8a8888;
      font-size: 14px;
      text-align: right;
      padding: 0px 8px 8px 8px;
      line-height: 16px;
      display: flex;
      justify-content: space-between;
      &--disabled {
        @extend .score-comment-text-area__limit;
        background-color: #f5f5f5;
        opacity: 0.5;
      }
    }
    &__status-container {
      display: flex;
      align-items: center;
    }
    &__status-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    &__status-text {
      font-size: 14px;
      &--error {
        @extend .score-comment-text-area__status-text;
        color: #f12938;
      }
      &--success {
        @extend .score-comment-text-area__status-text;
        color: #8ccc33;
      }
      &--progress {
        @extend .score-comment-text-area__status-text;
        color: #231f20;
      }
    }
    &__undo {
      cursor: pointer;
      margin-right: 16px;
      color: #255eba;
      font-size: 14px;
      line-height: 14px;
      &--inactive {
        @extend .score-comment-text-area__undo;
        color: #8a8888;
      }
    }
    &__character-count {
      cursor: pointer;
      color: #8a8888;
      &--over {
        @extend .score-comment-text-area__character-count;
        color: $c-red-dark;
      }
    }
  }
  &-compact-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    color: #231f20;
    margin: 6px 0px 23px 0px;
    &__edit-icon {
      padding: 5px;
      cursor: pointer;
    }
  }
}
