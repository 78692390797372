.add-team__modal-body {
  display: flex;
  flex-direction: column;

  &-split {
    width: 50%;
  }

  &-row {
    max-height: 400px;
    overflow: scroll;
  }

  &-noresult {
    margin: 48px 150px;
  }
  &-search {
    padding: 8px 0 8px 16px;
    border-bottom: 1px solid $c-gray-light;
  }

  &-btns-w {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px 16px 0;
    border-top: 1px solid $c-gray-light;
  }

  &__checkbox {
    height: 16px;
    width: 16px;
    border: 1px solid #b8b8b8;
    border-radius: 2px;
    background-color: none;
    margin: 0 8px;
  }
}
