.task-filter-dropdown {
  position: absolute;
  z-index: 900;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  left: -37px;
  border: 1px solid #d2d2d2;
  border-radius: 0px;
  box-shadow: 0 4px 7px 0 rgba(184, 184, 184, 0.5);
  top: 55px;
  width: 261px;
  cursor: pointer;
  border-radius: 6px;

  &-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #fafafa;
    border-bottom: solid #d2d2d2 1px;
    border-radius: 5px 5px 0 0;

    p {
      padding-left: 10px;
    }
  }

  &__list {
    max-height: 500px;
    overflow-y: auto;
    width: 100%;

    &-body {
      padding: 15px 0;
    }
  }

  &__item {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
  }

  &__selection {
    border: 1px solid transparent;
    background: none;
    color: white;

    &__header {
      font-size: 14px;
      color: #8a8888;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 171px;

      &--open {
        color: #231f20;
      }
    }
    &__filter {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: 1px solid #e4e5e7;
      padding: 10px 15px;
      line-height: 1;
      border-radius: 4px;
      cursor: pointer;
      transition: border 300ms;

      &--spacer {
        box-shadow: none;
        border: none;
        cursor: none;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &--active-filters {
        border: 1px solid transparent;
        color: white;
      }
    }

    &__dropdown-filter {
      z-index: 1;
      cursor: default;
      position: absolute;
      top: 43px;
      left: 0;
      border: 1px solid #d2d2d2;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      background-color: white;
      border-radius: 4px;
      width: 100%;
      padding: 2px 8px;

      &-options {
        display: grid;
        gap: 0px 50px;
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
        align-items: center;
        padding-right: 30px;

        &--sm {
          grid-template-rows: repeat(3, 1fr);
          width: 100%;
          height: 80px;
          overflow-y: auto;
          overflow-x: auto;
        }
      }
    }

    &__arrow {
      transform: rotate(90deg);
      color: #231f20;
      transition: transform 300ms, color 300ms;

      &--open {
        transform: rotate(270deg);
      }

      &--active-filters {
        color: white;
      }
    }
  }

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:after {
    top: -14px;
    left: 41px;
    border-color: transparent transparent white transparent;
    border-width: 7px;
  }

  &:before {
    top: -17px;
    left: 40px;
    border-color: transparent transparent #d2d2d2 transparent;
    border-width: 8px;
  }
}
