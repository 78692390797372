.im-global__footer {
  &-w {
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0px;
    max-height: 80px;
  }

  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 15px 16px;
    width: 100%;
    min-height: 76px;
    max-width: 1170px;
    min-width: 600px;
  }

  &-step {
    display: flex;
    min-width: 240px;
    padding-top: 8px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 22px;
    box-shadow: inset 0px 4px 0px 0px rgba(103, 104, 121, 0.1);
    color: #676878;
    &:not(:last-child) {
      margin-right: 25px;
    }
    &:last-child {
      max-height: 44px;
      width: 69px;
      min-width: 69px;
      margin-top: 15px;
      margin-left: auto;
    }

    &--active {
      box-shadow: inset 0px 4px 0px 0px #1ea382;
    }
  }
}
