.client-accounts-error-email-modal {
  &__email-html {
    transform: scale(0.85);

    // horizontally aligns table element's content in center
    table {
      margin: auto;
    }
    // targets Go to Net Worth button text and vertically centers it
    td[valign='middle'][align='center'] {
      vertical-align: middle;
    }
    // targets disclaimer logos and sizes them to 32px and gives top/bottom padding
    td[valign='middle'][class='block'] {
      padding-top: 40px;
      padding-bottom: 40px;
      > img {
        height: 32px;
      }
    }
    // targets disclaimer text and adjusts padding/margin
    td[border='0'] {
      padding: 0 !important;
      > p {
        padding: 0 !important;
        margin: 0 20px !important;
      }
    }
  }
}
