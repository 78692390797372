.insurance {
  &__null-w {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $c-gray-light;
  }
  &__null-text {
    text-align: center;

    img {
      max-width: 60px;
      margin-bottom: 15px;
    }

    h3 {
      color: $c-blue;
      font-size: 16px;
      margin-bottom: 12px;
      font-weight: $font-w-bold;
    }

    p {
      font-weight: $font-w-light;
      max-width: 300px;
      line-height: 1.3;
      font-size: 14px;
    }
  }
}
