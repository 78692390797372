.message-pane {
  &-w {
    flex: 1;
    border-right: 1px solid $c-gray-light;
    display: flex;
    flex-direction: column;
  }
  &__search-w {
    padding: 4px 8px;
    background-color: $c-background-hover;
    border-top: 1px solid $c-gray-light;
    border-bottom: 1px solid $c-gray-light;
  }
}
