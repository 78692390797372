.toast {
  &__overlay {
    padding: 20px;
    border: 1px solid #d9f2b6;
    background-color: #d9f2b6;
    width: 100%;
    height: 50;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__syncloader {
    display: flex;
    height: 92px;
    width: 381px;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
    &-spinner {
      img {
        width: 58px;
      }
      padding: 16px 12px;
    }
    &-loader {
      flex: 2;
      padding-top: 25px;
      &-action {
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: $c-blue;
      }
      &-description {
        color: $c-nero;
        line-height: 18px;
        font-size: 14px;
      }
    }
    &-close {
      padding-top: 8px;
      padding-right: 8px;
      & svg {
        path {
          fill: $c-gray;
        }
      }
    }
  }
}
