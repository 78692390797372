.c-dash__mm {
  padding: 20px 25px;

  &-icon {
    display: flex;
    justify-content: space-between;
    margin-left: 100px;

    > div {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      display: block;
      margin-top: 5px;
    }

    &-protection {
      color: $c-protection;
    }

    &-commitment {
      color: $c-commitment;
    }

    &-happiness {
      color: $c-happiness;
    }
  }

  &-score {
    padding: 20px 25px;

    &-client {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #d2d2d2;
      }

      & span {
        min-width: 100px;
      }

      & span:not(:first-child) {
        width: 100%;
        text-align: center;
      }
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: auto;
  }
}
