.new-advisor-message-modal {
  &__header-w {
    min-height: 49px;
    background-color: #f5f5f5;
    border: 1px solid #d2d2d2;
    border-top: none;
    padding: 6px 16px;
  }
  &__subject-w {
    line-height: 16px;
    margin-bottom: 5px;
    display: flex;
  }
  &__subject-input {
    width: 100%;
    background-color: #f5f5f5;
    border: none;
    color: $c-nero;
  }
  &__label {
    font-size: 12px;
    color: $c-gray;
    margin-right: 8px;
  }
  &__to-w {
    display: flex;
  }
  &__to-input-w {
    display: flex;
    flex-wrap: wrap;
  }
  &__to-input {
    background-color: #f5f5f5;
    height: 18px;
    width: 100px;
    border: none;
    color: $c-nero;
    max-width: 730px;
  }
  &__add-plus-btn-w {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    min-width: 24px;
    position: relative;
  }
  &__add-plus-btn {
    position: absolute;
    bottom: 0px;
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin-bottom: 6px;
  }
  &__content-w {
    position: relative;
    padding: 16px;
  }
  &__message-label {
    font-size: 14px;
    line-height: 14px;
    color: $c-nero;
    margin-bottom: 6px;
  }
  &__message-text-area {
    height: 127px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    border-color: $c-gray-light;
    resize: none;
    font-size: 14px;
    color: $c-nero;
    padding: 5px 10px;
    font-family: $font-main;
  }
  &__disclaimer {
    margin-top: 23px;
    margin-bottom: 27px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08px;
    color: #848695;
  }
  &__btn-w {
    display: flex;
    justify-content: flex-end;
  }
  &__recipients-dropdown-w {
    position: absolute;
    right: 10px;
    top: -4px;
    width: 404px;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    z-index: 1;
    background-color: #ffffff;
  }
  &__recipients-dropdown-list {
    height: 208px;
    overflow-y: auto;
  }
  &__recipients-dropdown-item {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 10px 12px;
    &:hover {
      background-color: $c-white-smoke;
    }
  }
  &__recipients-dropdown-profile-w {
    display: flex;
    width: 75px;
  }
  &__household-name {
    margin-left: 8px;
    font-size: 14px;
    color: $c-nero;
    font-weight: 500;
  }
  &__recipients-dropdown-btn-w {
    padding: 0 24px;
    border-top: 1px solid $c-gray-light;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__recipients-label-w {
    display: flex;
    align-items: center;
    background-color: #d8d8d8;
    border-radius: 4px;
    margin-right: 6px;
    margin-bottom: 4px;
  }
  &__recipient-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    height: 18px;
    color: $c-nero;
    margin: 0 6px;
  }
  &__remove-icon {
    height: 16px;
    cursor: pointer;
  }
}
