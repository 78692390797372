.c-dash-task {
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  justify-content: stretch;
  padding: 15px 0;
  min-height: 73px;

  &-w {
    min-height: 365px;
    max-height: 365px;
    overflow: hidden;
  }

  &-completed {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    span {
      display: flex;
      border: 1px solid #d2d2d2;
      background-color: $c-green;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      padding: 2px;
    }
  }

  &__row {
    width: 82%;

    &-w {
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-name {
      font-weight: 500;
      color: #231f20;
      max-width: 445px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 18px;
      margin-bottom: 5px;
      white-space: nowrap;
    }

    &-text {
      font-weight: 500;
      font-size: 12px;
    }

    &-date {
      font-size: 12px;
      font-weight: 400;
      margin-right: 10px;
    }

    &-dot {
      font-size: 18px;
      padding: 0 5px;
    }

    &-status {
      font-size: 12px;
      color: $c-nero;
      padding: 4px 8px;
      display: inline-block;
      border-radius: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 25ch;
    }
  }
}
