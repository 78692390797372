.hc-null-redo {
  background-color: $c-white;
  border: 1px solid $c-gray-light;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  &__null-img-w {
    background-color: #e6e6e6;
  }
  &__null-img {
    width: 100%;
    max-width: 708px;
    display: block;
    margin: auto;
  }
  &__button-w {
    height: 291px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__no-result-text {
    margin-top: 40px;
    font-size: 16px;
    color: $c-blue;
    font-weight: $font-w-bold;
  }
  &__selection-text {
    margin-top: 16px;
    margin-bottom: 32px;
    color: $c-nero;
    font-size: 14px;
  }
}
