.folder-modal {
  &__content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    color: $c-titles;

    &--input {
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      padding: 10px;
      margin-top: 6px;
      border-radius: 6px;
      border: 1px solid $c-borders;

      ::placeholder {
        color: $c-icons;
      }
    }

    &--error {
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      padding: 10px;
      margin-top: 6px;
      border-radius: 6px;
      border: 1px solid $c-red-bright;
    }
  }

  &__buttons {
    margin-top: 40px;
    align-self: flex-end;
    display: flex;
  }

  &__error-message {
    color: $c-red-bright;
    font-size: 12px;
    margin: 2px 0 0 4px;
  }
}
