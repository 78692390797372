.task-add {
  &__container {
    margin-bottom: 16px;
  }
  &__header {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    span {
      align-self: flex-end;
      display: inline-block;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 14px;
    margin: 0 0 8px 0;
    color: #231f20;
    display: inline-block;

    &--helper {
      font-size: 12px;
      margin: 0 0 0 4px;
    }
    &-note {
      margin: 0 0 14px 0;
    }

    &-switch {
      margin-top: 16px;
    }

    &-tooltip {
      display: inline-block;

      svg {
        width: 16px;
        cursor: pointer;
        vertical-align: middle;
        margin: 0 0 2px 5px;

        path {
          fill: $c-gray;
        }
      }
    }
  }

  &__textarea {
    box-sizing: border-box;
    border-radius: 4px;
    background: none;
  }

  &__charcount {
    text-align: right;
    font-size: 12px;
    color: $c-nero;
    &--over {
      color: $c-red-bright;
    }
  }
  &__options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    label {
      font-size: 14px;
      line-height: 24px;
      flex-grow: 1;
      input {
        cursor: pointer;
      }
    }
  }

  &__datepicker {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 115px;
    border: solid 1px #c2c2c2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 9px 3px;
    align-items: center;

    img {
      width: 14px;
      height: 14px;
    }
  }

  &__footer-bar {
    width: 100%;
    border-top: solid 1px #d8dde3;
    position: absolute;
    bottom: 22px;
    z-index: 1;
    height: 40px;
    background: none;
  }
  &__notification-warning-container:nth-child(1) {
    margin-top: 24px;
  }

  &__notification-warning-container:nth-child(2) {
    margin-top: 32px;
  }

  &__notification-warning-text {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 16px;
  }

  &__notification-warning-link {
    font-size: 14px;
    line-height: 16px;
    color: $c-blue;
  }

  &__footer {
    max-width: 150px;
    position: absolute;
    bottom: 22px;
    z-index: 100;
    height: 40px;
    background: none;

    &-delete {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      max-width: 120px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      color: #255eba;
      margin: 22px 10px 0px 10px;
      cursor: pointer;

      svg {
        height: 20px;
        margin-top: -4px;
        margin-right: 4px;
        vertical-align: middle;
        path {
          fill: #255eba;
        }
      }
    }
  }
}
