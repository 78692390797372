$c-name: '.accordion';

#{$c-name} {
  &-w {
    display: flex;
    flex-direction: column;
    width: 100%;

    .expand-enter {
      max-height: 0;
      transition: max-height 350ms ease-in;
      overflow: hidden;
    }

    .expand-enter-active,
    .expand-exit {
      max-height: 2000px;
    }

    .expand-exit-active {
      max-height: 0;
      transition: max-height 300ms ease-out;
      overflow: hidden;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 17px 8px;
    background-color: #f3f3f3;
    border-bottom: 1px solid #fff;
    cursor: pointer;
  }

  &__title {
    display: inline-block;
    font-family: inherit;
    font-size: 16px;
    line-height: 1;
  }

  &__arrow {
    transform: rotate(0);
    transition: transform 350ms;

    &--open {
      transform: rotate(180deg);
    }

    &-w {
      display: inline-block;
      margin-right: 8px;
    }
  }
}
