@import 'photoModal';

.advisor__body {
  margin-top: 86px;
  display: flex;
  min-height: 100%;

  &-content {
    display: flex;
    flex-direction: column;
    max-width: 1070px;
    min-width: 600px;
    margin: 0 auto 30px;
    padding: 0 16px;

    &-w {
      transition: all 300ms;
      width: 100%;
    }
  }
}

.a-profile {
  padding: 24px;

  &__avatar {
    color: #ffffff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 1px;
    border-collapse: collapse;
  }

  &-w {
    display: flex;
    min-height: 630px;
  }

  &__uploadBtn {
    height: 17px;
    width: 75px;
    color: #255eba;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    border: none;
    background-color: transparent;
    display: block;
    margin: 5px 0;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__editBtn {
    height: 17px;
    width: 50px;
    color: #255eba;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    border: none;
    background-color: transparent;
    display: block;
    margin: 5px 0;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    font-weight: $font-w-light;
  }
}

.a-profile__info {
  &-w + &-w {
    margin-top: 60px;
  }

  &-main {
    display: flex;
    align-items: center;
  }

  &-pic {
    margin-right: 15px;
    width: 75px;
    height: 75px;

    img {
      border-radius: 50%;
    }
  }

  &-name {
    font-size: 24px;
    color: $c-nero;
    font-weight: $font-w-bold;
  }

  &-title {
    font-size: 14px;
    color: $c-nero;
    font-weight: $font-w-light;
    padding: 3px 0;
  }

  &-details-main {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 100px;

    > * {
      display: flex;
      align-items: center;
      width: 50%;
      flex: 0 1 50%;
      padding-top: 25px;
      color: $c-nero;
      font-size: 14px;

      span {
        margin-right: 10px;

        svg path {
          fill: $c-gray;
        }
      }
    }
  }
}

.a-profile__edit {
  width: 100%;
  display: flex;

  h1 {
    font-size: 16px;
    font-weight: $font-w-bold;
    margin-bottom: 25px;
  }

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    width: 100%;
  }

  &-details-main {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 0 82px;
  }

  &-half-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;

    div {
      width: 50%;
      flex: 0 1 50%;
      margin-top: 15px;

      &:not(:first-child) {
        margin-left: 15px;
      }
    }
    label {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
      width: 100%;
    }
  }

  &-third-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;

    div {
      width: 33%;
      flex: 0 1 33%;
      margin-top: 15px;

      &:not(:first-child) {
        margin-left: 15px;
      }
    }
    label {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
      width: 100%;
    }
  }
}
