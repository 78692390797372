.goals-null-view {
  &__icon {
    margin-bottom: 21px;
    height: 48px;
  }
  &__main-text {
    font-size: 14px;
    color: #231f20;
    margin-bottom: 4px;
  }
  &__secondary-text {
    font-size: 12px;
    color: #8790a3;
    margin-bottom: 4px;
  }
  &__tertiary-text {
    font-size: 12px;
    color: #8790a3;
  }
}
