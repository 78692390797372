.select-planning-software-modal {
  &__body {
    display: flex;
    flex-direction: column;
    padding: 22px 24px;
  }

  &__text {
    color: $c-nero;
    font-size: 14px;
    line-height: 14px;
  }

  &__btns-w {
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px;
    margin-top: 30px;
  }
  &__fieldset {
    margin-top: 24px;
  }
  &__select {
    width: 50%;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  &--text {
    &-bold {
      font-weight: 700;
    }
    &-top-spacing {
      margin-top: 25px;
    }
  }
}
