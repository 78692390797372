.resources-dropdown {
  &__w {
    position: relative;
    padding: 8px;
    border-radius: 6px;
    background-color: transparent;
    transition: background-color 300ms;

    &--active {
      background-color: #4a78cf;
    }

    svg {
      margin-right: 9px;
    }
  }

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }
}
