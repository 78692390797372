.hc-prioritization-email-modal {
  padding: 16px 24px;

  &__name-label {
    color: $c-charcoal;
    font-weight: $font-w-bold;
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__header {
    height: 60px;
    padding-left: 40px;
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #dedede;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $c-nero;
    background-color: $c-white;
  }

  &__header-text {
    margin-left: 12px;
  }

  &__img-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 170px;
    padding-top: 20px;
    background: #e6e6e6;

    img {
      height: 150px;
    }
  }

  &__email-w {
    line-height: 18px;
    padding: 48px 40px;
    color: #696969;
    background-color: white;
  }

  &__body-text {
    margin-top: 15px;
    color: #4d4d4d;
    font-size: 12px;

    &--link {
      font-weight: $font-w-bold;
      color: #15c;
      text-decoration: underline;
    }
  }

  &__disclosure {
    background-color: #e6e6e6;
    font-size: 11px;
    margin: 0 7px;
    color: #949494;
    &-logo-w {
      margin: 48px 0;
      display: flex;
      justify-content: center;
    }

    &-section-1 {
      text-align: center;
    }

    &-section-2 {
      margin-top: 24px;
      &--justify {
        text-align: justify;
      }
    }
  }

  &__institution-logo {
    height: 32px;
    &--gray {
      filter: grayscale(1);
      opacity: 0.3;
    }
  }

  &__finlife-logo {
    height: 32px;
    margin-left: 45px;
  }
}
