@import './messageThreadList.scss';
@import './newMessageModal.scss';

.messages {
  &-w {
    height: 600px;
    display: flex;
    flex-direction: row;
  }
}
