.partner {
  &-w {
    padding: 24px;
    padding-top: 9px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    color: $c-blue;
    min-width: 164px;
    min-height: 77px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid $c-gray-light;
    margin-top: 15px;

    &:not(:last-child) {
      margin-right: 17px;
    }

    img {
      width: 100%;
      max-width: 142px;
    }
  }

  &__item-remove {
    display: none;
    justify-content: center;
    align-items: center;
  }

  &__null {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    width: 100%;

    img {
      max-width: 65px;
    }

    h4 {
      font-size: 16px;
      color: $c-blue;
      margin: 12px auto;
      font-weight: $font-w-bold;
    }

    p {
      max-width: 230px;
      font-size: 14px;
      text-align: center;
    }
  }
}

// Modal
.partner__modal {
  &-content {
    max-height: 330px;
    overflow: scroll;
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 5px;
    border-bottom: 1px solid $c-gray-light;

    img {
      max-width: 85px;
    }

    span {
      font-size: 14px;
      color: $c-nero;
      margin-left: 24px;
    }
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
  }
}
