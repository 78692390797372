@import './assetsAndLiabilities';
@import './clientAccountsTile';
@import './clientAccountsTileNull';
@import './assetsLiabTileNull';
@import './clientAccountModal';
@import './clientAccount';
@import './clientRowHelper';
@import './clientAccountsErrorEmailModal';
@import './mergeAccountsModal';

.client-account {
  &__btn {
    &-w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;
    }

    &--text {
      font-size: 14px;
      font-weight: $font-w-bold;
      line-height: 17px;
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
  }

  &__account-tile-w {
    margin-top: 28px;
  }
}
