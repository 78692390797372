.acc-null {
  padding: 16px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  margin-bottom: 17px;
  width: 340px;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  &__name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    &-text {
      height: 14px;
      width: 175px;
      background-color: #d2d2d2;
    }
    &-bal {
      height: 14px;
      width: 95px;
      background-color: #d2d2d2;
    }
  }
  &__acc-number {
    height: 10px;
    width: 76px;
    border-radius: 4px;
    background-color: #f0f0f0;
  }

  &__reg-type {
    margin-left: auto;
    height: 16px;
    width: 108px;
    border-radius: 4px;
    background-color: #f2f5fa;
    margin-bottom: 7px;
  }
  &__yield {
    margin-left: auto;
    height: 16px;
    width: 108px;
    border-radius: 4px;
    background-color: #f2f5fa;
    margin-bottom: 7px;
  }
  &__custodian {
    display: flex;
    justify-content: space-between;
    &-text {
      height: 14px;
      width: 126px;
      border-radius: 4px;
      background-color: #f0f0f0;
    }
    &-ratio {
      height: 14px;
      width: 126px;
      border-radius: 4px;
      background-color: #f0f0f0;
    }
  }
}
