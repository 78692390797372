.household-total-progress {
  &-w {
    position: relative;
    top: -10px;
    height: 46px;
  }

  &__label {
    position: relative;
    bottom: 32px;
    color: $c-nero;
    font-size: 14px;
    font-weight: $font-w-bold;
    text-align: center;
  }
}
