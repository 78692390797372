@import 'folder';
@import 'folderDetail';
@import 'createFolderModal';
@import 'file';
@import 'uploadFileModal';

.vault {
  &__actions {
    display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    &-btns {
      display: flex;
      align-items: center;
    }
    &-trash-btn {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }

  &__btn {
    background-color: $c-active-btn;
    color: $c-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;

    &--text {
      font-size: 14px;
      font-weight: $font-w-bold;
      line-height: 17px;
      margin-left: 5px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-modal {
    &__content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      color: $c-titles;

      &--input {
        font-size: 14px;
        line-height: 16px;
        padding: 10px;
        margin-top: 6px;
        border-radius: 6px;
        border: 1px solid $c-borders;

        ::placeholder {
          color: $c-icons;
        }
      }
    }

    &__buttons {
      margin-top: 40px;
      align-self: flex-end;
      display: flex;
    }
  }
  &__name {
    flex: 5;
  }
  &__files {
    flex: 1;
    text-align: right;
  }
  &__folder-actions {
    flex: 1;
    text-align: right;
  }
}
