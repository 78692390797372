@import 'dash-moneymind';
@import 'dash-honestconversations';
@import 'dash-clientTasks';
@import 'dash-pal';
@import 'assignTeamModal';

.c-dash {
  &__office-team {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__null {
      display: flex;
    }

    &__warning {
      border: 1px solid $c-red-bright;
      border-radius: 3px;
      display: flex;
      align-items: center;
      background-color: rgba(241, 41, 56, 0.1);

      img {
        margin: 4px 8px;
      }

      span {
        margin-right: 16px;
        color: $c-red-bright;
        font-size: 14px;
        line-height: 16px;
      }
    }

    &__btn {
      margin-left: 16px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;

      a {
        padding: 7px 6.2px !important;
      }

      &--text {
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: center;
          margin-right: 6.2px;
        }
      }
    }

    &__teams-w {
      display: flex;
      align-items: center;
      width: auto;
      cursor: pointer;
    }

    &__teams {
      display: flex;
      align-items: center;
      margin-left: -10px;
    }

    &__team-member-photo {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 2px solid white;
      color: $c-gray-light;
      background-color: #f4f4f4;
      box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 1px;
      border-collapse: collapse;
    }

    .more {
      background-color: #f4f4f4;
      color: $c-blue;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: $font-w-bold;
      line-height: 17px;
      margin-left: -10px;
    }
  }

  // Office Team styles end here

  &__row-w {
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }

  &__row-four {
    display: inline-flex;
    width: 100%;
    max-width: calc(100% / 4);

    &:not(:first-child) {
      margin-left: 15px;
    }

    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      height: 86px;
      padding: 16px;
      font-weight: $font-w-bold;
      font-size: 24px;

      > span {
        font-size: 14px;
        color: $c-gray;
      }
    }
  }

  &__row-two {
    display: inline-flex;
    width: 100%;
    max-width: calc(100% / 2);

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 8px;

      svg path {
        fill: $c-blue;
      }
    }

    &-label {
      font-weight: $font-w-bold;
      font-size: 13px;
      color: $c-nero;
    }
  }

  &__null {
    &--title {
      font-size: 16px;
      color: $c-blue;
      font-weight: 500;
      line-height: 19px;
      padding: 12px 12px 6px;
    }

    &--description {
      padding: 6px 12px 12px;
      color: $c-nero;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }
}

.c-dash__null-State {
  height: 13px;
  width: 60px;
  background: #dedede;
}

.c-dash__null-overlay-effect {
  background: rgba(255, 255, 255, 0.85);
}

.c-dash__null--stateBackground {
  background: rgba(255, 255, 255, 0.92);
  height: 209px;
  width: 100%;
  position: relative;
  margin-top: -141px;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: -164px;

  p {
    font-size: 14px;
    margin-bottom: 12px;
    text-align: center;
  }

  a {
    margin-bottom: 12px;
  }

  .btn__centered {
    width: 75%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-family: inherit;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    padding: 9px 17px;
    margin: 0;
    margin-bottom: 10px;
    transition: background-color 300ms;
  }
}

.c-dash__null--stateBackground {
  background: rgba(255, 255, 255, 0.92);
  height: 209px;
  width: 100%;
  position: relative;
  margin-top: -141px;
  padding-left: 15%;
  padding-right: 15%;

  p {
    font-size: 14px;
    margin-bottom: 12px;
    text-align: center;
  }

  a {
    margin-bottom: 12px;
  }

  .btn__centered {
    width: 75%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-family: inherit;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    padding: 9px 17px;
    margin: 0;
    margin-bottom: 10px;
    transition: background-color 300ms;
  }
}

.c-dash__null--stateBackground--largeDivs {
  background: rgba(255, 255, 255, 0.92);
  height: 364px;
  width: 100%;
  position: relative;
  margin-top: -365px;
  padding-left: 15%;
  padding-right: 15%;

  p {
    font-size: 14px;
    margin-bottom: 12px;
    text-align: center;
  }

  a {
    margin-bottom: 12px;
  }

  .btn__centered {
    width: 75%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-family: inherit;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    padding: 9px 17px;
    margin: 0;
    margin-bottom: 10px;
    transition: background-color 300ms;
  }
}

.filtered__bottom {
  filter: blur(2px);
  opacity: 0.4;
}
