.score-selector {
  margin-top: 10px;
  margin-bottom: 17px;
  display: inline-block;
  width: 100%;
  &--modal-element {
    margin-bottom: 24px;
  }
  &__selector-flex-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__selector-box {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 26px;
    border-bottom: 2px solid $c-gray-light;
    &--single-view {
      width: 58px;
    }
    &--modal-element {
      width: 48px;
    }
    &--orange {
      border-bottom: 2px solid $c-orange;
    }
    &--green {
      border-bottom: 2px solid $c-green-lighter;
    }
  }
  &__selector-number {
    color: $c-gray-light;
    text-align: center;
    margin-bottom: 6px;
    transition: font-size 100ms;
    font-size: 14px;
    &--selected {
      color: $c-nero;
      font-size: 24px;
      font-weight: $font-w-bold;
    }
  }
}
