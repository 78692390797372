.hc-reveal {
  &-w {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0 32px 0;
    height: 746px;
    position: relative;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__divider {
    height: 746px;
    border-right: 1px solid #d2d2d2;
  }

  &__card-w {
    position: relative;
    transition: 1s margin-bottom;
    margin-bottom: -100px;

    &--absolute {
      position: absolute;
      top: 6.8%;
    }

    &--margin {
      margin-bottom: 16px;
      margin-left: 0px;
    }
  }

  &__card-comment {
    position: absolute;
    top: 8px;
    left: 8px;
    color: white;
    cursor: pointer;

    img {
      width: 12px;
    }
  }

  &__card-switch {
    position: absolute;
    top: 8px;
    right: 8px;
    color: white;
    cursor: pointer;

    img {
      width: 12px;
    }
  }

  &__card-shared {
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: white;
    width: 18px;
  }

  &__card-modal {
    position: absolute;
    text-align: center;
    width: 406px;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f4f4f4;
    z-index: 500;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    box-sizing: border-box;

    &::after {
      content: '';
      display: block;
      width: 10px;
      z-index: 501;
      background-color: #f4f4f4;
      height: 10px;
      position: fixed;
      top: -6px;
      left: 50%;
      border-top: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2;
      transform: rotateZ(45deg);
    }

    &-last {
      top: -230px;

      &::after {
        top: 208px;
        transform: rotateZ(225deg);
      }
    }

    textarea {
      font-size: 14px;
      box-sizing: border-box;
      height: 89px;
      width: 370px;
      border: 1px solid #d2d2d2;
      border-radius: 6px;
      background-color: #ffffff;
      resize: none;
      padding: 5px 10px;
    }
  }

  &__card-modal-close {
    width: 100%;
    color: #898a8d;
    font-size: 12px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    padding-right: 6px;
    padding-top: 6px;
  }

  &__card-modal-title {
    font-size: 14px;
    margin-bottom: 12px;
  }

  &__card-modal-btns {
    margin: 16px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__card-reveal-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-w {
      width: 100%;
      position: relative;
      background-color: transparent;
    }

    &-blur {
      background-color: white;
      width: 110%;
      height: 110%;
      z-index: 1000;
      opacity: 0.6;
      position: absolute;

      &-w {
        filter: blur(6px);
        background: url('../../../../images/honestConversations/backgroundCard.png');
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        height: 130px;
        width: 210px;
        border-radius: 6px;
      }
    }
  }
}
