.back-to-link {
  &__wrap {
    margin: 30px 0;
    display: inline-block;
  }

  &__link {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: $c-gray;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
