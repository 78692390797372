.c-settings-login-history {
  &__list {
    max-height: 800px;
    overflow-y: overlay;
    border-bottom: 1px solid #d2d2d2;

    > a {
      transition: background-color 300ms;
    }

    > a:hover {
      background: $c-gray-lighter;
    }
  }

  &__success-status {
    font-family: $font-main;
    font-size: 14px;
    line-height: 22px;
    color: #068667;
  }

  &__err-status {
    font-family: $font-main;
    font-size: 14px;
    line-height: 22px;
    color: #f12938;
  }

  &__back-to-settings {
    height: 20px;
    left: 38.89%;
    right: 48.75%;
    top: calc(50% - 20px / 2 - 212.5px);

    font-family: $font-main;
    font-size: 14px;
    line-height: 20px;

    color: #231f20;

    mix-blend-mode: normal;
    opacity: 0.6;
    cursor: pointer;
    padding-bottom: 40px;
  }

  &__view-loginhistory {
    cursor: pointer;
    color: #255eba;
    text-decoration: underline;
  }

  &__client-login-settings-title {
    color: #231f20;
    display: flex;
    align-items: center;
    font-family: $font-main;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.08px;
    padding-bottom: 15px;
  }

  &__client-login-history-name {
    font-family: $font-main;
    font-size: 16px;
    line-height: 24px;
    color: #231f20;
    padding-bottom: 25px;
  }

  &__column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // overflow-y: overlay;

    &--header {
      flex: 8;
      justify-content: flex-start;
      font-family: $font-main;
      color: #8a8888;
    }
    &--header-two {
      flex: 8;
      justify-content: flex-start;
      font-family: $font-main;
      color: #8a8888;
      // margin-left: -20px;
    }
    &--name {
      flex: 8;
      justify-content: flex-start;
    }
  }
}
