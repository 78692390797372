.strategy-search__header {
  &-w {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    max-width: 1170px;
    min-width: 600px;
    margin: 0 auto;
    padding: 35px 16px;
    padding-bottom: 16px;
    width: 100%;
    transition: padding 300ms;

    &--scrolling {
      padding: 15px 16px;
    }
  }

  &-logo {
    width: 100%;
    max-width: 112px;
  }

  &-top-w {
    display: inline-block;
  }

  &-bottom-w {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    transition: margin-top 300ms;

    &--scrolling {
      margin-top: 15px;
    }
  }
}
