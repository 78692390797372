.subbody {
  float: left;
  width: 32%;
}
.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px 100px 100px;
  background-color: #fff;
  color: #444;
}
.clienttile {
  grid-column: 1 / 3;
  grid-row: 1;
  width: 70%;
  float: left;
}
.lefttile {
  grid-column: 1;
  grid-row: 2;
  float: left;
  margin-left: 65px;
  width: 96%;
  margin-top: 18px;
}
.middletile {
  grid-column: 2;
  grid-row: 2;
  float: right;
  margin-left: 16px;
  grid-gap: 16px;
  width: 66%;
}
.downtile {
  grid-column: 1/3;
  grid-row: 3;
  float: left;
  margin-left: 65px;
  width: 96%;
  margin-top: 18px;
}
.righttile {
  float: right;
  width: 26%;
}
