.hc-no-combined-cards {
  text-align: center;
  &__null-img-w {
    background-color: #e6e6e6;
  }

  &__null-img {
    max-width: 481px;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  &__text-1 {
    color: $c-blue;
    font-size: 16px;
    font-weight: $font-w-bold;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  &__text-2 {
    margin-bottom: 32px;
    font-size: 14px;
    color: $c-nero;
  }
  &__result-w {
    padding: 0 24px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__photo-w {
    display: flex;
    align-items: center;
    width: 200px;
  }
  &__photo {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-right: 12px;
  }
  &__name {
    margin-left: 12px;
    color: $c-nero;
    font-size: 16px;
    font-weight: $font-w-bold;
  }
  &__status-w {
    text-align: center;
  }
  &__sub-header {
    color: $c-gray;
    font-size: 11px;
    font-weight: $font-w-bold;
    letter-spacing: 0.7px;
    margin-bottom: 8px;
  }
  &__status-text {
    color: $c-nero;
    font-size: 14px;
  }
  &__send-icon {
    height: 20px;
    margin-right: 6px;
  }
}
