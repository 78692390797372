.message-reply {
  &-w {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 8px 16px;
    border-top: 1px solid $c-gray-light;
    width: 100%;
  }

  &-field {
    &-w {
      align-items: center;
      display: flex;
      color: $c-icons;
      background-color: $c-white;
      width: 85%;
    }

    &__icon {
      &:hover {
        cursor: pointer;
      }
    }

    &__input {
      font-size: 14px;
      font-family: $font-main;
      line-height: 16px;
      color: $c-icons;
      border: none;
      width: 100%;
      resize: none;
      overflow: auto;
      padding: 0 10px;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-height: 100px;
    }
  }

  &-video {
    margin: 30px 15px;
  }
}
