.edit-scores-modal {
  margin: 24px 24px 16px 24px;
  color: #231f20;
  font-size: 14px;
  &__text-1 {
    margin-bottom: 24px;
  }
  &__text-2 {
    margin-bottom: 32px;
  }
  &__btn-w {
    display: flex;
    justify-content: space-between;
  }
}
