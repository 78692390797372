.insurance-add-modal {
  p {
    margin: 10px auto;
    color: $c-nero;
  }

  &__content {
    padding: 24px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px;
  }

  &__account-type {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    &-h {
      color: $c-nero;
      font-size: 20px;
      line-height: 1.3;
      margin-top: 5px;
    }

    label {
      color: $c-nero;
      font-size: 14px;
      margin-bottom: 3px;
      line-height: 1;
    }

    select {
      display: block;
      padding: 10px;
      border: 1px solid $c-gray-light;
      height: 36px;
      font-size: 14px;
    }
  }

  &__account-type + &__account-details {
    border-top: 1px solid $c-gray-light;
    margin-top: 35px;
    padding-top: 25px;
  }

  &__account-details {
    border-top: 1px solid $c-gray-light;
    margin-top: 15px;
    padding-top: 25px;
  }

  &__account-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    > span {
      width: 50%;
      flex: 0 1 50%;
      display: flex;
      flex-direction: column;
      padding-right: 15px;
      margin-bottom: 15px;

      label {
        color: $c-nero;
        font-size: 14px;
        margin-bottom: 3px;
        line-height: 1;
      }

      input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid $c-gray-light;
        border-radius: 6px;
      }

      select {
        display: block;
        padding: 10px;
        border: 1px solid $c-gray-light;
        background-color: white;
        height: 36px;
        font-size: 14px;
      }
    }
  }

  &__input-money {
    position: relative;
    &:before {
      content: '$';
      color: $c-gray;
      position: absolute;
      left: 10px;
      bottom: 50%;
      transform: translateY(50%);
    }

    input {
      padding-left: 20px !important;
    }
  }
}
