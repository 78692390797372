.im-holding {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #e6ecf3;
    min-height: 56px;
    font-size: 14px;
    line-height: 24px;
    margin-top: 22px;
    padding: 18px 16px;

    &-analysis {
      font-size: 16px;
      line-height: 16px;
      max-height: 50px;
      max-width: 457px;
      padding-right: 26px;
      span {
        border: solid 1px red;
        display: inline-block;
        text-align: right;
        &:first-child {
          text-align: left;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    font-size: 16px;
    line-height: 26px;
    min-height: 56px;
    background: white;
    padding: 16px 16px;
    &--error {
      background: rgba(216, 118, 28, 0.05);
      svg {
        color: #d8761c;
        transform: translateY(5px);
      }
    }

    &:not(:last-child) {
      border-bottom: solid 1px #e6ecf3;
    }
    &-import {
      border-top: solid 2px #e6ecf3;
      margin-top: 16px;
      padding: 0px 0px;
    }
    &-update {
      border-top: solid 1.5px #e6ecf3;
      padding: 0px 0px;
    }
  }

  &__label-w {
    display: inline-block;
    text-align: left;
    flex: 4;
    max-width: 488px;

    &--empty {
      background: #aeb6be;
      margin: 10px 128px 0px 0px;
      max-width: 360px;
      max-height: 10px;
    }
  }

  &__label {
    display: inline-block;
    text-align: left;
    min-width: 120px;
    &:empty {
      background: #aeb6be;
      margin: 10px 38px 0px 0px;
      min-width: 72px;
      max-height: 10px;
    }
  }

  &__label-right {
    display: inline-block;
    text-align: right;
    min-width: 120px;

    &--tight {
      margin-right: -60px;
    }

    &--total {
      &:empty {
        background: #aeb6be;
        margin: 10px 0px 0px 30px;
        min-width: 80px;
        max-height: 10px;
      }
    }
  }

  /* Overrides for M3 */
  &__tooltip-alarm {
    font-size: 12px;
    line-height: 16px;
    svg {
      color: #d8761c;
      &:hover {
        color: #d8761c;
      }
    }
  }
}
