.marketplace {
  &__w {
    margin-top: 18px;
  }

  &__content {
    margin: 24px;
  }

  &__tile-header-left {
    display: flex;
    align-items: center;
  }

  &__divider {
    width: 1px;
    height: 100%;
    left: 188px;
    top: 24px;
    background: #d2d2d2;
    margin: 0 24px;
  }

  &__offer {
    &-w {
      display: flex;
      width: 100%;
      padding: 24px;
      border-radius: 6px;
      margin-bottom: 24px;
      height: 208px;
      cursor: pointer;
      background: #ffffff;
      &:hover {
        box-shadow: 0px 0px 4px #d2d2d2;
      }
    }
    &-left {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        width: 139px;
        height: 44.13px;
      }
    }

    &-right {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      flex: 4;
    }

    &-name {
      font-weight: 500;
      font-size: 16px;
      inline-size: 135px;
    }

    &-tag {
      border: 1px solid #0068ae;
      font-size: 10px;
      margin-top: 8px;
      padding: 3px 6px;
      border-radius: 4px;
      display: inline-block;
    }

    &-toggle {
      display: flex;
      justify-content: flex-end;
      flex: 0.5;
    }
    &-details {
      margin-bottom: 13px;

      &--gray {
        color: #918f8f;
        font-size: 12px;
        margin: 0;
      }
      span {
        text-decoration: underline;
        cursor: pointer;
        margin-left: 3px;
      }
    }
  }
  &__disclosure {
    line-height: 1.5;
    color: #231f20;
    font-size: 14px;
    display: block;
    a {
      text-decoration: underline;
    }
  }
  &__confirmation {
    line-height: 1.3;
    color: #231f20;
    font-size: 14px;
    ul {
      list-style-type: disc;
      margin: 10px 16px 0 16px;
    }
  }
}
