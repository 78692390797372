$blue: #255eba;
$green: #a2d134;
$orange: #ffbe1a;
$yellow: #ffde8c;
$grey: grey;
$circ: 440;

.override-btn {
  background-color: #f2f2f2;
  border-radius: 4px;
  color: $blue;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  padding: 7px 12px;
  cursor: pointer;
  margin-right: 10px;
}

.client-allocation {
  &__ratio-wrapper {
    padding-bottom: 7px;
    display: flex;
    justify-content: space-around;
  }

  &__ratio-data {
    font-weight: normal;
    color: #231f20;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    border-radius: 4px;
    background-color: #f2f5fa;
    padding: 3px 10px;
  }

  &__name {
    display: flex;
    color: #231f20;
    font-size: 14px;
    line-height: 16px;
    align-items: center;

    div {
      height: 12px;
      width: 12px;
      margin-right: 4px;
      border-radius: 50%;
    }
  }

  &__data {
    color: #231f20;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    text-align: right;
  }

  &__graph {
    display: flex;
    padding-top: 20px;

    svg {
      transform: rotate(-90deg);

      circle {
        fill: none;
        stroke-width: 20;
        stroke-dasharray: 0 $circ;
        transition: stroke-width 0.3s ease;
      }
    }

    &-equity {
      stroke: $blue;
    }

    &-fixed {
      stroke: $orange;
    }

    &-cash {
      stroke: $yellow;
    }

    &-alternate {
      stroke: $green;
    }

    &-rest {
      stroke: $grey;
    }
  }

  &__buttons-w {
    margin: 10px 25px;
    text-align: right;
  }

  &__total-investment {
    display: flex;
    justify-content: space-around;
    padding-top: 29px;
  }

  &__progress-bar {
    height: 10px;
    border-radius: 2px;
    background-color: #255eba;
  }
}
