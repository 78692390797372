.c-settings__info {
  color: #231f20;
  &-w + &-w {
    margin-top: 60px;
  }

  &-main {
    display: flex;
    align-items: center;
  }

  &-pic {
    margin-right: 15px;

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
  }

  &-badge {
    display: flex;
    margin-top: 5px;

    &:before {
      content: '';
      font-size: 10px;
      padding: 3px 6px;
      border-radius: 4px;
      color: white;
    }
    &--prime:before {
      content: 'Primary';
      background-color: $c-blue;
    }
    &--secondary:before {
      content: 'Secondary';
      background-color: #a2a4b0;
    }
  }

  &-name {
    display: flex;
    align-items: center;

    &-w {
      font-size: 24px;
      color: $c-nero;
      font-weight: $font-w-bold;
      margin-left: 10px;
    }
  }

  &-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $c-gray;
    margin-left: 5px;
  }

  &-details-main {
    margin: auto;
    width: 80%;
    display: flex;
    flex-wrap: wrap;

    > * {
      display: flex;
      align-items: center;
      width: 50%;
      flex: 0 1 50%;
      padding-top: 25px;
      color: $c-nero;
      font-size: 14px;
      line-height: 18px;

      span {
        margin-right: 10px;

        svg path {
          fill: $c-gray;
        }
      }
    }
  }
}

.c-settings__gc {
  border-top: 1px solid $c-gray-light;
  margin-top: 24px;
  padding-top: 24px;
  &-w {
    padding-bottom: 24px;
    border-bottom: 1px solid $c-gray-light;
    margin-bottom: 8px;
  }
  &-account-status {
    font-size: 14px;
    line-height: 20px;
    &--locked,
    &--not-created,
    &--deactivated {
      color: #f12938;
    }
    &--active {
      color: #068667;
    }
  }

  &__btn {
    &--w {
      display: flex;
    }
    &--w-without-dropdown {
      display: flex;
      padding-top: 75px;
    }
    &--text {
      font-size: 12px;
      font-weight: $font-w-bold;
      line-height: 17px;
      display: flex;
      align-items: center;
    }
    &--info {
      padding: 13px 0 0 10px;
      font-size: 14px;
    }
  }

  &-h {
    font-size: 16px;
    font-weight: $font-w-bold;

    &-toggle {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: $font-w-bold;
        display: flex;
        align-items: center;

        &.enabled {
          color: #a2d134;
        }

        &.disabled {
          color: #f12938;
        }
      }
    }
  }

  &-info-text {
    font-size: 12px;
    color: $c-gray;
    margin: 20px 0 30px 0px;
  }

  &-error-text {
    font-size: 12px;
    margin: 20px 0;
    color: $c-status-bad;
  }

  &--error {
    font-size: 12px;
    color: $c-status-bad;
    margin: -5px 0 15px 0;
  }

  &-lastemail,
  &-lastpassword {
    margin-top: 16px;
    font-size: 12px;
    span {
      font-size: 12px;
      font-weight: $font-w-bold;
    }
  }

  &-lastseen {
    margin-top: 24px;
    font-size: 12px;

    span {
      font-size: 12px;
      font-weight: $font-w-bold;
    }

    &-history {
      display: flex;
      cursor: pointer;
      color: $c-blue;
      font-size: 12px;
      margin-top: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-user-email {
    margin: 24px 0 0;
    display: flex;
    &-textview {
      font-size: 12px;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      min-width: 220px;
    }
    > div {
      flex: 0 1 50%;
      width: 50%;

      &:first-child {
        margin-right: 15px;
      }

      label {
        font-size: 14px;
        display: flex;
        margin-bottom: 3px;
      }

      input {
        width: 100%;
        color: $c-nero;
      }
    }
  }

  &-user-select {
    width: 100%;
    margin-bottom: 24px;
    margin-right: 20px;
    display: flex;
    > div {
      flex: 0 1 50%;
      width: 50%;
      padding-right: 6px;
      span {
        background-color: white;
      }
    }
  }

  &-user-select-label {
    color: #231f20;
    font-size: 14px;
    line-height: 14px;
    display: block;
    padding-bottom: 6px;
  }

  &-email {
    &-w {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 30px 0px 24px 0px;

      h4 {
        margin-bottom: 10px;
      }
    }

    &-btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: $c-gray-lighter;
      color: $c-blue;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      &:first-child {
        margin-right: 30px;
      }

      &-w {
        display: flex;
        align-items: center;
      }

      &--clear {
        background-color: transparent;
      }

      &:hover {
        text-decoration: underline;
      }

      & + & {
        margin-left: 10px;
      }
    }
    &-password {
      margin-top: 8px;
    }
  }

  &-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0px;
    border-top: 1px solid $c-gray-light;

    &:last-child {
      border-bottom: 1px solid $c-gray-light;
    }
  }

  &-save-btn-w {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
  }
  &-loadingtext {
    color: #8a8888;
  }
}

.send-modal {
  &__content {
    padding: 24px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    color: $c-titles;

    &--input {
      font-size: 14px;
      line-height: 16px;
      padding: 10px;
      margin-top: 6px;
      border-radius: 6px;
      border: 1px solid $c-borders;

      ::placeholder {
        color: $c-icons;
      }
    }
  }

  &__buttons {
    margin-top: 40px;
    align-self: flex-end;
    display: flex;
  }
}
