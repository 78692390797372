.results {
  &-tile {
    &-w {
      display: flex;
      margin-top: 16px;
    }
  }
  &__button-w {
    margin: 14px;
    display: flex;
    justify-content: center;
  }
  &__allocation {
    &-w {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 24px;
    }
    &-title {
      font-size: 15px;
      line-height: 22px;
      color: $c-nero;
    }
    &-graph {
      margin: 16px;
      position: relative;
      display: flex;
      justify-content: center;
      &-value {
        position: absolute;
        align-self: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
      }
    }
    &-legend {
      font-size: 14px;
      line-height: 16px;
      margin: 0 20px;
      display: flex;
      &-circle {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        margin: 0 8px;
        &--stocks {
          background-color: $c-stocks;
        }
        &--bonds {
          background-color: $c-bonds;
        }
      }
      &-w {
        display: flex;
        margin: 24px 0;
      }
    }
  }
  &__geography {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px;
    &-image {
      margin: 16px;
    }
    &-text {
      font-size: 15px;
      line-height: 22px;
    }
    &-description {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__priorities {
    &-w {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
