.strategy-search {
  &__input-w {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 280px;
    margin-left: 95px;
  }

  &__input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: #22263f;
    font-size: 16px;
    height: 21px;
    // padding is spec to images
    // adjust with caution
    padding: 15px;
    padding-left: 38px;
    padding-right: 16px;
    background-image: linear-gradient(#e6ecf3, #e6ecf3);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    transition: background-size 300ms;

    &:focus {
      background-size: 100% 1px;

      &::placeholder {
        opacity: 0;
      }
    }

    &::placeholder {
      transition: opacity 300ms;
      opacity: 1;
      color: #22263f;
      font-size: 16px;
    }
  }

  &__input-icon {
    position: relative;
    right: -24px;
    width: 100%;
    max-width: 24px;
  }

  &__input-clear {
    opacity: 0;
    position: relative;
    left: -16px;
    width: 100%;
    max-width: 16px;
    transition: opacity 300ms;

    &--show {
      opacity: 1;
      cursor: pointer;
    }
  }
}
