.moneymind__graphic {
  max-width: 150px;
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 0 1%;

  &-bg {
    opacity: 0.1;
    top: 15px;
    position: relative;
  }

  &-cell {
    display: inline-block;
    vertical-align: middle;
    margin: 0.1vw;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  &-icon {
    display: block;
    width: 10px;
    height: 10px;
  }

  &-placeholder {
    opacity: 0.2;
    background-color: $c-gray-light;
  }

  &-w {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-perctage {
    text-align: center;
    float: none;
    font-weight: $font-w-bold;
    font-size: 24px;
    padding-bottom: 10px;
  }

  &-label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    float: none;
    font-weight: $font-w-bold;
    font-size: 15px;
    padding: 5px 0 25px;

    svg {
      margin-right: 5px;
    }
  }
}
