.honest-conversation {
  padding: 61px 23px;
  display: flex;
  max-height: 240px;
  justify-content: space-between;
  &__boxes {
    height: 120px;
    margin: 0.3em;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    padding: 24px 22px;
    flex: 1;
    p {
      padding: 20px 0px;
    }
    &-img {
      position: absolute;
      bottom: 5%;
      right: 5%;
    }
  }
  &__boxes-protection {
    background-color: #a7d500;
  }

  &__boxes-commitment {
    background-color: #0068ae;
  }

  &__boxes-happiness {
    background-color: #f49500;
  }
}

.no-result {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    padding-top: 51px;
  }

  &__heading {
    color: #255eba;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding-top: 12px;
    text-align: center;
  }

  &__sub-heading {
    padding-top: 12px;
    color: #231f20;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding-bottom: 51px;
    max-width: 342px;
  }
}
