.slim-funded-score {
  &__w {
    width: 100%;

    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;

    &--placeholder {
      opacity: 0.3;
    }
  }

  &__marker {
    position: absolute;

    top: -15px;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    line-height: -15px;

    color: $c-blue-light;
    padding: 0 5px;
    font-family: Helvetica;

    &--left-line {
      //
      border-left: 1px solid $c-active-border;
    }

    &--right-line {
      //
      border-right: 1px solid $c-active-border;
    }
  }

  &__graph-w {
    display: flex;
    flex-direction: row;
  }

  &__gradient {
    width: 100%;
    background-image: linear-gradient(
      to right,
      $c-underfunded,
      $c-ideal,
      $c-overfunded
    );
  }

  &__section--underfunded {
    &-placeholder {
      width: 70%;
    }
  }

  &__section--ideal {
    &-placeholder {
      width: 20%;
    }
  }

  &__section--overfunded {
    &-placeholder {
      width: 10%;
    }
  }

  &__bar {
    //
    height: 8px;
    flex: 1;

    &--underfunded {
      background-color: $c-underfunded;
    }

    &--ideal {
      background-color: $c-ideal;
    }
    &--overfunded {
      background-color: $c-overfunded;
    }
  }

  &__label-w {
    display: flex;
    flex-direction: row;
  }

  &__bar-w {
    //
    height: 8px;
    align-items: center;
    display: flex;

    &--gradient {
      width: 100%;
    }

    &--ideal {
      border-left: solid 1px rgba(35, 31, 32, 0.3);
      border-right: solid 1px rgba(35, 31, 32, 0.3);
    }
  }

  &__section {
    width: 100%;
  }

  &__label {
    flex: 1;
    //
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-nero;
    //
    margin-top: 4px;

    &--overfunded {
      text-align: right;
    }

    &--ideal {
      text-align: center;
    }
  }

  &__label-underfunded--placeholer {
    width: 80px;
    padding: 5px 0;
    background-color: $c-gray-light;
  }

  &__label-ideal--placeholer {
    padding: 5px 0;
    width: 60px;
    background-color: $c-gray-light;
  }

  &__label-overfunded--placeholer {
    padding: 5px 0;
    width: 80px;
    background-color: $c-gray-light;
  }
}
