.hc-components {
  &__row {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;

    &__end {
      align-content: flex-end;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow: hidden;

    &-start {
      align-items: flex-start;
    }
    &-end {
      align-items: flex-end;
    }
  }

  &__legend {
    margin: 5px 5px 0 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &__line {
    margin-top: 2px;
    height: 4px;
    width: 24px;

    &--primary {
      border-top: solid 2px #cabeb3;
      &:before,
      &:after {
        background: #cabeb3;
      }
    }
    &--secondary {
      border-top: solid 2px #989dc2;
      &:before,
      &:after {
        background: #989dc2;
      }
    }

    &:before,
    &:after {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      float: left;
      margin-top: -3px;
    }
    &:after {
      float: right;
    }
  }

  &__combined-comment {
    width: 100%;
    padding: 24px 24px 24px 26px;
    font-size: 15px;
    line-height: 20px;
  }
}
