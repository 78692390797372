.input-field {
  &__money {
    position: relative;
    &:before {
      content: '$';
      color: $c-gray;
      position: absolute;
      left: 10px;
      bottom: 50%;
      transform: translateY(50%);
    }
    input {
      padding-left: 20px !important;
    }
  }

  &__percentage {
    position: relative;
    &:before {
      content: '%';
      color: $c-gray;
      position: absolute;
      left: 10px;
      bottom: 50%;
      transform: translateY(50%);
    }
    input {
      padding-left: 20px !important;
    }
  }

  input {
    width: 100%;
    padding-left: 10px;
    min-height: 36px;
    border-radius: 4px;
    border: 1px solid $c-gray-light;
    font-size: 14px;
    color: $c-nero;
    &:disabled {
      background-color: $c-gray-lightest;
      color: $c-gray;
    }
  }
}
