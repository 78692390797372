.money-mind {
  padding: 25px 72px 39px 60px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 240px;
  $self: &;

  &__wrapper {
    flex: 3;
  }

  &__header {
    color: #231f20;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    font-weight: 500;
    &-protection {
      color: #a7d500;
    }
    &-commitment {
      color: #0068ae;
    }
    &-happiness {
      color: #f49500;
    }
  }
  &__ratings {
    padding-top: 32px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    &-logo {
      margin-bottom: 8px;
    }
    &-text {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
    }
    &-green {
      color: #a7d500;
    }
    &-blue {
      color: #0068ae;
    }
    &-orange {
      color: #f49500;
    }
    &-bottom {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: center;
    }
  }

  &__divider {
    flex: 1;
    &-line {
      height: 116px;
      width: 1px;
      background-color: #e5e5e5;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.no-result {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    padding-top: 51px;
  }

  &__heading {
    color: #255eba;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding-top: 12px;
    text-align: center;
  }

  &__sub-heading {
    padding-top: 12px;
    color: #231f20;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding-bottom: 51px;
    max-width: 342px;
  }
}
