/* Accordion Override */
.accordion {
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  box-shadow: 0 1px 0 0 #d2d2d2;
  background-color: #fafafa;
}

.accordion__title {
  color: #231f20;
  background-color: #fafafa;
  padding: 13px 12px;
  background-color: unset;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  outline: none;
}

.accordion__title:hover {
  background-color: unset;
}

.accordion__body {
  padding: 0px;
  border-radius: 6px;
  background-color: #ffffff;
}

.accordion__arrow {
  width: 20px;
  height: 12px;
}

.accordion__arrow::after,
.accordion__arrow::before {
  content: '';
  color: #255eba;
  width: 8px;
  height: 3px;
}

.title-text {
  color: #231f20;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
