.pref-sort-settings {
  &__gear-btn {
    margin-left: 10px;
    cursor: pointer;
    width: 24px;
  }

  &__dropdown-menu {
    position: absolute;
    z-index: 800;
    min-width: 260px;
    top: 52px;
    font-weight: 300;
    font-size: 14px;
    background-color: white;
    border-radius: 6px;
    border: solid $c-borders 1px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      left: 11px;
      top: -19px;
      border-color: transparent transparent white transparent;
      border-width: 10px;
    }

    &:before {
      left: 10px;
      top: -22px;
      border-color: transparent transparent #d2d2d2 transparent;
      border-width: 11px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0 8px 12px;
    min-height: 45px;
    background: #fafafa;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 6px 6px 0 0;
    &-label {
      display: flex;
      align-items: center;
    }

    &-text {
      margin-left: 5px;
      font-size: 14px;
      font-weight: $font-w-bold;
    }
  }

  &__item {
    padding: 8px 16px;
    min-height: 45px;

    &-header {
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__radiogroup-item {
    padding-top: 8px;
    label span {
      padding: 8px;
    }
  }

  &__buttons {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    padding-bottom: 15px;
  }
}
