.toggle-switch {
  order: 2;
  position: relative;
  display: inline-block;
  text-align: left;
  width: 34px;
  min-width: 34px;
  height: 14px;
}

.toggle-switch input {
  display: none;
}

.toggle-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $c-blue;
  transition: all 0.4s;
}

.toggle-switch__slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  bottom: -3px;
  background-color: $c-blue;
  transition: all 0.4s;
}

input:checked + .toggle-switch__slider {
  background-color: #8eabe0;
}

input:not(:checked) + .toggle-switch__slider {
  background-color: $c-gray-lightest;
}

input:focus + .toggle-switch__slider {
  box-shadow: 0 0 1px #8eabe0;
}

input:checked + .toggle-switch__slider:before {
  background-color: $c-blue;
  transform: translateX(15px);
}

.toggle-switch__slider.toggle-switch__round {
  border-radius: 34px;
}

.toggle-switch__slider.toggle-switch__round:before {
  background-color: $c-gray;
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.5);
  border-radius: 50%;
}
