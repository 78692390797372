.ivf-summary {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__answer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    padding-right: 50px;
    svg {
      height: 20px;
      width: 20px;
    }

    &-number {
      align-content: right;
      font-weight: 900;
      margin-right: 20px;
      text-align: right;
      flex: 2;
    }

    &-icon {
      margin-right: 10px;
    }

    &-statement {
      flex: 4;
    }

    &-update {
      display: flex;
      align-items: center;
      order: 1;
      cursor: pointer;
      color: $c-blue;
      text-align: left;
      flex: 2;
      svg {
        fill: $c-blue;
      }
    }
  }
}
