@import 'hcPageheading.scss';
@import 'hcwHeader.scss';
@import 'hcwWelcome.scss';

.hcw {
  &__w {
    min-height: 100vh;
    width: 100%;
    padding-bottom: 60px;
    background: url('../../../images/honestConversations/hc-welcome-bg.png')
      no-repeat 0 0;
    background-size: cover;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 730px;
  }
}

.hc-exercise {
  &__w {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }
}

.tile {
  position: relative;

  &__btn-back {
    color: #255eba !important;

    > img {
      position: relative;
      margin-right: 3px;
      left: 0;
      transition: left 300ms;
    }

    &:hover {
      background-color: white !important;

      > img {
        left: -5px;
      }
    }
  }
}
