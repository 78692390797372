@import 'confirmDeactiveModal';
@import 'notesModal';
@import 'palNull';
@import 'hcCardModal';

$c-name: '.pal';

#{$c-name} {
  &__col {
    color: $c-gray;

    &-w {
      display: flex;
      width: 100%;
    }

    &-item {
      position: relative;
      display: inline-flex;
      align-items: center;
      color: $c-nero;

      &--menu {
        color: $c-gray;
      }

      &--large {
        width: 29%;
      }

      &--medium {
        width: 13%;
      }

      &--small {
        width: 9%;
      }

      &--xsmall {
        width: 7%;
      }

      &--center {
        justify-content: center;
      }

      &--right {
        justify-content: flex-end;
      }
    }
  }

  &__row {
    padding: 5px 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2;
    }
  }

  &__sort {
    display: flex;
    align-items: center;

    &-w {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        vertical-align: middle;
        width: 16px;
        margin-bottom: 2px;
        margin-left: 2px;
        path {
          fill: $c-gray;
        }
      }
    }

    &-label {
      cursor: pointer;
    }

    label {
      cursor: pointer;
      line-height: 1;
    }

    &-carrot {
      cursor: pointer;
      max-width: 8px;
      margin-left: 7px;
      transform: rotate(0);
      transition: 300ms;

      &--up {
        transform: rotate(180deg);
      }
    }
  }

  &__warning {
    padding: 5px 16px;
    color: $c-warning;
    flex: 0 0 100%;
    border-radius: 3px;
  }

  &__header-popover {
    position: relative;
    width: 275px;
  }
}

#{$c-name}__name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 55px;

  h5 {
    font-size: 14px;
    font-weight: $font-w-bold;
  }

  span {
    position: relative;
    color: $c-gray;
    font-size: 12px;
    white-space: nowrap;
    max-width: 90%;
    margin-top: 8px;
    overflow: hidden;
    padding-right: 10px;

    &:after {
      content: '...';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: white;
    }
  }
}

#{$c-name}__status {
  &-select {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 8px;
    background-color: transparent;

    &:hover,
    &--open {
      transition: background-color 300ms;
      background-color: #f2f2f2;
    }
  }

  &-icon {
    display: flex;
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 10px;

    &-w {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-label {
      font-size: 14px;
    }

    &--select {
      cursor: pointer;
      border-radius: 4px;
      padding: 8px;
      color: $c-blue;
      transition: background-color 300ms;
      background-color: #f2f2f2;

      &:hover,
      &-open {
        background-color: #e6e6e6;
      }
    }

    &--green {
      background-color: #e8f3cc;

      &:after {
        background-color: #8ccc33;
      }
    }

    &--orange {
      background-color: #ffedd1;

      &:after {
        background-color: #ffbe1a;
      }
    }

    &--red {
      background-color: #fae1e2;

      &:after {
        background-color: #b21e28;
      }
    }

    &--na {
      background-color: #e5e5e5;

      &:after {
        background-color: $c-gray;
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 10px;
      width: 10px;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 50%);
      border-radius: 50%;
    }
  }

  &-menu {
    position: absolute;
    z-index: 500;
    display: block;
    width: 245px;
    right: -10px;
    top: 45px;
    font-size: 14px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid #d2d2d2;

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      top: -19px;
      right: 11px;
      border-color: transparent transparent white transparent;
      border-width: 10px;
    }

    &:before {
      top: -22px;
      right: 10px;
      border-color: transparent transparent #d2d2d2 transparent;
      border-width: 11px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 12px;
      cursor: pointer;
      min-height: 45px;

      &:not(:last-child) {
        border-bottom: 1px solid #d2d2d2;
      }

      &:hover {
        text-decoration: underline;
      }

      label {
        width: 100%;
        text-align: left;
        cursor: pointer;
      }

      span {
        transition: color 300ms;
        padding: 5px 0;
      }

      &--active {
        cursor: unset;

        label {
          color: $c-blue;
          cursor: unset;
        }

        &:hover {
          text-decoration: none;
        }

        span {
          color: $c-blue;
        }
      }

      &--active svg path {
        fill: $c-blue;
      }
    }
  }
}

#{$c-name}__check-in {
  font-size: 14px;

  svg {
    margin-right: 5px;

    path {
      fill: $c-gray-light;
    }
  }

  &-select {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px 10px 5px 5px;
    background-color: #f2f2f2;
    color: $c-blue;
    transition: color 300ms, background-color 300ms;
    width: 92%;

    &:hover {
      background-color: #e6e6e6;
    }

    svg path {
      fill: $c-blue;
    }
  }

  &-set-select {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
    background-color: transparent;
    transition: background-color 300ms;
    width: 92%;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

#{$c-name}__notes {
  & svg {
    cursor: pointer;

    path {
      transition: fill 300ms;
      fill: $c-gray-light;
    }
  }

  & svg:hover path {
    fill: $c-blue;
  }
}

#{$c-name}__cards {
  &-w {
    width: 50%;
    position: relative;
    cursor: pointer;
  }

  &-item {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
  }

  &-image {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: white;
  }
}
