.radio {
  &__label {
    padding-left: 5px;
  }

  &__hover:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
