.hc-score-history {
  &__w {
    display: flex;
  }
  &__ideal-graph {
    &-w {
      margin-left: 5px;
      width: 100px;
      max-height: 32px;
      background-color: #f5f5f6;
      display: flex;
    }
    &-bar {
      height: 100%;
    }
    &-benchmark {
      border-right: 1px dashed #231f20;
      opacity: 0.3;
      flex: 1;
      position: relative;
    }
  }
  &__results {
    &-w {
      display: flex;
      padding: 14px 20px;

      &:hover {
        background-color: $c-background-hover;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $c-gray-light;
      }
    }

    &-active {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 3;

      h4 {
        font-size: 14px;
        margin-right: 20px;
        font-weight: $font-w-bold;
      }
    }

    &-results {
      display: flex;
      align-items: center;
      flex: 5;
    }

    &-updated {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 3;
    }

    &-actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  &__hc {
    &-icon {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      padding: 7px 17px;
      border-radius: 4px;
      margin-right: 8px;

      img {
        max-width: 18px;
      }

      &--protection {
        background-color: $c-protection;
      }
      &--commitment {
        background-color: $c-commitment;
      }
      &--happiness {
        background-color: $c-happiness;
      }
    }
  }

  &__active-badge {
    display: none;

    &:after {
      content: 'Active';
      background-color: $c-green;
      color: white;
      font-size: 10px;
      border-radius: 4px;
      padding: 2px 6px;
    }

    &--active {
      display: flex;
    }
  }

  &__active-img {
    max-width: 24px;
    width: 100%;
    margin-right: 5px;
  }

  &__graph {
    position: relative;
    width: 85px;
    display: flex;
    height: 7px;
    border-radius: 4px;
    background-color: #e2e2e2;

    &-w {
      display: inline-flex;
      flex-direction: column;
      max-width: 170px;
      width: calc(95% / 4);

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &-label {
      font-size: 11px;
      margin-top: 3px;
    }

    &-score,
    &-ideal {
      border-radius: 4px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    &-score {
      z-index: 501;
    }

    &-ideal {
      &:after {
        content: '';
        z-index: 503;
        display: inline-block;
        position: absolute;
        right: -1px;
        bottom: 50%;
        transform: translateY(50%);
        height: 10px;
        width: 1px;
        background-color: #231f20;
        opacity: 0.3;
      }
    }
  }
}
