.billing__summary {
  &-pdf {
    overflow-y: auto;
    background-color: grey;

    @media (max-height: $media-xxsmall-height) {
      height: 300px;
    }

    @media (min-height: $media-xxsmall-height) and (max-height: $media-medium-height) {
      height: 380px;
    }

    @media screen and (min-height: $media-medium-height) and (max-height: $media-xlarge-height) {
      height: 440px;
    }

    @media screen and (min-height: $media-xlarge-height) and (max-height: $media-xxlarge-height) {
      height: 540px;
    }

    @media (min-height: $media-xxlarge-height) {
      height: 650px;
    }
  }

  &-hidden-loaders {
    display: none;
  }

  &-btn {
    display: flex;
    padding: 20px 20px;
    justify-content: flex-end;
    flex: 1 1;
  }

  &--body {
    border: solid 1px green;
    margin: 20px 35px;
    padding: 20px 35px;
  }

  &--logo {
    img {
      width: 100px;
    }
  }

  &--title {
    color: $c-nero;
    font-size: 24px;
    font-weight: 500;
    padding-top: 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid $c-gray-light;
    margin-bottom: 35px;
  }

  &--row {
    display: flex;
    flex-direction: row;
  }

  &--col {
    width: 50%;
    display: flex;
    flex-direction: column;

    &-title {
      padding-bottom: 20px;

      label {
        font-size: 16px;
        color: $c-nero;
        font-weight: 600;

        span {
          &::before {
            content: '';
            padding-top: 10px;
            display: block;
          }
        }
      }
    }

    &-notes {
      color: $c-gray-light;
      line-height: 18px;
      font-size: 14px;
    }

    &-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      &-last {
        margin-top: 40px;
        margin-bottom: 50px;
      }

      &-icon {
        align-content: center;
        width: 40px;

        &-left {
          text-align: center;
        }
      }

      &-name {
        flex: 2;
        line-height: 25px;
        margin-right: 10px;
      }

      &-included {
        flex: 1;
      }

      &-feevalue {
        font-weight: 600;
        line-height: 25px;
        justify-content: flex-end;
      }
    }

    &-disclaimer {
      line-height: 18px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &-signatureleft {
      justify-content: flex-start;
      padding-right: 25px;
    }

    &-signatureright {
      justify-content: flex-end;
      padding-left: 25px;
    }

    &-clientname {
      line-height: 40px;
      font-weight: 700;
      font-size: 20px;
      border-bottom: solid 1px $c-gray-light;

      &-blank {
        margin-top: 50px;
      }
    }

    &-sub {
      flex: 1;
      padding-top: 10px;
      font-size: 14px;

      &-date {
        text-align: center;
        justify-content: flex-end;
      }
    }
  }
}
