.client-account-details {
  padding: 20px;

  &-w {
    margin-top: 18px;
  }

  &__arrow-down {
    display: flex;
    flex-direction: row;
  }

  label {
    font-size: 14px;
    display: block;
    line-height: 14px;
    padding-bottom: 6px;
    text-align: left;
  }

  input {
    padding-left: 10px;
  }

  &__form-group {
    flex: 0 0 32%;
  }

  &__investment-inputs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  &__inputs {
    width: 100%;

    > div:last-child {
      margin-right: 0px;
    }

    &-short {
      width: 74%;

      > div:last-child {
        margin-right: 0px;
      }

      &-pad {
        span {
          input {
            padding-left: 10px;
          }
        }
      }
    }

    &-money {
      position: relative;

      input {
        padding-left: 20px;
      }

      &:before {
        content: '$';
        color: $c-gray;
        position: absolute;
        left: 10px;
        padding-top: 31px;
      }
    }

    &-radio {
      input[type='radio'] {
        position: relative;
        cursor: pointer;
        margin: 0;
        margin-top: 10px;
      }

      input[type='radio']:before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: -1px;
        border: 1px solid #b8b8b8;
        border-radius: 3px;
        background-color: white;
      }

      input[type='radio']:checked:after {
        content: '';
        display: block;
        width: 5px;
        height: 10px;
        border: solid $c-blue;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 6px;
      }
    }

    &-readonly {
      input {
        background-color: #f0f0f0;
      }

      input[type='text']:focus {
        background-color: #fafafa;
        border: 1px solid #c2c2c2;
        box-shadow: none;
      }

      input[type='number']:focus {
        background-color: #fafafa;
        border: 1px solid #c2c2c2;
        box-shadow: none;
      }
    }

    &-custom-select {
      color: #231f20;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__inputs-column {
    width: 100%;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__inputs-desc {
    width: 100%;
    flex: 1 1 100%;

    label {
      font-size: 14px;
      display: block;
      line-height: 14px;
      padding-bottom: 6px;
    }

    textarea {
      border-radius: 4px;
      width: 100%;
      height: 100px;
      max-height: 100px;
      padding: 10px;
      font-family: $font-main;
    }

    div {
      width: 49%;
    }
  }

  &__graph {
    &-w {
      padding: 20px 0;
      width: 100%;
    }

    &-doughnut {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 150px;

      &-data {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: $font-w-bold;
        margin-bottom: 8px;

        &-edit {
          cursor: pointer;
          position: absolute;
          right: 5px;
          color: $c-gray;
        }
      }

      &-text {
        margin-top: 20px;
        color: $c-gray;
        font-size: 11px;
        text-align: center;
      }
    }

    &-bar {
      width: 100%;
      display: flex;

      &--equity {
        background-color: $c-blue;
      }

      &--fix {
        background-color: $c-yellow;
      }

      &--cash {
        background-color: #ffde8c;
      }

      &--alt {
        background-color: #a2d134;
      }

      &--unclass {
        background-color: #8a8888;
      }

      > div {
        border-radius: 2px;
        height: 100%;
      }

      > span {
        margin-left: 8px;
        font-size: 14px;
      }

      &-x {
        display: inline-flex;
        width: 83%;
        flex-direction: column;
        padding-left: 18px;
      }

      &-w {
        display: inline-flex;
        width: 100%;
        padding-left: 18px;
        flex-direction: column;
      }

      &--label,
      &--data {
        display: flex;

        > *:first-child {
          width: 100%;
          max-width: 145px;
          margin-right: 5px;
        }

        > *:nth-child(2) {
          width: 100%;
          max-width: 700px;
          margin-right: 5px;
        }

        > *:nth-child(3) {
          text-align: right;
          min-width: 100px;
          width: 200px;
        }
      }

      &--label {
        font-weight: $font-w-bold;
        font-size: 14px;
        padding-bottom: 8px;
        border-bottom: 1px solid $c-gray-light;
      }

      &--data {
        padding: 8px 0;
        font-size: 14px;

        &:not(:last-child) {
          border-bottom: 1px solid $c-gray-light;
        }
      }
    }
  }

  &__inputs-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__datepicker {
    border: solid 1px #c2c2c2;
    border-radius: 4px;
    padding: 5px 0px 5px 0px;
    svg {
      vertical-align: middle;

      path {
        fill: #d2d2d2;
      }
    }

    .react-datepicker-wrapper {
      vertical-align: top;
      width: 100%;
    }

    .react-datepicker__input-container {
      display: flex;

      input {
        cursor: pointer;
        width: 100%;
        font-weight: $font-w-light;
        font-size: 14px;
        background-color: transparent;
        border: none;
        padding-left: 10px;
      }
    }

    &-changed,
    &:hover {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
    }
  }

  &__datepicker-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
  }

  &__datepicker--active {
    display: inline-block;
    width: 78%;
    border: none;
  }

  &__override-allocation-modal {
    p {
      margin: 10px auto;
      color: $c-nero;
      font-size: 14px;

      &:first-of-type {
        margin-bottom: 25px;
      }
    }

    &__content {
      padding: 24px;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin: 20px 24px;
    }
  }

  &__right-header {
    width: auto;
    display: flex;
    flex-direction: row;

    &-button {
      margin-left: 15px;
    }

    &--value {
      border-left: 1px solid $c-gray-light;
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
      text-align: right;
      margin-left: 16px;
      padding-left: 15px;
    }
  }
}

.ca-header-ctrl {
  font-size: 0.9em;
  display: flex;
  line-height: 14px;

  > :nth-child(odd) {
    padding-right: 10px;
    padding-left: 10px;
  }
}
