.gb-container__nav {
  // main nav styles are in index
  &-custom {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-hidden-fileinput {
      display: none;
    }

    &--label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: gainsboro;
      color: #255eba;
      padding: 7px;
      border-radius: 5px;

      &-null {
        height: 40px;
        width: 85px;
      }
    }
  }
  &-checkbox-w,
  &-input-w {
    &:not(:last-child) {
      border-bottom: 1px solid $c-gray-light;
    }
  }

  &-checkbox-w {
    transition: background-color 300ms;

    &:hover .checkbox-w {
      background-color: $c-white-smoke;
    }

    &:hover .checkbox-w--no-toggle-allowed {
      background-color: inherit;
    }
  }

  .checkbox__label {
    font-size: 16px;
  }

  &-input-w {
    padding: 20px 16px;

    span {
      display: block;
      margin-bottom: 6px;
      font-weight: $font-w-light;
      font-size: 14px;
    }

    input {
      display: block;
      width: 100%;
      padding: 5px 10px;
      border-radius: 4px;
      min-height: 36px;
      font-size: 14px;
      border: 1px solid $c-gray-light;
    }
  }

  &-select-w {
    padding: 20px 16px;
    border-bottom: 1px solid $c-gray-light;

    span {
      display: block;
      margin-bottom: 6px;
      font-weight: $font-w-light;
      font-size: 14px;
    }
  }
}
