.strategy-search {
  &__checkbox {
    &-w {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-option {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #22263f;
      padding-left: 40px;
      line-height: 25px;
      width: max-content;
      margin-bottom: 15px;

      &--level-one {
        border-bottom: 1px solid #e6ecf3;
        padding-bottom: 10px;
        font-size: 16px;
        width: 100%;
      }

      &--level-two {
        font-size: 14px;
      }

      input {
        height: 0;
        width: 0;
        margin: 0;
        display: none;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        height: 24px;
        width: 24px;
      }

      &:before {
        display: inline-block;
        border-radius: 4px;
        border: 1px solid #22263f;
        background-color: white;
        transition: background-color 200ms, border-color 200ms;
      }

      &:after {
        transform: scale(0);
        transition: transform 400ms;
      }

      &--checked {
        &:before {
          border-color: #2178c4;
          background-color: #2178c4;
        }

        &:after {
          background: url(../../../images/icons/general/check.png) no-repeat 0 0;
          transform: scale(0.6);
        }
      }

      &--dash {
        &:after {
          background: none;
          width: 12px;
          height: 2px;
          background-color: white;
          transform: scale(1);
          left: 6px;
          position: absolute;
          top: 12px;
        }

        &:before {
          border-color: #2178c4;
          background-color: #2178c4;
        }
      }
    }
  }
}
