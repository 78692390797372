.a-moneymind {
  padding: 24px;

  &__header {
    color: $c-gray;
  }

  &__text {
    top: -5px;
    margin-left: 5px;
    position: relative;
    font-weight: $font-w-bold;
  }

  &-w {
    display: flex;
    min-height: 630px;
  }

  &__gender {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__edit {
    width: 100%;

    h1 {
      font-size: 16px;
      font-weight: $font-w-bold;
      margin-bottom: 25px;
    }

    label {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
      width: 100%;
    }

    &-btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 30px;
      margin-right: 25px;
      width: 100%;
    }

    &-details-main {
      display: flex;
      flex-wrap: wrap;
      padding: 25px;
    }

    &-third-w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      width: 100%;

      div.form-group {
        width: 33%;
        flex: 0 1 33%;
        margin-top: 15px;

        &:not(:first-child) {
          margin-left: 15px;
        }
      }
      label {
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
        width: 100%;
      }
    }

    &-half-w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;

      label {
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
        width: 70%;
      }
    }
  }

  &__date {
    font-size: 14px;

    &-w {
      margin-top: 5;
      position: 'relative';
    }

    svg {
      margin-right: 5px;

      path {
        fill: $c-gray-light;
      }
    }

    &-select {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 5px;
      background-color: #f2f2f2;
      color: $c-blue;
      transition: color 300ms, background-color 300ms;
      width: 98%;
      margin: 0;

      &:hover {
        background-color: #e6e6e6;
      }

      svg path {
        fill: $c-blue;
      }
    }

    &-picker {
      display: none;

      &--active {
        display: inline-block;
        width: 75%;
      }

      .react-datepicker__input-container {
        input {
          cursor: pointer;
          width: 90%;
          font-weight: $font-w-light;
          font-size: 14px;
          background-color: transparent;
          border: none;
        }
      }
      .react-datepicker__header {
        background-color: white;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range {
        border-radius: 50%;
        background-color: $c-blue;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header {
        color: $c-nero;
        font-weight: $font-w-bold;
      }
    }
  }
}
