.priority-bar {
  &-w {
    margin: 24px 24px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 150px;
    width: 100%;
  }
  &__label {
    font-size: 16px;
    line-height: 18px;
    &-img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
    &-w {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 16px 0;
    }
  }
  &__highlight {
    border-radius: 4px;
    padding: 2px 14px;
    background-color: #a2a4b0;
    font-size: 10px;
    line-height: 12px;
    color: $c-white;
  }
  &__target {
    position: absolute;
    width: 100%;
    bottom: 0;

    &:before {
      content: '';
      z-index: 603;
      display: inline-table;
      position: absolute;
      opacity: 100;
      top: 0;
      left: -8px;
      right: 0;
      height: 2px;
      border-bottom: 2px dashed;
      width: 115%;
    }
    &-w {
      margin-top: 20px;
      position: relative;
    }
    &--opaque {
      opacity: 0.3;
    }
    svg {
      position: absolute;
      left: -32px;
      top: -12px;
    }
  }
}
