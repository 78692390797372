.c-settings__billing {
  &-divide {
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: $c-gray-light;
  }

  &-h {
    font-size: 16px;
    font-weight: $font-w-bold;
    margin-bottom: 25px;
  }

  &-tagline {
    font-weight: $font-w-bold;

    &-w {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-p {
      color: $c-gray;
      font-size: 12px;
      margin: 20px auto;
      text-align: justify;
      line-height: 18px;
    }
  }

  &-textfield {
    width: 100%;
    height: 100px;
    color: $c-nero;
    font-size: 14px;
    border-radius: 4px;
    font-family: $font-main;

    label {
      float: right;
      color: $c-gray;
      font-size: 11px;
    }
  }

  &-review {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-select {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        margin-left: 16px;

        input {
          margin: 0;
        }

        label {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
  }

  &-calc {
    display: flex;
    flex-direction: column;

    label {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      span {
        display: inline-block;
        margin-left: 5px;
      }
    }
  }

  &-inputs {
    display: flex;
    justify-content: space-between;

    &-item {
      width: 45%;
      flex: 0 1 45%;

      &-column {
        flex-direction: column;
      }
    }

    &-adjust {
      display: flex;

      div:not(.form-group) {
        display: flex;
        align-items: center;
        margin: 0 12px 10px 0;
      }

      .form-group {
        margin-right: 0;
      }

      p {
        padding-bottom: 5px;
      }

      &-cost-option {
        label {
          flex: 0 1 45%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            margin-left: 5px;
          }
        }

        input {
          margin: 0 0 2px 0;
        }
      }

      > input {
        flex: 0 1 50%;
        width: 50%;
      }
    }
  }

  &-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    float: right;
  }
}
