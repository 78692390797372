.hc-personal-priority {
  &__card-w {
    display: flex;
    justify-content: space-between;
    margin: 16px 16px 0 16px;
  }
  &__card-selected {
    margin-top: 20px;
    width: 100%;
    border-bottom: 2px solid #0068ae;
  }
  &__content-w {
    padding: 0 24px;
    background-color: $c-white;
    min-height: 96px;
    border-top: 1px solid $c-gray-light;
    border-radius: 6px;
    display: flex;
    align-items: center;
  }
  &__profile-photo {
    margin-right: 12px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
  &__name-w {
    margin-right: 16px;
    margin-left: 10px;
    min-width: 200px;
    text-align: start;
  }
  &__name {
    color: $c-nero;
    font-size: 16px;
    font-weight: $font-w-bold;
    margin-bottom: 4px;
  }
  &__date {
    color: $c-gray;
    font-size: 12px;
  }
  &__comment-w {
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.3px;
    text-align: start;
  }
  &__comment-intro {
    color: $c-nero;
    font-weight: $font-w-bold;
    margin-bottom: 6px;
  }
  &__comment {
    color: $c-gray;
  }
}
