.impersonation-modal {
  &__content-w {
    text-align: center;
    padding: 24px;
  }
  &__last-login {
    font-size: 12px;
    color: $c-gray;
    margin-bottom: 16px;
    &--not-activated {
      color: $c-red-bright;
    }
  }
  &__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-pic {
      width: 65px;
      height: 65px;

      img {
        border-radius: 50%;
      }
    }

    &-name {
      margin: 8px 0;
      font-size: 18px;
      font-weight: $font-w-bold;
      color: $c-nero;
    }

    &:not(:first-child) {
      margin-left: 80px;
    }

    &-w {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
    }
  }
  &__divider {
    margin-top: 36px;
    margin-bottom: 16px;
    border-width: 0.5px;
    border-color: $c-gray-light;
  }
  &__text {
    font-size: 14px;
    color: $c-nero;
  }
  &__client-settings-w {
    margin-top: 15px;
    font-weight: $font-w-bold;
    font-size: 14px;
    color: $c-blue;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &__settings-icon {
    margin-right: 6px;
  }
}
