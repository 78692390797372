.assign-team__modal__body {
  display: flex;
  flex-direction: column;
  max-height: 375px;
  overflow: auto;

  &__row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 16px 0;
    border-bottom: 1px solid $c-gray-light;

    input[type='radio'] {
      position: relative;
      cursor: pointer;
      margin: 0;
      margin-top: -4px;
    }

    input[type='radio']:before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      border: 1px solid #b8b8b8;
      border-radius: 3px;
      background-color: white;
    }

    input[type='radio']:checked:after {
      content: '';
      display: block;
      width: 5px;
      height: 10px;
      border: solid $c-blue;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  &__row-radio {
    display: flex;
    align-items: center;
  }

  &__row--col {
    padding: 16px 8px;

    > *:first-child {
      display: flex;
      align-items: center;
      max-width: 390px;
      width: 100%;
      margin-right: 10px;
    }

    > *:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    h5 {
      margin-left: 15px;
      font-weight: $font-w-bold;
      min-width: 283px;
    }

    &-h {
      background-color: $c-white-smoke;
      color: $c-gray;
      font-size: 11px;
    }
  }

  &__team-photo {
    display: flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px dashed $c-gray-light;
    color: $c-gray-light;
  }

  &__teams-w {
    display: flex;
    align-items: center;
    position: relative;
    height: 34px;
  }

  &__teams {
    display: flex;
    align-items: center;
    position: absolute;
  }

  &__team-member-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white;
    color: $c-gray-light;
    background-color: white;
  }

  &__actionBtns {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    padding: 16px 24px 16px 0;
  }

  .no-border {
    border: none;
  }

  .more {
    background-color: #f4f4f4;
    color: $c-blue;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }
}
