.ivf-header {
  &__w {
    display: flex;
    flex-direction: row;
    padding: 15px 64px;
    background-color: $c-white;
    box-shadow: 0 1px 0 0 $c-gray-light;
    justify-content: space-between;
  }
  &__logo {
    height: 32px;
    width: 32px;
  }
  &__title-w {
    display: flex;
    flex-direction: row;
  }
  &__title {
    align-self: center;
    padding: 0 11px;
  }
  &__household-name {
    padding: 5px 16px;
    border-left: 2px solid $c-gray-lightest;
    align-self: center;
    font-weight: $font-w-bold;
  }
}
