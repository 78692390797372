.inner-container {
  display: flex;
  flex-direction: column;
  max-width: 1170px;
  min-width: $minsize-container;
  margin: 0 auto 30px;
  padding: 0 16px;
  width: 100%;

  &--wide {
    max-width: 1440px;
    padding: 0 44px;
  }
}
