.strategy-search {
  &__dropdown-filter {
    cursor: default;
    position: absolute;
    top: 43px;
    left: 0;
    border: 1px solid #2178c4;
    box-shadow: 0px 3px 0px #e4e5e7;
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    width: max-content;
    min-width: 210px;

    &-options {
      display: grid;
      gap: 0px 50px;
      grid-template-rows: repeat(6, 1fr);
      grid-auto-flow: column;
      align-items: center;
      padding-right: 30px;

      &--sm {
        grid-template-rows: repeat(3, 1fr);
      }
    }

    &-manager {
      display: grid;
      gap: 0px 50px;
      grid-auto-rows: minmax(min-content, max-content);
      grid-template-columns: repeat(2, 1fr);
      overflow: auto;
      max-height: 400px;
    }
  }
  @media (max-width: 1080px) {
    &__dropdown-filter {
      max-height: 600px;
      &-manager {
        grid-template-columns: 1fr;
      }
    }
  }
}
