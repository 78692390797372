.view-finder {
  &__exercise {
    display: flex;
    flex-direction: row;
    flex: 3;
    max-height: 240px;
    justify-content: space-evenly;

    &-w {
      height: 192px;
      width: 136px;
      border-radius: 6px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-bottom: 20px;
      justify-content: flex-end;
      text-align: center;
    }
  }

  &__graph {
    position: relative;
    margin: 0 auto;
    width: 72px;
    height: 108px;
    background-color: $c-white-smoke;
    border-radius: 4px;

    span {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;

      > svg {
        position: absolute;
        left: -32px;
        top: -12px;
      }
    }
  }

  &__divider {
    flex: 0.5;

    div {
      height: 116px;
      width: 1px;
      background-color: #e5e5e5;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__no-result {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      padding-top: 51px;
    }

    &-heading {
      color: #255eba;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      padding-top: 12px;
      text-align: center;
    }

    &-sub-heading {
      padding-top: 12px;
      color: #231f20;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      padding-bottom: 51px;
      max-width: 342px;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 25px 16px;
  }

  &__label {
    padding-top: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;

    p {
      padding-left: 12px;
      color: #231f20;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__preference-global {
    flex: 2;

    &-text {
      padding-bottom: 10px;
      color: #231f20;
      font-size: 14px;
      line-height: 13px;
      text-align: center;
    }

    &-map-w {
      text-align: center;
    }
  }
}
