.financial-planning {
  height: 100vh;
  position: relative;

  &__header {
    width: 100%;
    padding: 14px 60px;
    background-color: $c-white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 125px;
    }

    &-text {
      font-size: 16px;
      font-weight: bold;
    }

    &-title {
      display: flex;
      align-items: center;
    }

    &-divider {
      height: 24px;
      width: 2px;
      background-color: #e3e3e3;
      margin: 0 16px;
    }

    &-btns-w {
      a {
        margin-right: 15px;
      }
    }
  }

  &__tile-w {
    margin-top: 25px;
  }

  &__tile-content {
    display: flex;
    flex-direction: column;

    &--row {
      flex-direction: row;
      align-items: center;
      padding: 15px 88px 16px 16px;
    }
  }

  &__software {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 88px;
    width: 28%;

    &-label {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  &__advisor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 28%;

    &-text {
      font-size: 14px;
      margin-bottom: 5px;

      &--bold {
        font-weight: bold;
      }
    }
  }

  &__content-header {
    flex: 2;
    text-align: left;
    text-transform: uppercase;

    &--send {
      flex: 1;
      margin-right: 12px;
    }

    &--fname {
      flex: 3;
    }

    &--lname {
      flex: 3;
    }

    &--employment {
      flex: 3;
      margin-right: 20px;
    }

    &--birthdate {
      flex: 3;
    }

    &--acnumber {
      flex: 1;
      text-align: right;
      margin: 0 15px 0px 0px;
    }

    &--acnickname {
      text-align: left;
      flex: 1;
    }

    &--actype {
      padding-left: 20px;
      flex: 1;
    }

    &--owner {
      flex: 1.5;
      padding-left: 23px;
    }

    &--taxstatus {
      padding-left: 15px;
      flex: 1.5;
    }

    &--regtype {
      flex: 1;
      text-align: center;
      padding: 0 10px;
    }

    &--totalvalue {
      flex: 1;
      text-align: right;
    }

    &--cost {
      flex: 1;
    }
  }

  &__content-row {
    padding: 17px;
    display: flex;
    font-size: 13px;
    align-items: center;
    border-bottom: 1px solid $c-gray-light;

    &__item {
      flex: 2;
      text-align: left;

      &--send {
        text-align: center;
      }

      &--fname {
        flex: 3;
      }

      &--lname {
        flex: 3;
      }

      &--employment {
        flex: 3;
        margin-right: 20px;
      }

      &--birthdate {
        flex: 3;
      }

      &--acnumber {
        text-align: right;
        flex: 2;
        margin: 0 15px 0 0;
      }

      &--acnickname {
        text-align: left;
        flex: 2.5;
      }

      &--owner {
        flex: 3;
        margin-right: 15px;
      }

      &--regtype {
        text-align: center;
        padding: 0 10px;
      }

      &--totalvalue {
        flex: 2.3;
        text-align: right;
      }

      &--classification {
        flex: 3.5;
      }

      &--cost {
        text-align: center;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    margin: 0;
    margin-top: -4px;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 1px solid #b8b8b8;
    border-radius: 3px;
    background-color: white;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid $c-blue;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }

  &__progress-bar-w {
    display: flex;
  }

  &__progress-bar {
    padding: 2.5px 0;
    margin: 0;

    &--green {
      border-radius: 0 2.5px 2.5px 0;
      background-color: $c-green-light;
    }
  }

  &__allocation-warning {
    margin: 25px 0 0 0;
    font-size: 14px;
    line-height: 16px;
    p:first-child {
      padding: 5px 8px;
      border: 1px solid $c-red-bright;
      border-radius: 3px;
      display: inline-flex;
      margin-bottom: 16px;
      align-items: center;
      background-color: rgba(241, 41, 56, 0.1);
      color: $c-red-bright;

      img {
        margin-right: 8px;
      }
    }
  }

  &__changes-warning {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #8a8888;
    border-radius: 3px;
    background-color: rgba(138, 136, 136, 0.1);
    margin-top: 25px;
    font-size: 14px;
    line-height: 16px;

    img {
      cursor: pointer;
    }
  }

  &__missinginfo-warning-w {
    position: absolute;
    padding: 16px;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
    display: inline-flex;
    background-color: $c-white;
    cursor: pointer;
    &--open {
      padding: 16px;
      margin-left: 32px;
      cursor: default;
    }

    span {
      cursor: pointer;
    }
  }

  &__missinginfo-icon-w {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__missinginfo-icon {
    width: 48px;
    background-color: rgba(244, 149, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__missinginfo-body {
    padding: 5px 0px 7px 16px;
    width: 280px;
  }

  &__missinginfo-text {
    p {
      display: inline-block;
      font-size: 14px;

      &:first-child {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $c-orange;
      }
    }
  }

  &__missinginfo-btns-w {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-top: 23px;
    color: $c-blue;
    text-decoration: underline;
    span {
      margin-left: 24px;
      cursor: pointer;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin-top: 24px;

    li {
      margin-right: 4px;
      cursor: pointer;
      // padding: 5px 8px;
      height: 24px;
      width: 24px;
      border: 1px solid #d2d2d2;
      border-radius: 2px;
      background-color: #ffffff;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border-color: $c-blue-light;
      }
    }
    span {
      margin: 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 1px solid transparent;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        border: 1px solid $c-blue-light;
        border-radius: 2px;
      }
    }
  }
}
