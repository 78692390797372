.dot-oval {
  &:last-child {
    padding-bottom: 0px;
  }
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &__keys {
    display: flex;
    color: #231f20;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
  }
  &__circle {
    height: 12px;
    width: 12px;
    margin-right: 4px;
    border-radius: 50%;
  }
  &__values {
    color: #231f20;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    text-align: right;
    margin-left: auto;
    padding-left: 10px;
  }
}
