.record-form {
  padding: 24px;
  &__title-w {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  &__title {
    min-width: 224px;
    color: #231f20;
    font-size: 20px;
    font-weight: $font-w-bold;
  }
  &__name-w {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--preview {
      justify-content: start;
    }
  }
  &__name {
    width: 48%;
    font-weight: $font-w-bold;
    &--preview {
      width: 115px;
      text-align: center;
    }
  }
  &__inner-w {
    width: 100%;
  }
  &__details-w {
    width: 100%;
    display: flex;
    border-top: 1px solid #d2d2d2;
    padding-top: 16px;
    margin-top: 16px;
    &:first-child {
      padding-top: 0;
      border-top: none;
    }
  }
  &__card-col {
    min-width: 224px;
  }
  &__score-graph {
    height: 120px;
    display: flex;
    margin-right: 12px;
  }
  &__score-action-col {
    width: 100%;
    &--fade {
      opacity: 0.5;
    }
  }
  &__score-w {
    display: flex;
    justify-content: space-between;
  }
  &__score {
    width: 48%;
    &--single-contact {
      width: 100%;
    }
  }

  &__button-w {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    text-align: right;
  }
  &__add-action-button-w {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    height: 32px;
    background-color: #f2f2f2;
  }
  &__plus-icon {
    margin: 0 6px;
  }
  &__add-action-button-text {
    font-size: 14px;
    color: #255eba;
    font-weight: $font-w-bold;
  }
  &__edit-btn-w {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
  }
  &__edit-icon {
    width: 20px;
  }
  &__edit-text {
    color: #255eba;
    font-size: 14px;
    font-weight: $font-w-bold;
    margin: 0;
    margin-left: 6px;
  }
  &__action-null {
    color: #231f20;
    font-size: 14px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
