@import 'guidebookactions';
@import 'gbNavigation';
@import 'gbCoverPage';
@import 'gbItem';

.gb-container {
  width: 100%;
  min-width: 600px;
  height: calc(100vh - 84px);

  &__main {
    display: flex;
    height: calc(100% - 55px);
    padding-right: 408px;
    margin-top: 55px;
  }

  &__nav {
    position: fixed;
    right: 0;
    top: 138px;
    bottom: 0;
    background-color: white;
    max-width: 408px;
    width: 100%;
    box-shadow: 0px 5px 5px 0px #cecece;
    overflow: auto;
    border-left: 1px solid #d2d2d2;
  }

  &__pdfPreview {
    width: 100%;
    max-width: 868px;
    margin: 0 auto;
    margin-top: 24px;
    padding: 0 24px;

    canvas {
      width: 100%;
      box-shadow: 0px 0px 5px 1px #989898;
    }
  }

  &__next-prev-w {
    margin: 20px auto 40px;
    display: flex;
    justify-content: flex-end;
  }
}
