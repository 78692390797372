.funded-score-recent {
  &__comment {
    padding-top: 20px;
    margin: 30px 15px 20px 15px;
    border-top: solid 1px $c-gray-light;

    &-content {
      overflow-wrap: normal;
      font-size: 14px;
      line-height: 16px;
      margin: 10px auto;
      color: $c-nero;

      pre {
        white-space: pre-wrap;
        width: inherit;
      }
    }
  }

  &__edit-img {
    padding-left: 10px;
    img {
      vertical-align: bottom;
      max-width: 24px;
      cursor: pointer;
    }
  }
}
