.transfer-list {
  &__group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Header
  &__group-header {
    font-size: 24px;
    line-height: 30px;
  }

  // Footer
  &__footer {
    border-top: 1px solid #d2d2d2;
    padding: 36px 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 6px 6px;
    height: 50px;
    align-self: stretch;
  }

  &__footer-left {
    align-items: center;
    order: 1;
  }

  &__footer-right {
    align-items: center;
    order: 2;
  }

  &__items-selected--count {
    color: #255eba;
    font-weight: 800;
  }

  // Lists
  &__origin-list,
  &__destination-list {
    flex: 2;
  }

  &__search-wrapper {
    background: #f5f5f5;
    padding: 14px;
    border-bottom: 1px solid #d2d2d2;

    // remove this style when filter menu is added to UI
    .search__w {
      width: 100%;
    }
  }

  // List Items
  &__list-items-wrapper {
    height: 500px;
    overflow: auto;
  }

  &__list-item {
    padding: 21px 14px;
    border-bottom: 1px solid #d2d2d2;
    background: #ffffff;
  }

  // Buttons
  &__transfer-btn-group {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__transfer-btn {
    display: table;
    width: 182px;
    padding: 5px;
    margin: 7px;
    cursor: pointer;
  }

  &__transfer-btn-icon--add-transfer {
    fill: white;
    stroke: white;
    stroke-width: 2;
    transform: rotate(90deg);
  }

  &__transfer-btn-icon--remove-transfer {
    fill: white;
    stroke: white;
    stroke-width: 2;
    transform: rotate(-90deg);
  }

  &__transfer-btn--end-spacer {
    display: table-cell;
    width: 25%;
    text-align: center;
  }

  &__transfer-btn--middle-spacer {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
    text-align: center;
  }

  &-row__office {
    text-align: end;
    font-size: 14px;
  }
}
