.merge-accounts {
  &__modal-body {
    height: 400px;
    overflow-y: scroll;
  }
  &__selected_account {
    color: #231f20;
    font-size: 14px;

    &--blue {
      color: #255eba;
      margin: 0;
    }
  }

  &__header-row {
    color: #676879;
    font-size: 12px;
  }
  &-row {
    padding: 10px 0;
    color: #231f20;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 15px;
    align-items: center;

    &__name {
      width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__account-number {
      color: #646464;
    }
    &__source {
      width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      justify-content: flex-end;
      margin-left: 5px;
    }
    &__account-type {
      width: 140px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      justify-content: flex-end;
      margin-left: 8px;
    }

    &__amount {
      width: 105px;
      overflow: hidden;
      margin-left: 5px;
      display: flex;
      justify-content: flex-end;
    }

    &__check {
      .checkbox__check {
        width: 17px;
        height: 17px;
        border-radius: 2px;
      }
    }
  }
}
