.score-preview {
  &__header-w {
    height: 32px;
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
  }
  &__left-header {
    display: flex;
    justify-content: center;
  }
  &__title {
    display: flex;
    align-items: center;
    color: #231f20;
    font-size: 14px;
    font-weight: $font-w-bold;
    margin-right: 16px;
  }
  &__active-tag {
    height: 16px;
    width: 48px;
    border-radius: 4px;
    background-color: #8ccc33;
    color: #ffffff;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
