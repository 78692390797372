.funded-score-modal {
  &__body {
    display: flex;
    flex-direction: column;
    padding: 22px 24px;
  }

  &__input {
    padding: 10px;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    margin-top: 6px;
    font-size: 14px;
    line-height: 16px;
  }

  &__text {
    color: $c-nero;
    font-size: 14px;
    line-height: 14px;
  }

  &__btns-w {
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px;
    margin-top: 30px;
  }
}
