@import './sideBarBody';
@import './filterModal';
@import './filterToggleRow';
@import './goalsNullView';
@import './goasLoading';

.goals {
  &__w {
    display: flex;
    flex-direction: row;
  }

  &__body {
    width: 100%;
  }
}
