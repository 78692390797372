.combine {
  &__modal {
    &-body {
      color: $c-nero;
      font-size: 14px;
      line-height: 18px;
      padding-bottom: 22px;
    }

    &-text {
      padding: 16px;
      font-size: 14px;
      text-align: left;
      color: $c-nero;
    }
  }

  &__table {
    &-wrapper {
      max-height: 380px;
      overflow-y: scroll;
      border-bottom: 1px solid #d2d2d2;
    }

    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #8a8888;

      &-section {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &:nth-child(1) {
          width: 45%;
          margin-left: 25px;
          text-align: left;
        }

        &:nth-child(2) {
          width: 27%;
          text-align: center;
        }

        &:nth-child(3) {
          width: 23%;
          text-align: right;
        }
      }
    }

    &-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 60px;

      &:not(:last-child) {
        border-bottom: 1px solid #d2d2d2;
      }

      &-section {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 10px;

        &:nth-child(1) {
          width: 5%;
          padding: 0 0 0 10px;
        }

        &:nth-child(2) {
          width: 44%;
          text-align: left;
        }

        &:nth-child(3) {
          width: 27%;
          text-align: center;
        }

        &:nth-child(4) {
          width: 23%;
          text-align: right;
        }
      }

      &-account {
        &--name {
          color: $c-nero;
          font-size: 16px;
          line-height: 18px;
        }

        &--number {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #8a8888;
        }

        &--amount {
          color: $c-nero;
          font-size: 16px;
          line-height: 18px;
        }

        &--type {
          span {
            padding: 1px 12px;
            background-color: $c-blue-lightest;
            color: $c-nero;
            font-size: 10px;
            line-height: 12px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
