.task-modal {
  padding: 25px;
  color: $c-nero;

  &__select {
    label {
      display: inline-table;
      align-items: center;
      color: $c-nero;

      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }

  &__input {
    width: 100%;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px;

    &--date-picker {
      input {
        border: none;
      }
    }
  }

  &__checkbox-w {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;

    &-label {
      margin-left: 3px;
    }
  }

  &__input-w {
    margin-top: 10px;

    label {
      display: flex;
      align-content: center;
      margin-bottom: 6px;
      font-size: 14px;

      input {
        margin-right: 10px;
      }
    }

    &--split {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      > div {
        width: 50%;

        &:not(:first-of-type) {
          margin-left: 16px;
          margin-bottom: 17px;
        }
      }
    }

    p {
      margin-bottom: 3px;
    }

    textarea {
      width: 100%;
      height: 100px;
      border: 1px solid $c-gray-light;
      border-radius: 4px;
      font-size: 14px;
      padding: 10px;
      font-family: $font-main;
    }
  }

  &__input-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px;

    svg {
      margin: 0 10px;
      color: $c-gray;
    }
  }

  .react-datepicker-wrapper {
    input {
      border: 0;
      font-size: 12px;
    }
  }

  &__btns-w {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  .css-1aya2g8 {
    background-color: white;
  }
}
