.hc-scoring {
  &__slider {
    flex: 1;
    max-height: 6px;
    border-radius: 3px;
    background-color: $c-gray-light;
    outline: none;
    -webkit-appearance: none;
    width: 100%;
    &:focus {
      outline: none;
    }

    &::-moz-focus-outer {
      border: 0;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 32px;
      height: 32px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid $c-gray-light;
      border-radius: 16px;
      background-color: #ffffff;
      cursor: pointer;

      &::before {
        content: '1';
        color: black;
      }
    }

    &::-ms-track {
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      width: 32px;
      height: 32px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid $c-gray-light;
      border-radius: 16px;
      background-color: #ffffff;
      cursor: pointer;

      &::before {
        content: '1';
        color: black;
      }
    }

    &::-ms-thumb {
      -webkit-appearance: none;
      width: 32px;
      height: 32px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid $c-gray-light;
      border-radius: 16px;
      background-color: #ffffff;
      cursor: pointer;

      &::before {
        content: '1';
        color: black;
      }
    }

    &-value {
      position: relative;
      pointer-events: none;
    }

    &-value-w {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-w {
      display: flex;
      align-items: center;
    }
  }

  &__comment {
    height: 60px;
    resize: none;
    border-radius: 4px;
    border: 1px solid $c-gray-light;
    font-family: $font-main;
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
      padding: 3px;
    }

    &-label {
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 14px;
    }

    &-w {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }
  }

  &__row {
    flex: 1;
    margin: 0 24px;
    display: flex;
    flex-direction: column;
  }
}
