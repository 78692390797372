.strategy-search__sort {
  color: #22263f;
  background: #e6ecf3; // gs color
  align-items: center;
  padding: 10px 100px 10px 25px;
  font-size: 14px;
  line-height: 20px;
  &-spacer {
    padding: 10px 50px 10px 25px;
  }

  &-help {
    vertical-align: middle;
    padding-left: 2px;
  }

  &-item {
    cursor: pointer;
    &:not(:first-child) {
      text-align: right;
    }
  }
  &-arrow {
    vertical-align: middle;
  }
}
