.household-text-display {
  &-w {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    padding: 8px;
    color: $c-nero;
    background-color: transparent;
    transition: background-color 300ms;
    margin-left: 11px;
    &:hover {
      background-color: $c-gray-lightest;
      display: inline-flex;
      border-radius: 4px;
      color: $c-active-btn;
    }
  }
}
