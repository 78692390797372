.file-modal {
  &__content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    color: $c-titles;

    &--input {
      font-size: 14px;
      line-height: 16px;
      padding: 10px;
      margin-top: 6px;
      border-radius: 6px;
      border: 1px solid $c-borders;

      ::placeholder {
        color: $c-icons;
      }
    }
  }

  &__btn-w {
    display: flex;
    justify-content: center;
  }

  &__filename {
    margin-bottom: 15px;
    font-size: 14px;
    color: $c-nero;
    line-height: 18px;

    &--format {
      display: flex;
      background: rgba(241, 41, 56, 0.1);
      border: 1px solid #f12938;
      border-radius: 3px;
      padding: 9px;
      color: #f12938;

      span {
        display: block;
        margin-right: 10px;
      }

      &-img {
        margin-right: 10px;
        display: block;
      }

      &-pending {
        background: #f2f5fa;
        border-color: #d2d2d2;
        color: #231f20;
      }
    }
  }

  &__restrictions {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
    color: $c-manatee;
  }

  &__dropzone {
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 43px 0 43px 0;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: $font-w-bold;
  }

  &__divider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &--line {
      border-bottom: 1px solid #d2d2d2;
      width: 120px;
    }

    &--text {
      padding: 16px;
      color: $c-gray;
    }
  }

  &__buttons {
    margin-top: 40px;
    align-self: flex-end;
    display: flex;
  }
}
