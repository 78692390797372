.mimeo-productinfo {
  &__input {
    max-width: 55px;
  }
}

.mimeo-shipping {
  &__title {
    display: flex;
    align-items: center;
    margin-right: 40px;

    &-w {
      justify-content: space-between;
    }
  }

  &__input {
    display: inline-flex;
    flex-direction: column;

    &--locked {
      background-color: #f4f4f4;
      font-size: 14px;
      min-height: 36px;
      border: 1px solid #c2c2c2;
      border-radius: 4px;
      padding: 8px;
      width: 55px;
    }

    &-w {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 14px;
      margin-bottom: 3px;
    }
  }

  &__quantity-update {
    cursor: pointer;
    color: $c-blue;
    font-size: 14px;
    margin-top: 13px;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__details {
    &:not(:last-of-type) {
      margin-right: 20px;
    }

    span {
      display: block;
      margin-bottom: 8px;
      font-size: 11px;
      color: #8a8888;
    }
    div {
      font-size: 14px;
    }
  }

  &__info-details {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    span {
      display: block;
      color: #8a8888;
      font-size: 11px;
      margin-bottom: 6px;
    }

    div {
      font-size: 14px;
    }
  }

  &__shipping-w {
    width: 100%;

    > span {
      display: block;
      font-size: 14px;
      margin-bottom: 6px;
    }
  }
}
