.im-upload {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__header {
    margin-top: 5px;
    font-size: 24px;
  }

  &__message {
    margin: 24px 0px 24px 0px;
    line-height: 26px;
    span {
      cursor: pointer;
    }
  }
}
