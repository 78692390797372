.client-score-history {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__graph {
    width: 100%;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &__chart-name-label {
    display: flex;
    width: 50%;
    flex: 1;
    justify-content: flex-end;
  }

  &__primary-line {
    border-top: 2px solid #989dc2;
  }

  &__secondary-line {
    border-top: 2px solid #cabeb3;
  }

  &__table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 372px;
    margin-top: 25px;

    &--couple {
      width: 49%;
    }
  }

  &__header-left {
    font-size: 16px;
    font-weight: 500;
  }

  &__table-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 13px 16px;
    font-size: 11px;
    font-weight: $font-w-bold;
    background-color: #f5f5f5;
    color: $c-gray;
    border-bottom: 1px solid #d2d2d2;
    width: 100%;

    &-score {
      min-width: 253px;
    }

    &-created {
      width: 100%;
      max-width: 165px;
    }
  }

  &__state {
    margin-left: 15px;
  }

  &__actions {
    margin-left: auto;
  }

  &__column-one {
    display: flex;
    align-items: center;
    width: 245px;
  }

  &__column-two {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 170px;
    margin-left: 15px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    width: 100%;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: solid 1px $c-gray-light;
    }
  }

  &__average {
    color: white;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    line-height: 25px;
    vertical-align: middle;
    text-align: center;
    margin: 0px 5px;
    font-size: 14px;
    &-empty {
      background-color: $c-gray-light;
    }
    &-primary {
      background-color: $c-green-lighter;
    }
    &-secondary {
      background-color: $c-yellow;
    }
  }

  &__meter {
    display: flex;
    align-items: center;
    min-width: 210px;

    svg {
      width: 152px;
    }
  }

  &__date {
    color: $c-nero;
    font-size: 16px;
    font-weight: 500;
    &-null {
      color: $c-gray-light;
    }
  }
}
