.ivf-attributes {
  border: 1px solid $c-gray-light;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  max-width: 210px;
  height: 280px;
  visibility: visible;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(35, 31, 32, 0.18);
  }
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 880px;
    margin: 40px auto;
  }
  &-w {
    display: flex;
    flex-direction: row;
    visibility: hidden;
    &:hover > .ivf-attributes-line-w:not(:hover) {
      opacity: 0.5;
    }
  }
  &-line {
    height: 40px;
    width: 1px;
    background-color: $c-gray-light;
    margin: 0 auto;
    visibility: hidden;
    &-w {
      visibility: hidden;
      &:not(:last-child) {
        margin: 0 16px 0 0;
      }
    }
  }
  &__bar {
    height: 72px;
    width: 96px;
    border-radius: 0 0 4px 4px;
    background-color: $c-blue;
    margin: 16px 0;
  }
  &__title {
    line-height: 18px;
    font-size: 16px;
    text-align: center;
    color: $c-nero;
    margin-bottom: 4px;
  }
  &__description {
    line-height: 22px;
    font-size: 14px;
    text-align: center;
    color: $c-gray;
  }
  &__pros-cons {
    flex: 1;
    display: flex;
    flex-direction: column;
    &-w {
      min-height: 220px;
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 32px 40px;
      border: 1px solid $c-gray-light;
      border-radius: 6px;
      justify-content: center;
    }
    &-divider {
      width: 1px;
      height: 52px;
      background-color: $c-gray-light;
      margin: 0 40px;
      align-self: center;
    }
    &-title {
      color: $c-blue;
      font-size: 18px;
      font-weight: $font-w-bold;
      line-height: 23px;
      margin-bottom: 4px;
    }
    &-description {
      list-style-type: disc;
      margin: 4px 0;
      font-size: 15px;
      line-height: 22px;
      color: $c-nero;
    }
  }
}
