.pal-notes-modal {
  p {
    margin: 10px auto;
    color: $c-nero;
  }

  &__content {
    padding: 24px;

    textarea {
      width: 100%;
      max-width: 100%;
      height: 95px;
      font-size: 14px;
      color: $c-nero;
      border-radius: 6px;
      border: 1px solid #d2d2d2;
      padding: 5px;
      font-family: $font-main;
    }
  }

  &__note-templates {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    position: absolute;
    top: 50px;
    left: 0;
    width: 320px;
    background-color: white;
    border: 1px solid #d2d2d2;
    box-shadow: -1px 1px 0 0 rgba(210, 210, 210, 0.5);
    border-radius: 6px;

    ul {
      display: block;
      max-height: 180px;
      height: 180px;
      overflow: auto;
    }

    li {
      padding: 12px;
      color: $c-nero;
      border-bottom: 1px solid #d2d2d2;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      top: -19px;
      left: 11px;
      border-color: transparent transparent white transparent;
      border-width: 10px;
    }

    &:before {
      top: -22px;
      left: 10px;
      border-color: transparent transparent #d2d2d2 transparent;
      border-width: 11px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px 24px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn.btn__action:not(:first-of-type) {
        margin-left: 5px;
      }
    }
  }
}
