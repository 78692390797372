.ivf-target-allocation {
  &-w {
    display: flex;
    margin: 40px 20px;
    flex-wrap: wrap;
    overflow: auto;
  }

  &__disclosure {
    &-w {
      padding: 24px;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
    }

    &-callout {
      font-weight: 500;
      padding: 14px 0 0;
    }

    &-definitions {
      p {
        padding: 14px 0 0;
      }
      hr {
        width: 50%;
        margin: 14px 0 7px;
      }
      font-weight: 500;
    }

    padding: 14px 0;
  }
}
