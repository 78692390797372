.ivf-checkmark {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $c-gray-light;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f5f5f5;
  img {
    width: 16px;
    display: none;
  }
  &--checked {
    background-color: $c-green;
    border: 1px solid $c-green;
    img {
      display: flex;
    }
  }
}
