.ivf-priorities {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 980px;
    margin: 40px auto;
  }

  &__icon {
    height: 20px;
    width: 20px;
    margin: 0 8px;
  }

  &__graph-w {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }

  &__priority-result-w {
    &--active {
      border: 1px solid $c-blue;
      border-radius: 6px;
    }

    &--opaque {
      opacity: 0.3;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    width: 100%;
    padding: 24px 56px 10px;
  }

  &__priority {
    &-w {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 160px;
      width: 100%;
    }

    margin: 16px 0;
    display: flex;
    align-items: center;
  }

  &__description {
    &-divider {
      height: 1px;
      width: 30px;
      background-color: $c-gray-light;
    }

    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin-top: 8px;
  }

  &__middle-header {
    display: flex;
    flex-direction: row;

    &-text {
      flex: 1;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      text-align: center;
    }
  }

  &__instructions {
    max-width: 600px;
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    color: $c-nero;
  }

  &__question-w {
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__bar {
    &-w {
      height: 144px;
      width: 96px;
      background-color: #dfe0e330;
      display: flex;
      align-items: flex-end;
      border-radius: 4px;
      overflow: hidden;
    }

    transition: height 0.2s ease-out;
    width: 100%;
  }

  &__slider {
    &::-moz-focus-outer {
      border: 0;
    }

    &-hack {
      position: relative;
      margin-top: 164px;

      &:before,
      &:after {
        content: '';
        height: 16px;
        background-color: $c-white;
        border-radius: -2px;
        position: absolute;
        top: 0;
        z-index: 400;
        width: 12px;
      }

      &:before {
        left: -10px;
      }

      &:after {
        right: -12px;
      }
    }

    &-w {
      display: flex;
      max-width: 648px;
      width: 100%;
      flex-direction: column;
      margin: 0 40px;
    }

    margin: 0;
    appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 2px;
    outline: none;

    &::-webkit-slider-thumb {
      z-index: 900;
      cursor: pointer;
      position: relative;
      appearance: none;
      margin-top: 5px;
      width: 27px;
      height: 40px;
      background-image: url('../../../images/icons/indicator.svg');
      background-repeat: no-repeat;
    }

    &::-moz-range-thumb {
      cursor: pointer;
      margin-top: 5px;
      width: 26.5px;
      height: 40px;
      background: transparent;
      border: transparent;
      background-image: url('../../../images/icons/indicator.svg');
      background-repeat: no-repeat;
      box-sizing: border-box;
    }

    &-label {
      position: absolute;
      font-size: 14px;
      line-height: 16px;
      color: black;
      top: 25px;

      &-w {
        display: flex;
        flex-direction: row;
        position: relative;
      }

      &--center {
        &::before {
          content: '';
          position: absolute;
          background-color: $c-gray-light;
          width: 2px;
          height: 15px;
          top: -20px;
          right: 50%;
          transform: translate(50%);
        }
      }

      &--left {
        &::before {
          content: '';
          position: absolute;
          background-color: $c-gray-light;
          width: 2px;
          height: 15px;
          top: -20px;
        }
      }

      &--right {
        &::before {
          content: '';
          position: absolute;
          background-color: $c-gray-light;
          width: 2px;
          height: 15px;
          top: -20px;
          right: 0;
        }
      }
    }
  }

  &__highlight {
    font-size: 15px;
    color: $c-blue;
    font-weight: 700;

    &-w {
      display: flex;
      padding: 24px 0;
      border: 1px solid #d2d2d2;
      width: 100%;
      max-width: 856px;
      justify-content: center;
      border-radius: 6px;
      margin: 0 40px 40px;
      font-size: 15px;
      line-height: 22px;
    }
  }

  &-line {
    height: 40px;
    width: 1px;
    background-color: #d2d2d2;
    margin: 0 auto;
  }
}
