.filter-toggle-row {
  display: flex;
  align-items: center;

  &__w {
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
  }

  &__row {
    &-action-type {
      justify-content: flex-start;
    }

    &-icon {
      justify-content: center;
      width: 20px;
      margin-right: 5px;
    }
  }

  &__label {
    &-action {
      font-weight: bold;
    }
  }
}
