.moneymind-modal {
  p {
    margin: 10px auto;
    color: $c-nero;
  }

  &__content {
    padding: 24px 18px;
  }

  &__account-border {
    padding-bottom: 10px;
    border-bottom: 1px solid $c-gray-light;
  }

  &__email {
    &-box {
      height: 350px;
      border: 1px solid $c-gray-light;
      margin-top: 10px;
      overflow: scroll;
    }

    &-w {
      font-size: 11px;
      line-height: 14px;
      padding: 0 30px;
      color: #696969;
    }

    &-w p {
      margin-top: 10px;
      color: #696969;
    }

    &-w p.name-label {
      margin-top: 20px;
    }

    &-w span {
      font-weight: 500;
      color: #696969;
    }

    &-w a {
      color: #15c;
      text-decoration: underline;
    }

    &-image {
      max-width: 250px;
    }

    &-header {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__row {
    color: $c-nero;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    line-height: 18px;
    padding: 2px 0;

    &-label {
      width: 100px;
    }

    &-value {
      font-weight: $font-w-bold;
    }
    &-error {
      font-weight: $font-w-bold;
      color: $c-red-bright;
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;

    &-w {
      margin: 0 5px;

      .btn {
        padding: 9px;
      }
    }

    &-text {
      font-size: 14px;
      font-weight: $font-w-bold;
      line-height: 17px;
      margin-left: 5px;
      min-width: 250px;
    }
  }

  &__copy {
    position: relative;
    top: -7px;
    left: 5px;
    font-weight: $font-w-bold;
    font-size: 14px;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-right: 25px;
    width: 100%;
    color: $c-blue;
  }

  &__account-type {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    &-h {
      color: $c-nero;
      font-size: 20px;
      line-height: 1.3;
      margin-top: 5px;
    }

    label {
      color: $c-nero;
      font-size: 14px;
      margin-bottom: 3px;
      line-height: 1;
    }

    select {
      display: block;
      padding: 10px;
      border: 1px solid $c-gray-light;
      height: 36px;
      font-size: 14px;
    }
  }
}
