.meetingmode-modal {
  &__w {
    padding: 20px;
    line-height: 1.3;
    color: $c-nero;
  }

  &__btn-w {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}
