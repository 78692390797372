.launch-exercise {
  &__pp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 15px;
    border-bottom: 1px solid $c-gray-light;

    &-w {
      max-height: 305px;
      overflow: auto;
    }

    &-label {
      color: $c-nero;
      font-size: 14px;
      font-weight: $font-w-bold;
      flex: 1;
      word-break: break-all;
    }

    &-graph {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 100%;
      flex: 3;

      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 50%;
          transform: translateY(50%);
          height: 16px;
          width: 1px;
          background-color: $c-nero;
          opacity: 0.3;
        }
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &-w {
        max-width: 230px;
        width: 100%;
        line-height: 0;
        margin: 0 15px;
      }

      &--equity {
        background-color: $c-blue;
      }

      &--fixed {
        background-color: $c-yellow;
      }

      &--cash {
        background-color: #ffde8c;
      }

      &--alt {
        background-color: #a2d134;
      }
    }

    &-date {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      color: $c-nero;
      font-size: 14px;
      font-weight: $font-w-bold;
      flex: 1;

      > svg {
        margin-right: 10px;
        color: $c-gray-light;
      }
    }
  }

  &__or-text {
    position: relative;
    display: inline-block;
    margin: 24px 0;
    width: 100%;

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      transform: translateY(-50%);
      bottom: 50%;
      left: 0;
      width: 40%;
      height: 1px;
      background-color: $c-gray-light;
    }

    &:before {
      content: '';
      left: auto;
      right: 0;
    }
  }

  &__input {
    &-w {
      max-width: 286px;
      margin: 0 auto;
      margin-top: 20px;
      text-align: center;
    }
  }
}

.launch-input {
  width: 100%;
  border: 1px solid #c2c2c2;
  margin-top: 15px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 9px 10px;
  color: #231f20;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  display: inline-block;
}
