.flex {
  display: flex;
  flex: 1;
}

.no-strategy {
  &__w {
    padding: 60px 38px;
    text-align: center;
  }

  &__img {
    padding-bottom: 12px;
  }

  &__text {
    p:first-child {
      color: $c-blue;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      padding-bottom: 12px;
    }

    p:nth-child(2) {
      opacity: 0.5;
      color: #231f20;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.client-strategies {
  &__scroll {
    height: calc(100vh - 265px);
    overflow-y: scroll;
  }

  &__ratio-wrapper {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-right: 10px;
  }

  &__filter {
    .client-strategies__sort-btns {
      justify-content: flex-end;

      &-cancel {
        margin-right: 30px;
      }
    }

    padding: 10px 16px;
    color: #231f20;
    font-size: 14px;
    line-height: 16px;

    input {
      border: 1px solid #c2c2c2;
      height: 24px;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 3px 4px 5px 4px;
    }

    &-allocation {
      display: flex;
      justify-content: space-between;
      padding-top: 4px;

      input {
        width: 50px;
      }

      &-wrapper {
        display: flex;

        div {
          margin-right: 10px;
          width: 50px;
          color: #231f20;
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
        }

        .divider {
          transform: rotate(-160deg);
          background-color: #cccccc;
          height: 26px;
          width: 1px;
        }
      }
    }

    &-minimum {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;

      input {
        width: 96px;
      }
    }

    &-yield {
      display: flex;
      justify-content: space-between;
      padding-top: 4px;

      input {
        width: 64px;
      }
    }

    &-domestic {
      .radio-group {
        display: flex;
        justify-content: flex-start;
        width: 60%;
      }
    }

    &-domestic-w {
      margin-top: 4px;
    }

    &-input-wrapper {
      display: flex;
      justify-content: space-between;
      color: #231f20;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
    }

    &-heading {
      color: #231f20;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      padding: 5px 0;
    }

    &-check-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 34px;

      &-row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 17px;

        label {
          margin-left: 3px;
        }

        &-half {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 50%;
        }
      }
    }
  }

  &__search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search__w {
      height: 30px;
      width: 90%;
    }
  }

  &__sort {
    padding: 12px 0px;

    &--selected {
      color: $c-blue;
    }

    &-link {
      color: #231f20;
      font-size: 14px;
      line-height: 16px;
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;

      label {
        cursor: pointer;
      }
    }

    &-btns {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 7px;
    }
  }

  &__header {
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-right: 7px;
    }

    span {
      color: #231f20;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    a {
      cursor: pointer;
    }
  }

  &__reset {
    color: $c-nero;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }

  &__favorite {
    cursor: pointer;
    border-bottom: 1px solid #d2d2d2;
    padding: 25px 15px 25px 30px;

    &-image {
      padding-right: 10px;
      width: 7%;

      &-no {
        width: 14px;
      }
    }

    &-main {
      display: flex;
    }

    &-wrapper {
      display: flex;
    }

    &-heading {
      &-w {
        width: 93%;
      }

      img {
        padding-right: 8px;
      }

      span {
        color: #231f20;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      padding-top: 8px;

      &-balance {
        color: #231f20;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        text-align: right;
        padding-right: 16px;
      }

      &-ratio {
        color: #231f20;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        border-radius: 4px;
        background-color: #f2f5fa;
        padding: 1px 11px 1px 11px;
        margin-right: 8px;
        display: flex;
        align-items: center;
      }

      &-ratio:last-child {
        margin-right: 5px;
      }

      &-star {
        align-self: center;
      }
    }
  }

  .accordion {
    border: rgb(255, 255, 255);

    &__item {
      border-radius: 0;
      width: 100%;
    }
  }

  width: 100%;
}

.rc-slider {
  &__w {
    width: 95%;
    margin: auto;
  }
}

.rc-slider-handle {
  border: 2px solid rgb(37, 94, 186);
  background-color: rgb(255, 255, 255);
  width: 14px;
  height: 14px;
  margin-top: -6px;

  &:hover {
    background-color: rgb(255, 255, 255);
    box-shadow: unset;
  }

  &:active {
    background-color: rgb(255, 255, 255);
    box-shadow: unset;
  }

  &:focus {
    background-color: rgb(255, 255, 255);
    box-shadow: unset;
  }
}

.rc-slider-rail {
  height: 2px;
  background-color: #d2d2d2;
}

.rc-slider-track {
  background-color: rgb(37, 94, 186);
  height: 2px;
}
