.asset-null {
  &-w {
    position: relative;
    width: 150px;
    height: 200px;
    border-right: lightgray solid 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    position: absolute;
    bottom: 12px;
    color: $c-gray;
    font-size: 11px;
    text-align: center;
  }
}
