.im-account {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__header {
    margin-top: 5px;
    margin-right: 90px;
    font-size: 24px;
  }

  &__message {
    margin: 24px 0px 24px 0px;
    line-height: 26px;
    font-size: 16px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    &-start {
      justify-content: flex-start;
    }
  }

  &__modal {
    &-title {
      margin-right: 90px;
    }
  }

  &__add {
    &-input {
      flex-grow: 1;
      max-width: 256px;
    }
  }

  &__button {
    margin: 32px 32px 0px 0px;
  }
}
