@import './content.scss';
@import './loading.scss';
@import './spinner';
@import './portfolio/index.scss';
@import './portfolio/position.scss';
@import './portfolio/holding.scss';
@import './benchmarks/index.scss';
@import './analysis/summary.scss';
@import './analysis/donut.scss';

//
@import './portfolio/accountAdd.scss';
@import './portfolio/upload.scss';

.modal-im {
  font-family: $font-main;
}
