.sync-modal {
  padding: 25px;
  color: black;
  align-items: center;
  align-content: center;
  overflow: scroll;

  &__content {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__btn-login {
    display: flex;
    width: 44%;
  }

  &__wrapper {
    margin-bottom: 20px;
  }

  &__input {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #dadada;
    margin-top: 6px;
    margin-bottom: 26px;
    padding-left: 5px;
  }

  &__loading {
    margin: 32px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
}
