$c-name: '.btn';

#{$c-name} {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: inherit;
  font-size: 14px;
  font-family: inherit;
  font-weight: $font-w-light;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  padding: 9px 17px;
  margin: 0;
  transition: background-color 300ms;

  &__prime {
    color: white;
    background-color: #255eba;
    border-radius: 4px;

    &--disabled {
      margin-left: 15px;
      opacity: 0.5;
    }

    &:hover {
      background-color: #4a78cf;
    }
  }

  &__default {
    background-color: #dddddd;
    border-radius: 4px;

    &:hover {
      background-color: #ebebeb;
    }
  }

  &__header {
    background-color: #f2f2f2;
    border-radius: 4px;
    color: #245eb9;

    &:hover {
      background-color: #ebebeb;
    }
  }

  &__link {
    color: #255eba;
    text-decoration: underline;
  }

  &__clear {
    color: #898a8d;
    border-radius: 4px;
    background-color: transparent;

    &:hover {
      background-color: transparent !important;
      text-decoration: underline;
    }
  }

  &__remove {
    background: red;
    color: white;
    border-radius: 4px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__action.btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c-blue;
    padding: 6px 8px;
    font-size: 14px;
    background-color: transparent;
    transition: background-color 300ms;

    &:hover {
      border-radius: 4px;
      background-color: #f2f2f2;
    }

    svg {
      margin-right: 4px;

      path {
        fill: $c-blue;
      }
    }

    .btn__centered {
      width: 75%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      font-size: 14px;
      font-family: inherit;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      text-align: center;
      text-decoration: none;
      padding: 9px 17px;
      margin: 0;
      margin-bottom: 10px;
      transition: background-color 300ms;
    }
  }

  & ~ &:not(:first-of-type) {
    margin-left: 15px;
  }
}
