.collapsable {
  width: 100%;
  border: 1px solid $c-gray-light;
  border-radius: 6px;
  overflow: hidden;
  &__header {
    height: 50px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
  }
  &__title {
    font-size: 14px;
    font-weight: $font-w-bold;
    color: $c-nero;
    margin: 0;
  }
  &__arrow {
    transform: rotate(0);
    transition: transform 300ms;
    &--rotate {
      transform: rotate(180deg);
    }
  }
  &__header-border {
    border-top: 1px solid $c-gray-light;
  }
}
