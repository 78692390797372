.content-header {
  &__w {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 0;
    justify-content: space-between;

    &--left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__w-footer {
    padding: 24px;
    background-color: white;
    border: 1px solid #d2d2d2;
    border-radius: 6px 6px 0px 0px;
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &--left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 1;
    font-weight: $font-w-bold;
  }

  &__img {
    width: 24px;
    margin-right: 8px;
  }

  &__icon {
    &--w {
      line-height: 0;
      color: $c-gray;
      margin-right: 20px;

      svg path {
        fill: $c-gray;
      }
    }

    &-filter {
      margin-left: 25px;
      cursor: pointer;
    }
  }
}
