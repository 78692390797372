.household-progress {
  &__icon {
    color: $c-icons;
    padding: 5px 8px;
    background-color: transparent;
    transition: background-color 300ms;

    &:hover {
      background-color: $c-gray-lightest;
      display: inline-flex;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      border-radius: 4px;
      color: $c-active-btn;
    }
  }

  &__start-btn {
    color: $c-active-btn;
    background-color: $c-start-btn;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    border-radius: 4px;
    padding: 8px;
    transition: background-color 300ms;

    &:hover {
      background-color: $c-gray-lightest;
    }
  }
}
