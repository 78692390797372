.null-state {
  &__w {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    max-width: 64px;
  }

  &__heading {
    color: $c-blue;
    font-weight: $font-w-bold;
    font-size: 16px;
    margin: 15px 0;
  }

  &__text {
    max-width: 340px;
    text-align: center;
    font-size: 14px;
    color: $c-gray;
    &--multiImg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
    }
  }

  &__btn-w {
    margin-top: 25px;
  }
}
