.shared-priority-actions {
  width: 100%;
  padding: 16px 24px 24px 24px;
  width: 100%;
  border-top: 1px solid $c-gray-light;
  border-radius: 0 0 6px 6px;
  background-color: #fafafa;
  &__add-action-button-w {
    text-align: end;
    width: 120px;
  }
  &__expand-icon {
    color: $c-blue;
    height: 20px;
    margin-right: 6px;
  }
  &__add-action-plus-icon {
    margin-right: 6px;
  }
  &__no-action-w {
    width: 100%;
    border-radius: 4px;
    background-color: $c-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    text-align: center;
    padding: 32px 0 32px 0;
  }
  &__no-action-text {
    color: $c-blue;
    font-size: 16px;
    font-weight: $font-w-bold;
    margin: 12px 0 12px 0;
  }
  &__no-action-text-2 {
    color: $c-nero;
    font-size: 14px;
  }
  &__action-w {
    width: 100%;
  }
  &__goals {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &-mb20 {
      margin-bottom: 24px;
    }
    &-title {
      font-size: 18px;
      line-height: 22px;
      color: #231f20;
      letter-spacing: 0.005em;
      flex-grow: 1;
    }
    &-toggle-title {
      font-size: 14px;
      line-height: 20px;
      color: #231f20;
      letter-spacing: 0.005em;
    }

    &--mr12 {
      margin-right: 12px;
    }
  }
}
