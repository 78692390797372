.maintenance {
  &__wrapper {
    background: url('../../../images/backgroundlogo.png') fixed;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: #e6ecf3;
    background-position: right -25px top -50px;
    background-size: 385px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    @media (max-width: 768px) {
      background-position: right -130px top -50px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 180px;
    margin-left: 150px;
    padding: 0 10px 10px;
    @media (max-width: 768px) {
      margin-right: 0;
      margin-left: 10%;
    }
  }

  &__content {
    text-align: left;
    margin-right: 300px;
    @media (max-width: 768px) {
      margin-top: 55px;
      width: 75%;
    }
  }

  &__title {
    color: #2178c4;
    margin-bottom: 45px;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__body {
    p {
      font-size: 16px;
      margin: 16px 0;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    a {
      color: #2178c4;
      text-decoration: none;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: auto;
    font-size: 12px;
    color: #8a8888;
    &-links {
      display: flex;
      a {
        margin-top: 5px;
        text-decoration: none;
        color: #678679;
      }
    }
    &-seperator {
      margin: 5px 2px;
    }
    @media (max-width: 768px) {
      width: 80%;
    }
  }
}
