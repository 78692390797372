@import 'taskNull';
@import 'addTaskModal';
@import 'addNewTask';
@import 'taskFilter';
@import 'filterDropdown';

.task {
  &__header {
    font-size: 12px;
    color: #8a8888;
  }

  &__body {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    width: 100%;

    &-table {
      display: flex;
      flex-direction: column;
      width: 100%;

      &--open {
        width: calc(100% - 400px);
        padding: 0 30px 20px;
        min-width: 600px;
      }

      &--closed {
        max-width: 1170px;
        min-width: 600px;
        margin: 0 auto 30px;
        padding: 0 16px;
      }
    }
  }

  &__toggle-btn {
    cursor: pointer;
    font-weight: $font-w-bold;
    padding: 17px 10px;
    box-shadow: inset 0px 0px 0 0 $c-blue;
    transition: box-shadow 300ms, color 300ms;

    & + & {
      margin-left: 10px;
    }

    &--active {
      color: $c-blue;
      box-shadow: inset 0px -2px 0 0 $c-blue;
    }
  }

  &__grid {
    &-header {
      font-size: 12px;
      color: #8a8888;
      background-color: rgb(245, 245, 245);
      display: grid;
      grid-template-columns: 3.5fr minmax(50px, 170px) minmax(50px, 105px) 1.3fr minmax(
          50px,
          100px
        );
      grid-column-gap: 10px;
      padding: 13px 15px;

      &:hover {
        cursor: pointer;
      }
    }

    &-row {
      &:not(:first-child) {
        border-top: 1px solid $c-gray-light;
      }
      display: grid;
      grid-template-columns: 3.5fr minmax(50px, 170px) minmax(50px, 105px) 1.3fr minmax(
          50px,
          100px
        );
      grid-column-gap: 10px;
      padding: 20px 15px;
      min-width: 400px;
      &--active,
      &:hover {
        background-color: $c-blue-lightest;
      }
    }

    &-col-left {
      display: flex;
      align-items: center;
    }

    &-col-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-col-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        width: 16px;
        cursor: pointer;
        vertical-align: middle;
        margin: 0 0 2px 5px;

        path {
          fill: $c-gray;
        }
      }
    }
  }

  &__subject {
    margin-right: 5px;
  }

  &__pic {
    color: $c-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 0;
    margin-right: 10px;
    padding: 5px;
    background-color: white;
    border: 1px solid $c-gray-light;
  }

  &__desc {
    font-weight: $font-w-bold;
    word-break: break-word;

    &-w {
      display: flex;
      align-content: center;
    }
  }

  &__desc,
  &__owner {
    font-size: 14px;
    word-break: break-word;
  }

  &__date {
    font-size: 14px;
    font-weight: $font-w-light;

    &-icon {
      margin-right: 6px;
      width: 18px;
    }
  }

  &__status {
    font-size: 14px;
    color: $c-nero;
    padding: 4px 0;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__position-relative {
    position: relative;
  }

  &__tooltip {
    display: block;
    width: fit-content;
    z-index: 200;
    position: absolute;
    left: 100%;
    background: $c-tooltip;
    border-radius: 4px;
    color: $c-gray-lightest;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    padding: 9px;
    text-align: center;
    // tooltip arrow
    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 100%; /* To the left of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent $c-tooltip transparent transparent;
    }
  }

  &__scroll {
    max-height: 500px;
    overflow-y: overlay;
    border-top: 1px solid #d2d2d2;
  }
}
