.disabled-view {
  &__w {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    &-container {
      max-width: 350px;
      flex-direction: column;
      text-align: center;
      padding: 40px 0 100px 0;
    }

    &-header {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #255eba;
    }

    &-body {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #231f20;
      padding: 20px 0;
    }
  }
}
