.tooltip {
  position: relative;
  &-linkEmoney {
    max-width: 240px;
    min-width: 100px;
    word-break: break-word;
  }
  &-label-name {
    font-weight: $font-w-bold;
  }

  &-trigger {
    display: inline-block;
    text-decoration: none;
  }

  &-bubble {
    position: absolute;
    z-index: $tooltip-zindex;

    &::after {
      content: '';
      position: absolute;
    }
  }

  &-message {
    background: $c-tooltip;
    border-radius: 4px;
    color: $c-gray-lightest;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    padding: 9px;
    text-align: center;
    &-left {
      text-align: left;
      padding: 15px 15px;
      word-break: break-word;
    }
  }

  &-note-message {
    padding-top: 20px;
    font-size: 10px;
  }

  &-position {
    &--top {
      bottom: 100%;
      left: 50%;
      padding-bottom: 9px;
      transform: translateX(-50%);

      &::after {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid $c-tooltip;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &-right {
        bottom: 100%;
        left: 0%;
        padding-bottom: 9px;

        &::after {
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 9px solid $c-tooltip;
          bottom: 0;
          left: 4px;
        }
      }

      &-left {
        bottom: 100%;
        right: 0%;
        padding-bottom: 9px;

        &::after {
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 9px solid $c-tooltip;
          bottom: 0;
          right: 0%;
          transform: translateX(-50%);
        }
      }
    }

    &--bottom {
      top: 100%;
      left: 50%;
      padding-top: 9px;
      transform: translateX(-50%);

      &::after {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid $c-tooltip;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &-right {
        top: 100%;
        left: 0%;
        padding-top: 9px;

        &::after {
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-bottom: 9px solid $c-tooltip;
          top: 0;
          left: 4px;
        }
      }

      &-left {
        top: 100%;
        right: 0%;
        padding-top: 9px;

        &::after {
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-bottom: 9px solid $c-tooltip;
          top: 0;
          right: 0%;
          transform: translateX(-50%);
        }
      }
    }

    &--left {
      top: 50%;
      right: 100%;
      padding-right: 9px;
      transform: translateY(-50%);

      &::after {
        border-left: 9px solid $c-tooltip;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      &-reverse {
        top: 50%;
        padding-right: 9px;
        transform: translateY(-50%) translateX(calc(100% - 10px - 200%));

        &::after {
          border-left: 9px solid $c-tooltip;
          border-top: 9px solid transparent;
          border-bottom: 9px solid transparent;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }

    &--right {
      top: 50%;
      left: 100%;
      padding-left: 9px;
      transform: translateY(-50%);

      &::after {
        border-right: 9px solid $c-tooltip;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &-reverse {
        top: 50%;
        padding-left: 9px;
        transform: translateY(-50%) translateX(calc(100% + 10px));

        &::after {
          border-right: 9px solid $c-tooltip;
          border-top: 9px solid transparent;
          border-bottom: 9px solid transparent;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}
