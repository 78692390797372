.hc-card-modal {
  h3 {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $c-nero;

    span {
      margin-right: 12px;
    }
  }

  p {
    margin: 10px auto;
    font-size: 14px;
    color: $c-nero;
  }

  &__content {
    padding: 24px;
  }

  &__commitment {
    svg path {
      fill: #0068ae;
    }
  }

  &__happiness {
    svg path {
      fill: #f49500;
    }
  }

  &__protection {
    svg path {
      fill: #a7d500;
    }
  }
}
