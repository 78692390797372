.confirm-modal {
  p {
    margin: 10px auto;
    color: $c-nero;
    font-size: 14px;

    &:first-of-type {
      margin-bottom: 25px;
    }
  }

  &__content {
    padding: 24px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px;
  }

  &-dataSource {
    p {
      margin: 10px auto;

      &:first-of-type {
        margin-bottom: 25px;
      }
    }

    &__content {
      padding: 24px;
      color: $c-nero;
      font-size: 14px;
      line-height: 16.41px;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin: 20px 24px;
    }
  }
}
