footer {
  margin-top: 20px;
  padding: 0 15px;
  line-height: 1.3;

  p {
    color: $c-gray;
    font-size: 12px;
    line-height: 14px;
    &:not(:first-of-type) {
      padding-top: 14px;
    }
  }
}
