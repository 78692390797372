.inv-accounts {
  padding: 20px 16px;

  &-test {
    background: gray !important;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $c-gray-light;
  }

  &__error {
    margin-right: -8px;
    display: flex;
    justify-content: end;
    &-icon {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      position: relative;
      background: #f49500;
    }
  }

  &__col-w {
    width: 100%;
    display: flex;
    align-items: center;

    &--header {
      font-size: 11px;
      color: #8a8888;
      font-weight: $font-w-bold;
    }
  }

  &__col-1 {
    flex: 4;
    margin-right: 10px;
  }

  &__col-2 {
    flex: 3;
    margin-right: 10px;
  }

  &__col-3 {
    flex: 3;
    margin-right: 10px;
  }

  &__col-4 {
    flex: 4;
    margin-right: 10px;
  }

  &__col-5 {
    flex: 2;
    text-align: right;
    margin-right: 10px;
  }

  &__col-6 {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__col-7 {
    width: 24px;
  }

  &__name {
    h4 {
      display: block;
      font-size: 14px;
    }
  }

  &__registration-type-w {
    width: 100%;
    display: flex;
    margin-top: 5px;
    align-items: center;
  }

  &__registration-type {
    padding: 3px 6px;
    font-size: 10px;
    background-color: $c-blue-lightest;

    &--unclassified {
      color: $c-red-bright;
    }

    &--warning {
      display: flex;
      align-items: center;
      color: $c-orange;
      font-size: 11px;

      img {
        max-width: 16px;
      }
    }
  }

  &__amount-w {
    font-weight: $font-w-bold;
    font-size: 16px;
  }

  &__amount-date {
    margin-top: 5px;
    font-weight: $font-w-light;
    font-size: 14px;
  }

  &__classification {
    font-size: 14px;
  }

  &__strategy {
    font-size: 14px;
  }

  &__override-icon {
    width: 25px;
    min-width: 25px;
    cursor: pointer;
    margin-left: 5px;
  }

  &__allocation-graph-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__allocation-graph {
    display: flex;
    min-width: 87%;

    &-item {
      height: 10px;
      position: relative;

      &--equity {
        background-color: #255eba;
      }

      &--fix {
        background-color: #ffbe1a;
      }

      &--cash {
        background-color: #ffde8c;
      }

      &--alt {
        background-color: #a2d134;
      }

      &--unclassified {
        width: 100%;
        background-color: $c-gray;
      }
    }
  }

  &__null {
    &-box {
      display: inline-block;
      background-color: #e4e4e4;
      width: 40%;
      height: 10px;
    }
  }

  &__help-icon {
    svg {
      width: 16px;
      height: 16px;
      margin-left: 2px;

      path {
        fill: $c-gray;
      }
    }
  }
}
