.footer {
  width: 100%;
  min-height: 56px;
  font-size: 11px;
  text-align: right;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  background-color: #fafafa;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 120px;
    flex: 1;
    max-width: 1170px;
  }

  &__logo {
    filter: invert(0.5);
    height: 24px;
  }

  &__links {
    font-size: 11px;
    font-weight: $font-w-bold;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;

    p,
    a {
      opacity: 0.7;
      color: #231f20;
      font-size: 11px;
      font-weight: $font-w-bold;
      line-height: 18px;
    }
  }

  &__divider {
    height: 20px;
    width: 1px;
    background-color: #d2d2d2;
    margin: 9px 20px;
  }

  &__copyright {
    color: $c-gray;
    font-size: 11px;
    line-height: 18px;
  }
}
