.funded-score-history-comment {
  margin-bottom: 40px;

  &__header {
    display: flex;
    margin-bottom: 10px;
    font-size: 12px;
    color: $c-gray;

    &-score {
      flex: 1;
    }

    &-date {
      flex: 1;
      text-align: right;
    }
  }

  &__column {
    flex: 1;
    &-w {
      display: flex;
      width: 100%;
    }
  }

  &__notes {
    white-space: pre-wrap;
  }
}
