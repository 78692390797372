.message-body {
  &-w {
    flex: 3;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__list {
    margin-top: 10px;
    overflow-y: auto;
    flex: 1;

    &-error {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .messages-error {
        width: unset;
      }
    }
  }

  &-item-w {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }

  &__video {
    cursor: pointer;
  }

  &__video-thumbnail {
    border-radius: 20px;
    margin-top: 10px;
    width: 280px;
    img {
      border-radius: 20px;
      margin-top: 10px;
      width: 280px;
    }
  }

  &__video-thumbnail-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      content: ' ';
      width: 50px;
      height: 50px;
      background: url('../../../images/play_button.png') no-repeat 0 0;
      background-size: 50px 50px;
      position: absolute;
    }
  }

  &__video-media {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
  }

  &__video-loader {
    background-color: #2178c4;
    margin-top: 10px;
    width: 385px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__thumbnail-loader {
    background-color: #2178c4;
    border-radius: 20px;
    margin-top: 10px;
    width: 280px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-container {
    margin-left: 10px;
  }

  &-heading {
    &-w {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
    }

    &__name {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: $c-nero;
    }

    &__date {
      font-size: 12px;
      line-height: 16px;
      color: $c-gray;
    }

    &__dot {
      font-size: 12px;
      line-height: 16px;
      color: $c-gray;
      margin: 0 5px;
    }
  }

  &__avatar {
    color: $c-white;
    background-color: $c-blue-light;
    border: 1px solid $c-white;
    border-radius: 18px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 1px;
    border-collapse: collapse;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    min-width: 36px;
  }

  &__message {
    font-size: 14px;
    line-height: 20px;
    color: #444444;
    word-break: break-word;
  }

  &__divider {
    &-w {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-line {
      height: 1px;
      background-color: $c-gray-light;
      flex: 1;
      margin: 10px;
    }

    &-date {
      font-size: 12px;
      color: $c-gray;
    }
  }
}
