@import 'productinfo';

.mimeo {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: black;

  .form-group__error-box {
    height: 0;
    overflow: visible;
  }

  &__section {
    padding: 0 20px;
    display: flex;
    width: 100%;

    &:not(:first-child) {
      margin-top: 20px;
    }

    &-w {
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    &-left,
    &-right {
      display: inline-flex;
      align-items: center;
      width: 50%;
    }

    &-right {
      margin-left: 10px;

      &--end {
        justify-content: flex-end;
      }
    }

    &-left--column {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__label {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;

    a {
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    &--trips {
      width: calc(100% / 3);
    }

    span:not(.form-input-Error__message) {
      font-size: 14px;
      margin-bottom: 3px;
      color: $c-nero;
    }

    > div:not(.form-group) {
      display: flex;
      align-items: center;
      min-height: 36px;
    }
  }

  &__input {
    font-size: 14px;
    min-height: 36px;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    padding: 8px;

    &--error {
      border-color: $c-red-bright;
    }
  }

  &__btn {
    &-w {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      padding: 0 20px;
    }
  }
}
