.filter-modal {
  position: absolute;
  z-index: 800;
  min-width: 260px;
  top: 65px;
  transform: translateX(75px);
  font-weight: 300;
  font-size: 14px;
  background-color: white;
  border-radius: 6px;
  border: solid #d2d2d2 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:after {
    left: 11px;
    top: -19px;
    border-color: transparent transparent white transparent;
    border-width: 10px;
  }

  &:before {
    left: 10px;
    top: -22px;
    border-color: transparent transparent #d2d2d2 transparent;
    border-width: 11px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    background: #fafafa;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 6px 6px 0 0;
    padding: 8px 0 8px 20px;
    &-label {
      display: flex;
      align-items: center;
    }

    &-text {
      margin-left: 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__body {
    padding: 20px;

    &-label {
      font-weight: bold;
      display: block;
      padding: 10px 0;
      font-size: 14px;
    }

    &-radio-group {
      padding: 5px 0;
    }

    &-buttons {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
