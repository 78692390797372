@import './results-summary-action-modal';

.results-summary {
  &__row {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
  }

  &__print {
    margin-top: 20px;
    border: 1px solid #d2d2d2;
    overflow: scroll;
    overflow-x: hidden;
    height: 580px;

    &-buttons {
      margin: 32px 0 16px 0;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__for-pdf {
    display: none;
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    width: 100%;

    &-item {
      height: 50px;
      display: flex;
      font-size: 11px;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #d2d2d2;
      width: 116px;

      &--border {
        border-right: 1px solid #d2d2d2;
      }

      &--wider {
        width: 496px;
      }

      &--single-contact {
        width: 613px;
      }
    }

    &-empty {
      background-color: white;
      width: 230px;
      height: 50px;
      box-shadow: 0 30px 20px 0 rgba(0, 0, 0, 0.25);
    }
  }

  &__card-w {
    background-color: white;
    padding: 10px 15px;
    box-shadow: 0 30px 20px 0 rgba(0, 0, 0, 0.25);
  }

  &__card-shared {
    position: relative;
  }

  &__card-shared-icon {
    position: absolute;
    float: left;
    width: 18px;
    height: 18px;
    margin-top: -29px;
    left: 8px;
    color: white;
  }

  &__score-w {
    display: flex;
    align-items: center;
    border-right: 1px solid #d2d2d2;
  }

  &__actions-w {
    flex: 1.5;
    padding: 0 15px;
  }

  &__action-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0px 10px 5px 10px;
  }

  &__action-task {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding: 2px 5px 2px 10px;
    background: white;
    border: solid 1px #d3d3d3;
    border-radius: 6px;

    &-description {
      flex-grow: 2;
      line-height: 32px;
      color: $c-gray;
    }

    &-contacts {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__contact-avatar {
    color: $c-gray;
    font-weight: 700;
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;

    img {
      border-radius: 18px;
    }
  }

  &__task-see-all {
    cursor: pointer;
    display: flex;
    flex: 1;
    color: $c-blue;
    justify-content: center;
    font-size: 10px;
    background: #e3f2fc;
    padding: 3px 0px 3px 0px;
    border-radius: 4px;
    margin-top: 10px;
  }
}
