.household-display {
  &-w,
  &__avatar-w {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 64px;
  }

  &__avatar {
    img {
      border-radius: 18px;
    }

    &--primary {
      z-index: 2;
    }

    &--secondary {
      position: relative;
      z-index: 1;
      right: 3px;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: $font-w-bold;
    color: $c-nero;
    line-height: 17px;
  }
}
