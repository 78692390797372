.ivf-allocation-description {
  &-w {
    display: flex;
    flex-direction: column;
    border: 1px solid $c-gray-light;
    margin: 4px;
    border-radius: 6px;
    position: relative;
    padding: 16px;
    min-width: 210px;
    min-height: 290px;
    align-items: center;
    &--opaque {
      opacity: 0.3;
    }
    &--active {
      border: 1px solid $c-blue;
    }
    &--hovering {
      box-shadow: 0 4px 8px 0 rgba(35, 31, 32, 0.18);
    }
    max-width: 210px;
  }
  &__title {
    color: #000;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding: 16px;
  }
  &__divider {
    height: 1px;
    width: 30px;
    background-color: $c-gray-light;
  }
  &__attribute {
    display: flex;
    &-w {
      position: absolute;
      top: 130px;
      padding: 15px;
      width: 100%;
    }
    &-title {
      font-size: 14px;
      line-height: 16px;
      color: $c-nero;
      padding: 4px 0;
    }
    &-value {
      flex: 1;
      text-align: right;
      font-weight: $font-w-bold;
      font-size: 14px;
      line-height: 17px;
      margin-top: 4px;
      &--good {
        color: $c-green-lighter;
      }
      &--bad {
        color: $c-red-dark;
      }
      &--neutral {
        color: $c-nero;
        opacity: 0.5;
      }
    }
    &-stddev {
      padding-right: 13px;
    }
  }
  &__pie-chart {
    display: flex;
    justify-content: center;
    position: relative;
    &-value {
      position: absolute;
      margin-top: 10px;
      align-self: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
    }
  }
}
