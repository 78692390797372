.profile-avatar {
  &__w {
    width: 36px;
    height: 36px;
  }

  img {
    max-width: 36px;
  }
}
