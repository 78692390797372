.nav-menu {
  position: fixed;
  z-index: 800;
  top: 84px;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-right: 1px solid #d2d2d2;
  padding-top: 24px;
  width: 290px;
  transition: width 300ms;
  overflow: hidden;

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    padding: 8px;
    color: $c-gray;
    background-color: white;
    box-shadow: inset 0px 0px $c-blue;
    transition: background-color 300ms, box-shadow 300ms;

    &:hover {
      background-color: $c-white-smoke;
    }

    &--active {
      background-color: $c-white-smoke;
      box-shadow: inset 2px 0px $c-blue;
    }

    &-icon {
      max-width: 24px;
    }
  }

  &__title {
    position: absolute;
    left: 40px;
    font-size: 16px;
    color: $c-nero;
    white-space: nowrap;
    opacity: 1;
    transition: opacity 300ms;
  }

  &--collapsed {
    width: 42px;

    .nav-menu__link {
      min-width: 0;
    }

    .nav-menu__title {
      flex: 0;
      opacity: 0;
      max-width: 0;
    }
  }
}
