.hc-satisfaction {
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-left {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
    }

    &-right {
      display: flex;
      justify-content: space-between;
      // width: 45%;
      align-items: center;
      // font-size: 14px;
      font-weight: 500;
      color: #255eba;
    }
    &-icon {
      display: flex;
      align-items: center;
      padding: 0 12px;
      margin-right: 12px;
      cursor: pointer;
      img {
        width: 20px;
        margin-right: 6px;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;

    .hc-wrapper--row {
      display: flex;
      align-items: center;
    }

    .hc-wrapper--col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
    }

    &-card {
      width: 50%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      &:first-child {
        margin-right: 24px;
      }
    }

    &-avatar {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      img {
        border-radius: 50%;
      }
    }

    &-name {
      color: #231f20;
      font-weight: 500;
    }

    &-date {
      color: #8a8888;
      font-size: 12px;
    }

    &-icon {
      margin-left: 8px;
      margin-bottom: 11px;
      width: 16px;
    }
  }
}
