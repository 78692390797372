.status-select {
  &__icon {
    flex: 0 0 20px;
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 10px;
    &--green {
      background-color: #e8f3cc;

      &:after {
        background-color: #8ccc33;
      }
    }

    &--orange {
      background-color: #ffedd1;

      &:after {
        background-color: #ffbe1a;
      }
    }

    &--red {
      background-color: #fae1e2;

      &:after {
        background-color: #b21e28;
      }
    }

    &--na {
      background-color: #e5e5e5;

      &:after {
        background-color: #8a8888;
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 10px;
      width: 10px;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 50%);
      border-radius: 50%;
    }
  }

  &__menu {
    position: absolute;
    z-index: 500;
    display: block;
    width: 236px;
    margin-top: 10px;
    left: 0;
    font-size: 14px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid $c-gray-light;

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      top: -19px;
      left: 26px;
      border-color: transparent transparent white transparent;
      border-width: 10px;
    }

    &:before {
      top: -22px;
      left: 25px;
      border-color: transparent transparent $c-gray-light transparent;
      border-width: 11px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      cursor: pointer;
      height: 40px;

      &:not(:last-child) {
        border-bottom: 1px solid $c-gray-light;
      }

      &:hover {
        text-decoration: underline;
      }

      label {
        width: 100%;
        text-align: left;
        cursor: pointer;
        color: $c-nero;
        font-size: 14px;
      }

      span {
        transition: color 300ms;
        padding: 5px 0;
      }

      &--active {
        cursor: unset;

        label {
          color: $c-blue;
          cursor: unset;
        }

        &:hover {
          text-decoration: none;
        }

        span {
          color: $c-blue;
        }
      }

      &--active svg path {
        fill: $c-blue;
      }
    }
  }
}
