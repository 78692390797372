.folder {
  &__null {
    &--stateBackground {
      background: rgba(255, 255, 255, 0.5);
      height: 223px;
      padding-top: 40px;
      width: 100%;
      position: relative;
      padding-left: 15%;
      padding-right: 15%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: 'center';
      margin-top: -224px;
    }
    &--title {
      font-size: 16px;
      color: $c-blue;
      font-weight: 500;
      line-height: 19px;
      padding: 12px 12px 6px;
    }
    &--description {
      padding: 6px 12px 12px;
      color: $c-nero;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }
  &__column {
    &--name {
      display: flex;
      flex-direction: row;
      align-items: center;
      display: flex;
      flex: 5;
      color: $c-titles;
      font-size: 15px;
      font-weight: $font-w-bold;
      line-height: 18px;
    }

    &--file-count {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      color: $c-titles;
      font-size: 14px;
      line-height: 16px;
    }

    &-new-tag {
      background-color: #8ccc33;
      color: white;
      font-size: 10px;
      border-radius: 4px;
      padding: 3px 5px;
      margin-left: 16px;
    }
  }

  &__column-actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    color: $c-icons-disabled;

    &--delete {
      color: $c-icons-enabled;

      &:hover {
        color: $c-icons;
        cursor: pointer;
      }
    }

    &--lock {
      color: $c-icons-disabled;
    }
    &--null {
      color: black;
    }
  }

  &__icon {
    margin-right: 8px;

    &--w {
      color: $c-gray;
    }
  }
}
