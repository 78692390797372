.horizontal-bar {
  &__bar {
    height: 100%;
    border-radius: 2px;
    width: 0%;
    transition: width 0.5s 0s;
  }

  &-w {
    flex: 1;
    background-color: $c-background-hover;
    width: 100%;
    height: 12px;
    border-radius: 2px;
  }
}
