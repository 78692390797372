$c-name: '.txtarea';

#{$c-name} {
  display: inline-block;
  width: 100%;
  max-height: 32px;
  border-radius: 6px;
  padding-top: 7px;
  padding-left: 7px;
  background-color: white;
  border: solid 1px $c-gray-light;
  line-height: 1;
  opacity: 1;
  font-family: $font-main;
  font-size: 14px;
  font-weight: $font-w-light;
  color: $c-nero;
}

textarea:focus {
  outline: none;
}
