.disclosure {
  &__body {
    &-w {
      max-width: 1170px;
      padding: 0 16px;
      width: 100%;
      margin: 20px auto 25px;
      font-size: 11px;
      color: #8a8888;
      text-align: left;
      line-height: 13px;

      a {
        color: #8a8888;
      }
    }
  }
}
