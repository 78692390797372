.edit-team {
  &__back-btn {
    margin: 24px 0;
    opacity: 0.6;
    color: $c-nero;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__team-profile {
    display: flex;
    align-items: center;
    margin-bottom: 22.5px;
    p {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      img {
        margin-left: 7px;
        cursor: pointer;
      }
    }
  }

  &__img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    border: 2px dashed #d2d2d2;
    margin-right: 14px;

    &--placeholder {
      display: block;
      width: 36px;
      height: 36px;
    }

    &--real {
      display: block;
      border-radius: 50%;
    }
  }

  &__no-border {
    border: none;
  }

  &__add-btn {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
    border-top: 1px solid $c-gray-light;
    border-bottom: 1px solid $c-gray-light;
  }
}
