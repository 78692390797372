.new-message-modal {
  &-w {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    flex-direction: row;
  }
  &__subject-w {
    flex: 1;
    padding-right: 5px;
  }
  &__recipient-w {
    flex: 1;
    padding-left: 5px;
  }
  &__label {
    padding: 10px 0;
    font-size: 14px;
    color: $c-nero;
  }
  &__message {
    &-w {
      margin: 15px 0;
    }
    &-text-area {
      width: 100%;
      border-radius: 4px;
      border-color: $c-gray-light;
      resize: none;
      height: 66px;
      font-size: 14px;
      color: $c-nero;
      padding: 5px 10px;
      font-family: $font-main;
    }
  }
  &__buttons {
    justify-content: flex-end;
    display: flex;
  }
}
