.results-summary-action-modal {
  &__header {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    width: 580px;
  }

  &__icon {
    & svg {
      path {
        fill: red;
      }
    }
  }

  &__title {
    display: flex;
    color: 'white';
    text-align: center;
    margin: auto;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    flex: 1;
  }

  &__close {
    cursor: pointer;
    display: flex;
    & svg {
      path {
        fill: white;
      }
    }
  }

  &__tasks {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 700;
    &-list {
      height: auto;
      max-height: 400px;
      overflow-y: auto;
    }
  }
}
