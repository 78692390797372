.c-dash__pal {
  &-icon-w {
    display: flex;
    justify-content: flex-end;

    > a {
      display: flex;
      align-items: center;
      margin-left: 13px;
    }
  }

  &-icon {
    display: flex;
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 3px;

    &-w {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-label {
      font-size: 14px;
    }

    &--select {
      cursor: pointer;
      border-radius: 4px;
      padding: 8px;
      color: $c-blue;
      transition: background-color 300ms;
      background-color: #f2f2f2;

      &:hover,
      &-open {
        background-color: #e6e6e6;
      }
    }

    &--green {
      background-color: #e8f3cc;

      &:after {
        background-color: #8ccc33;
      }
    }

    &--orange {
      background-color: #ffedd1;

      &:after {
        background-color: #ffbe1a;
      }
    }

    &--red {
      background-color: #fae1e2;

      &:after {
        background-color: #b21e28;
      }
    }

    &--na {
      background-color: #e5e5e5;

      &:after {
        background-color: #8a8888;
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 10px;
      width: 10px;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 50%);
      border-radius: 50%;
    }
  }
}
