.side-bar-body {
  padding: 15px;
  background-color: #fff;
  &__panel {
    transition: all 300ms;
    position: fixed;
    bottom: 0px;
    top: 87px;
    right: 0px;
    transform: translateX(401px);
    &--open {
      transform: translateX(0);
    }
  }
  textarea {
    resize: none;
    border: none;
    width: 100%;
    max-height: 150px;
    padding: 0;
    font-family: inherit;
  }
  &__assignee-row,
  &__icon-row {
    width: 100%;
    display: flex;
    align-items: center;

    &-margin {
      &--right {
        margin-right: 10px;
      }
      &--left {
        margin-left: 10px;
      }
    }
  }
  &__assignee-row {
    height: 23px;
  }

  &__line-break {
    height: 1px;
    width: 100%;
    background-color: #e6e7ea;
    margin: 6px 0 25px 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: auto auto;
    grid-column-gap: 15px;
  }

  &__selector {
    &-w {
      position: relative;
      color: #2d2d2d;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-target {
      width: 95%;
      box-sizing: border-box;
      user-select: none;
    }

    &-status-modal {
      position: relative;
      margin-left: 15px;
    }
    &-select-modal {
      position: relative;
    }
  }

  &__checkbox-container {
    margin-bottom: 25px;
  }

  &__checkbox-row {
    align-items: center;
    label {
      font-size: 16px;
      line-height: 18px;
      color: #2a2d33;
      &:nth-child(2) {
        margin-right: 40px;
      }
    }
    & input[type='checkbox'] {
      position: relative;
      cursor: pointer;
      margin: 0;
      margin-top: -4px;
      margin-right: 12px;
    }
    & input[type='checkbox']:before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      border: 1px solid #b8b8b8;
      border-radius: 3px;
      background-color: white;
    }
    & input[type='checkbox']:checked:after {
      content: '';
      display: block;
      width: 5px;
      height: 10px;
      border: solid $c-blue;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
    }
  }

  &__notes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;
    color: #8790a3;
    margin: 18px 0px 30px 0px;
  }
  &__notes-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    > img {
      margin-right: 7px;
    }
  }

  &__show-hide {
    margin-bottom: 25px;
  }

  &__text {
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #2a2d33;

    &--header {
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.25px;
      color: #727a8a;
      margin: 0 0 10px 0;
    }

    &--error {
      padding: 3px 0;
      color: $c-red-bright;
      font-size: 12px;
    }

    &--placeholder {
      color: #d2d2d2;
    }
  }

  &__custom-goal {
    background-color: white;
    padding: 31px 16px 30px 16px;
    letter-spacing: 0.234375px;
    line-height: 18px;
    font-size: 15px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
  }

  &__suggested-goal {
    padding: 13px 0px 13px 15px;
    letter-spacing: 0.25px;
    line-height: 24px;
    font-size: 14px;
    display: flex;
    &-view {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 18px 16px 18px 16px;
      letter-spacing: 0.25px;
      line-height: 16px;
      font-size: 14px;
      cursor: pointer;
      border-top: 6px solid #f8f8f8;
      &:first-child {
        border-top: 0px;
      }
    }
  }

  &__priorities {
    margin-top: 30px;
    background-color: #fff;
    margin-bottom: 60px;
  }

  &__priorities-header {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e7ea;
    height: 40px;
    align-items: center;
  }

  &__priorities-body {
    min-height: 182px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  &__add_priorities {
    font-size: 14px;
    line-height: 16px;
    color: #255eba;
    cursor: pointer;
  }

  &__priorities-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #8790a3;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    min-height: 182px;
    > img {
      margin-bottom: 24px;
    }
  }

  &__priorities-cards-list {
    padding: 0px 15px;
    background-color: white;
  }

  &__delete {
    &-text {
      padding: 15px 20px;
      font-size: 14px;
      line-height: 24px;
      color: #231f20;
    }

    &-button-w {
      display: flex;
      justify-content: flex-end;
      padding: 15px 20px;
    }
  }

  /* =============  SUGGESTED CARDS / CARDS VIEW CSS  ============== */

  &__cards {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  &__cards-item {
    left: 0px;
    z-index: 10;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    align-items: center;
    width: 20px;
    height: 20px;
    img {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: white;
    }
    &--border {
      border-top: 6px solid #f8f8f8;
      &:first-child {
        border-top: 0px;
      }
    }
    &--pointer {
      cursor: pointer;
    }
  }

  &__cards-item-content {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
  }

  &__cards-item-body {
    flex: 0 0 40px;
    align-items: center;
    justify-content: center;
  }

  &__cards-item-block {
    display: flex;
    min-height: 56px;
    align-items: center;
    justify-content: space-between;
    > span {
      display: flex;
      align-items: center;
      > img {
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
      }
      > span {
        margin: 16px 16px;
      }
    }
  }
}
