.moneymind__results {
  display: inline-flex;
  flex-direction: column;
  flex: 0 1 45%;
  width: 45%;
  margin: 0 10px;

  &-w {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  &-name {
    color: $c-nero;
    font-weight: $font-w-bold;
    font-size: 14px;
    margin: 24px 0;
  }
}
