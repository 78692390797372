.hc-page-header {
  &__w {
    max-width: 960px;
    margin: 25px auto;
    display: flex;
    align-items: center;
  }

  &__text {
    color: white;
    font-size: 20px;
    font-weight: 500;
  }
  &__number {
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid white;
  }
}
