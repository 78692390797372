.shared-priority-comments {
  width: 100%;
  height: 100%;
  padding: 24px 24px 27px 30px;
  &:nth-child(2) {
    padding-top: 0;
  }
  &__contact-w {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  &__profile-pic {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-right: 12px;
  }
  &__name-score-w {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
  }
  &__name {
    color: $c-nero;
    font-size: 16px;
    margin-bottom: 4px;
  }
  &__date {
    color: $c-gray;
    font-size: 12px;
  }
  &__score-w {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a2d134;
    color: $c-white;
    font-weight: $font-w-bold;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    &--orange {
      background-color: #ffbe1a;
    }
  }
  &__comment-heading {
    color: $c-nero;
    font-size: 16px;
    margin-bottom: 6px;
    line-height: 24px;
  }
  &__comment {
    color: $c-nero;
    font-size: 14px;
    line-height: 20px;
  }
}
