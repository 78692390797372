.add-task-modal {
  padding: 16px 24px;
  color: $c-nero;
  &__related-action-w {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #d2d2d2;
  }
  &__status-w {
    display: flex;
    align-items: center;
    width: 300px;
  }
  &__action {
    color: $c-nero;
    font-size: 14px;
    font-weight: $font-w-bold;
  }
  &__due-date {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $c-nero;
  }
  &__calendar-icon {
    color: $c-gray-light;
  }
  &__card-icon-w {
    position: relative;
    > span {
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
    }
  }
  &__radio-options-w {
    display: flex;
    margin-bottom: 14px;
  }
  &__select {
    label {
      display: inline-table;
      align-items: center;
      color: $c-nero;

      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }

  &__input {
    width: 100%;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px;

    &--date-picker {
      input {
        border: none;
      }
    }
  }

  &__checkbox-w {
    display: flex;
    align-items: center;
    font-size: 14px;

    &-label {
      margin-left: 3px;
    }
    &--spacing {
      margin-right: 24px;
    }
  }

  &__input-w {
    margin-top: 10px;

    label {
      display: flex;
      align-content: center;
      margin-bottom: 6px;
      font-size: 14px;

      input {
        margin-right: 10px;
      }
    }

    &--split {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      > div {
        width: 50%;

        &:not(:first-of-type) {
          margin-left: 16px;
          margin-bottom: 17px;
        }
      }
    }

    p {
      margin-bottom: 3px;
    }

    textarea {
      width: 100%;
      height: 100px;
      border: 1px solid $c-gray-light;
      border-radius: 4px;
      font-size: 14px;
      padding: 10px;
      font-family: $font-main;
    }
  }

  &__input-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px;

    svg {
      margin: 0 10px;
      color: $c-gray;
    }
  }

  &__btns-w {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
}
