.hcw__welcome {
  background: white;
  align-self: center;

  &-image {
    width: 100%;
    height: 417px;
    background-color: #e6e6e6;
    background: url('../../../images/honestConversations/welcome-illustration.png')
      no-repeat 0 0;
    background-size: cover;
  }

  &_messege {
    font-family: $font-main;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: 30px;
    }

    &-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
    }

    &-block {
      line-height: 20px;
      font-size: 14px;
    }

    &-title {
      font-size: 36px;
      line-height: 44px;
      font-weight: 500;
      color: $c-blue;
      padding: 45px 0 0;

      &-m {
        font-size: 20px;
        padding: 15px 0 5px;
      }
    }

    &-body {
      padding: 25px 120px 0px 120px;
      line-height: 21px;
      text-align: center;
      font-size: 14px;

      &-sm {
        padding: 0px 20px 0px 20px;
      }

      &-goto {
        padding: 0px 20px 15px 20px;
      }

      &-med {
        font-weight: 500;

        &-name {
          padding: 0px 10px 0px 10px;
          background-color: $c-gray-lighter;
          border-radius: 5px;
        }
      }

      &-desc-image {
        height: 140px;
      }

      &-code {
        padding: 0px 0px 0px 0px;
        font-size: 36px;
        line-height: 44px;
        font-weight: 500;
        color: $c-blue;
      }

      &-status {
        padding: 5px 10px 0px 10px;
        color: $c-gray;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
      }

      span {
        color: $c-blue;
      }
    }
  }

  &_divider {
    border-left: solid 2px #d2d2d2;
    height: 30px;
    margin-top: 28px;
    margin: 28px 20px 0 20px;
  }
}
