.action-modal .tile__header-left {
  svg {
    height: 24px;
    width: 24px;
    path {
      fill: $c-gray;
    }
  }
}

.action-form {
  &__content-w {
    padding: 24px;
  }
  &__second-row {
    display: flex;
    justify-content: space-between;
  }
  &__assign-to-w {
    position: relative;
    max-width: 200px;
  }
  &__assign-owner-modal-w {
    position: absolute;
    top: 70px;
    left: 0;
    z-index: $max-zindex;
  }
  &__status-w {
    position: relative;
  }
  &__status {
    display: flex;
    align-items: center;
    padding: 8px;
    width: 220px;
    height: 36px;
    border: 1px solid #c2c2d2;
    border-radius: 4px;
  }
  &__status-text {
    font-size: 14px;
    color: $c-nero;
  }
  &__due-date-w {
    width: 100px;
  }

  &__date-input-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    width: 100%;
    padding: 10px;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    font-size: 14px;
  }
  &__date-picker {
    width: 100% !important;
  }
  &__calendar-icon {
    color: $c-blue;
    height: 24px;
    width: 53px;
  }

  &__note-w {
    margin-bottom: 32px;
  }
  &__note {
    height: 100px;
    width: 100%;
    resize: none;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    padding: 10px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    &:focus {
      color: #495057;
      background-color: $c-white;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
    }
  }
  &__button-w {
    display: flex;
    justify-content: flex-end;
  }
  &__input-header {
    color: $c-nero;
    font-size: 14px;
    margin-bottom: 6px;
  }
}
