@import 'insurancePolicyModal';
@import 'accountModal';
@import 'accountNull';

.insurance {
  &-w ~ &-w {
    margin-top: 17px;
  }

  &__col {
    &-w {
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &-item {
      position: relative;
      display: inline-flex;
      align-items: center;
      color: $c-nero;

      label {
        color: $c-gray;
        text-transform: uppercase;
        span {
          &::before {
            content: '';
            display: block;
          }
        }
      }

      &--small {
        flex: 2 1;
        margin-right: 10px;
      }

      &--medium {
        flex: 3 1;
        margin-right: 10px;
      }

      &--menu-toggle {
        width: 24px;
      }

      &--monthly {
        text-align: left;
        width: 103px;
        justify-content: flex-end;
      }

      & .insurance-policy-modal__account-details,
      & .insurance-add-modal__account-details {
        label {
          text-transform: unset;
        }
      }
    }
  }

  &__row {
    padding: 13px 16px;

    .insurance__col-item {
      font-size: 14px;
      color: $c-nero;
      word-break: break-word;
    }
  }

  &__edit {
    svg {
      cursor: pointer;
      path {
        transition: fill 300ms;
        fill: $c-gray-light;
      }
    }
  }

  &__toggle-pdf {
    div {
      margin-right: 20px;
    }
    svg {
      vertical-align: middle;
      width: 16px;
      margin-bottom: 2px;
      margin-left: 2px;
      path {
        fill: $c-gray;
      }
    }
  }
}
