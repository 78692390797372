.strategy-search {
  &__filter-btns {
    &-w {
      // forgive my sins
      position: absolute;
      right: 0;
      top: -67px;
    }

    &-clear {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #22263f;
      font-size: 16px;
    }

    &-counter {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: #2178c4;
      border-radius: 22px;
      padding: 3px 5px;
      min-width: 24px;
      min-height: 20px;
      margin-left: 8px;
    }
  }
  &__filter-strategy {
    position: relative;
    display: flex;
    align-items: flex-start;
    height: 0;
    overflow: hidden;

    &--open {
      margin: 40px 0 50px 0;
      overflow: visible;
      height: 100%;
    }
  }

  &__type {
    &-text {
      font-size: 16px;
      color: #676879;
      margin-bottom: 16px;
    }

    &-w {
      min-width: 270px;
    }

    &-option {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #22263f;
      padding-left: 40px;
      line-height: 25px;

      input {
        height: 0;
        width: 0;
        margin: 0;
        display: none;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
      }

      &:before {
        display: inline-block;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        border: 1px solid #22263f;
      }

      &:after {
        background-color: #22263f;
        left: 4px;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        transform: scale(0);
        opacity: 0;
        transition: transform 300ms, opacity 200ms;
        position: absolute;
        top: 4px;
      }

      &:hover:after {
        opacity: 0.2;
        transform: scale(1);
      }

      &--checked {
        &:after {
          opacity: 1;
          transform: scale(1);
        }
        &:hover:after {
          opacity: 1;
        }
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__asset-class {
    &-w {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-group {
      width: 45%;

      &--spacing {
        margin-bottom: 48px;
      }
    }
  }
}
