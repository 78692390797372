.goals__null {
  width: 100%;
  height: 10px;
  background: rgba(135, 144, 163, 0.2);

  &__col {
    color: $c-gray;

    &-w {
      display: flex;
      width: 100%;
    }
    &-item {
      position: relative;
      display: inline-flex;
      align-items: center;
      color: $c-nero;

      &--large {
        width: 95%;
      }

      &--small {
        width: 75px;
      }
      &--xsmall {
        width: 50px;
        margin-left: 32px;
        display: flex;
        justify-self: flex-end;
      }

      &--center {
        justify-content: center;
        width: 40px;
      }

      &--right {
        justify-content: flex-end;
      }
    }
  }

  &--col {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 25px;
    justify-content: space-between;
  }

  &__row {
    padding: 17px 16px !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    background: #ffffff;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  &--name {
    max-width: 70%;
  }
  &--status,
  &--checkin {
    max-width: 40%;
    height: 8px;
  }

  &--hc {
    max-width: 0;
  }

  &--notes {
    max-width: 30px;
  }

  &--active {
    max-width: 70px;
  }

  &__status {
    &-icon {
      display: flex;
      position: relative;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-right: 10px;

      &-w {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      &--na {
        background: rgba(135, 144, 163, 0.2);
        margin-right: 15px;
      }

      &--nomargin {
        margin: 0;
        &:nth-child(2) {
          margin-left: -5px;
          border: 1px solid white;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  &__right {
    width: 20%;
    display: flex;
    align-items: center;
  }

  &__loading-effect {
    position: absolute;
    width: 20%;
    height: 100%;
    background-color: white;
    opacity: 0.3;
    top: 0;
    left: 0;
    animation-name: move;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    z-index: 1000;
  }
}

@keyframes move {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(500%);
  }
}
