.sync-row {
  padding-top: 3%;
  padding-bottom: 3%;
  border-top: solid 1px #afafaf;
  border-bottom: solid 1px #afafaf;

  &--flex {
    display: flex;
  }

  &__icon {
    height: 36px;
    margin: 0 8px;
  }
}

.sync__content {
  margin-left: 5px;

  h1 {
    font-weight: bold;
  }

  &-text {
    font-size: 12px;
    color: #afafaf;
    margin-top: 5px;
  }

  &-info {
    font-size: 12px;
    color: #afafaf;
    padding: 20px 0;
  }

  &-heading {
    font-weight: bold;
  }
}

.sync-modal {
  &__table-header-select-w {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }

  &__table-row-w {
    max-height: 300px;
    overflow-y: scroll;
  }
  .modal__box-w {
    margin: auto;
  }
}

.wealthbox-login {
  &-w {
    margin: 32px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  &__header {
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

.redtail-login {
  &-w {
    margin: 32px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  &__header {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  &__error {
    font-size: 14px;
    color: $c-red-bright;
  }
}

.sync-screen--w {
  margin-top: 50px;
}
