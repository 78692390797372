.funded-score-tile {
  &__last-updated {
    font-size: 12px;
    padding-top: 10px;
    color: $c-gray;
    margin-right: 18px;
    text-align: right;
  }

  &__title {
    font-size: 14px;
    font-weight: $font-w-bold;
    line-height: 1.71;
    letter-spacing: normal;
    color: $c-nero;
  }

  &__header-w {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    padding: 0 8px 0 21px;
  }
  &__default-icon {
    padding: 0 8px 0 21px;
    color: $c-blue-light;
    display: flex;
  }
}
