$c-name: '.input';

#{$c-name} {
  &__txt {
    height: 32px;
    border: 0;
    border-radius: 6px;
    padding-left: 7px;
    background-color: #ffffff;
    border: solid 1px #d2d2d2;
  }

  &-w {
    display: inline-block;
    user-select: none;
    cursor: pointer;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__radio {
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      cursor: inherit;
    }

    &-dot {
      margin: 0 7px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: white;
      border: solid 1px #b8b8b8;
    }

    &-dot:after {
      content: '';
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(45%, 50%);
      width: 0px;
      height: 0px;
      border-radius: 50%;
      background-color: transparent;
      transition: width 0.3s, height 0.3s;
    }

    &:hover input ~ .input__label &-dot:after,
    & input:checked ~ .input__label &-dot:after {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #0047b9;
    }
  }
}

::-webkit-input-placeholder {
  color: $c-nero;
  opacity: 0.5;
}
::-moz-placeholder {
  color: $c-nero;
  opacity: 0.5;
}
:-ms-input-placeholder {
  color: $c-nero;
  opacity: 0.5;
}
:-moz-placeholder {
  color: $c-nero;
  opacity: 0.5;
}

input:focus {
  outline: none;
}
