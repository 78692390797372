.c-settings {
  &__household {
    &-label {
      display: block;
      padding-top: 10px;
      font-size: 14px;
    }

    h1 {
      font-size: 16px;
      font-weight: $font-w-bold;
      margin-bottom: 25px;
    }

    &--full-w {
      width: 100%;
      border-bottom: solid 1px #d3d3d3;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    &-select {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 20px;
      font-size: 14px;
    }

    &-input {
      width: 50%;
    }

    &-half-w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 25px;
      border-bottom: solid 1px #d3d3d3;

      h1 {
        margin-bottom: 20px;
      }

      div {
        width: 50%;
        flex: 0 1 50%;
        margin-top: 15px;
      }
    }

    &-refresh {
      &-description {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 12px;
        color: #9c9c9c;
      }

      &-img {
        height: 20px;
        margin-right: 3px;
      }
    }

    &-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 130px;
      float: right;
    }

    &-rows {
      display: flex;
      flex: 0 1 50%;
      align-items: baseline;
    }
    &-link {
      margin-left: 30px;
      text-decoration: underline;
      color: #255eba;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
