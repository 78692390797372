.funded-score-history {
  &-w ~ &-w {
    margin-top: 17px;
  }

  &__header {
    background: $c-white-smoke;
    border-top: solid 1px $c-gray-light;
  }

  &__display {
    &-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
    }
    &-notes {
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      line-height: 14px;
      color: $c-gray-light;
    }
    &-updated {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    label {
      color: $c-gray;
      text-transform: uppercase;
      span {
        &::before {
          content: '';
          display: block;
        }
      }
    }

    &-col-name {
      display: flex;
      flex-direction: row;
      flex: 3;
      &-icon {
        cursor: pointer;
        padding-top: 5px;
        margin-right: 10px;
        width: 25px;
        svg {
          path {
            fill: $c-gray;
          }
        }
        &-active {
          svg {
            path {
              fill: $c-blue;
            }
          }
        }
      }
      &-notes {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        flex: 4;
      }
    }

    &-col-graph {
      flex: 5;
      margin-left: 20px;
      &-content {
        margin-top: 10px;
      }
    }

    &-col-toggle {
      text-align: center;
      margin-left: 20px;
      justify-content: flex-end;
      flex: 1;
      &-lock {
        cursor: pointer;
        margin-right: 20px;
        svg {
          path {
            fill: $c-blue;
          }
        }
      }
    }

    &-col-updated {
      text-align: right;
      justify-content: flex-end;
      flex: 1;
    }
  }

  &__item {
    &-col {
      flex: 3;
      flex-direction: column;
    }
  }
}
