.gb-item {
  height: 57px;
  box-shadow: 0 1px 0 0 #d2d2d2;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__icon {
    height: 24px;
    width: 24px;
    align-self: center;
    margin-left: 15px;
  }

  &__name {
    margin-left: 5px;
    color: $c-nero;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    align-self: center;
    flex-grow: 1;
  }

  &__date {
    height: 16px;
    color: $c-nero;
    font-size: 14px;
    line-height: 16px;
    margin-right: 74px;
  }

  &__actions {
    margin-right: 16px;
  }
}
