.folder-detail {
  &__btn {
    &--back {
      padding: 24px 0 10px 0;
      color: $c-icons;
      cursor: pointer;
      display: inline-flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        color: $c-active-btn;
      }
    }
  }
  &__name {
    flex: 5;
  }
  &__date-created {
    flex: 1;
    text-align: right;
  }
  &__actions {
    flex: 1;
    text-align: right;
  }
}
