.hcCardModal {
  &-w {
    padding: 20px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    display: flex;
    align-items: center;
    color: $c-nero;
    font-size: 14px;
  }

  &__item-img {
    margin-right: 15px;
    max-width: 30px;
  }
}
