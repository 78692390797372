.ivf-welcome {
  &__title-image {
    margin: 40px 0 10px;
    text-align: center;
  }
  &__title-text {
    text-align: center;
    font-size: 22px;
    line-height: 28px;
    color: $c-blue;
  }
  &__description-w {
    display: flex;
    flex-direction: row;
    margin: 40px 0;
  }
  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 240px;
    margin: 0 20px;
    &-text {
      font-size: 14px;
      color: $c-nero;
      line-height: 22px;
      text-align: center;
    }
    &-number {
      color: $c-blue-light;
      font-size: 16px;
      font-weight: $font-w-bold;
      line-height: 19px;
      height: 40px;
      width: 40px;
      border: 1px solid $c-green;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
    }
  }
  &__buttons {
    margin-bottom: 40px;
  }
}
