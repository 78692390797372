.task-row {
  display: flex;
  align-items: center;
  height: auto;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 0 8px 0 12px;
  margin-bottom: 8px;
  &__title {
    flex: 10;
    color: #231f20;
    font-size: 14px;
  }
  &__owner {
    flex: 3;
  }
  &__status-w {
    flex: 5;
  }
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 66px;
    border-radius: 4px;
    color: #ffffff;
    font-size: 10px;
    &--in-progress {
      background-color: #f49500;
    }
    &--completed {
      background-color: #8ccc33;
    }
  }

  &__due-date {
    display: flex;
    align-items: center;
    color: #231f20;
    font-size: 14px;
    flex: 3;
  }
  &__delete {
    text-align: end;
    flex: 2;
  }
}
