.document-name {
  color: #231f20;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: center;
  &__approved {
    margin-left: 16px;
    background-color: #8ccc33;
    border-radius: 4px;
    padding: 1px 6px;
    color: #ffffff;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
  &__date {
    color: #898a8d;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    padding-left: 4px;
  }
  span {
    padding: 0px 8px 0px 16px;
  }
  input {
    margin: 0px 8px 0px 16px;
  }
}
