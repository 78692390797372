.hc-draggable-row {
  &__wrapper {
    border: none;
    display: flex;
    flex-direction: row;
  }

  &__handle {
    position: relative;
    top: -5px;
    left: 5px;
    margin: auto 10px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
