.inv-exercise {
  padding: 20px 16px;

  &__key {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    &--current {
      span {
        opacity: 0.3;
      }
    }

    &:first-of-type {
      margin-right: 25px;
    }

    &-w {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 8px;
    }

    span:nth-of-type(1) {
      background-color: #9dd47d;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    span:nth-of-type(2) {
      background-color: #3386be;
    }

    span:nth-of-type(3) {
      background-color: #f6aa33;
    }

    span:nth-of-type(4) {
      margin-right: 10px;
      background-color: #ad6a99;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  &__score-priority {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 10px;
    color: white;
    background-color: #a2a4b0;
  }

  &__graph {
    position: relative;
    margin: 0 auto;
    width: 96px;
    height: 144px;
    background-color: $c-white-smoke;

    &-w {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      margin: 80px 0 30px;

      > div {
        text-align: center;
      }

      div h5 {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 8px;
          max-width: 20px;
          width: 100%;
        }
      }
    }

    span {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;

      > svg {
        position: absolute;
        left: -32px;
        top: -12px;
      }
    }

    &--pref {
      .inv-exercise__graph--target {
        background-color: #d1e8c7;

        &:before {
          border-color: #b0ed8c;
        }

        > svg {
          color: #9dd47d;
        }
      }
    }

    &--pro {
      .inv-exercise__graph--target {
        background-color: #a6c9de;

        &:before {
          border-color: #3b99d9;
        }

        > svg {
          color: #3386be;
        }
      }
    }

    &--cost {
      .inv-exercise__graph--target {
        background-color: #f6d6ae;

        &:before {
          border-color: #ffb845;
        }

        > svg {
          color: #f6aa33;
        }
      }
    }

    &--tax {
      .inv-exercise__graph--target {
        background-color: #d9bdd0;

        &:before {
          border-color: #c779b0;
        }

        > svg {
          color: #ad6a99;
        }
      }
    }

    &--target {
      position: relative;
      width: 100%;

      &:before {
        content: '';
        display: inline-table;
        position: absolute;
        opacity: 100;
        top: 0;
        left: -8px;
        right: 0;
        height: 2px;
        border-bottom: 2px dashed;
        width: 115%;
      }
    }

    &--current {
      width: 100%;
      z-index: 501;
    }
  }
}
