.action-row {
  border-radius: 4px;
  background-color: $c-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0;
  }
  &--record-view {
    margin-bottom: 8px;
    min-height: 44px;
    border: 1px solid #d3d3d3;
  }
  &__content-w {
    padding: 16px 22px 16px 16px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--record-view {
      min-height: 44px;
      padding: 6px 9px;
    }
  }
  &__icon {
    flex: 1;
  }
  &__name {
    color: $c-nero;
    font-size: 16px;
    font-weight: $font-w-bold;
    flex: 10;
    padding-right: 15px;
    &--record-view {
      flex: 8;
      font-size: 14px;
    }
  }
  &__owner {
    display: flex;
    flex: 2;
    &--record-view {
      padding: 0;
    }
    &--preview-view {
      justify-content: flex-end;
    }
  }
  &__owner-photo {
    border: 2px solid white;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    z-index: 200;
    &--overlap {
      position: relative;
      right: 10px;
      z-index: 100;
    }
  }
  &__status {
    display: flex;
    align-items: center;
    flex: 5;
    &--record-view {
      flex: 2;
      justify-content: center;
    }
  }
  &__status-text {
    font-size: 14px;
    color: $c-nero;
  }
  &__due-date {
    display: flex;
    align-items: center;
    color: $c-nero;
    font-size: 14px;
    flex: 3;
    &--record-view {
      flex: 2;
    }
  }
  &__calendar-icon {
    color: $c-gray-light;
  }
  &__buttons {
    flex: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--record-view {
      flex: 1;
    }
  }
  &__pencil {
    cursor: pointer;
    color: $c-gray;
    height: 18px;
    width: 18px;
  }
  &__delete {
    cursor: pointer;
    color: $c-gray;
    height: 18px;
    width: 18px;
  }
  &__arrow {
    cursor: pointer;
    height: 20px;
    width: 20px;
    transform: rotate(-90deg);
    transition: transform 300ms;
    &--hide {
      transform: rotate(90deg);
    }
  }
  &__comment-w {
    padding: 0 16px 16px 16px;
    color: $c-gray;
    font-size: 14px;
    letter-spacing: 0.3px;
  }
  &__task-w {
    padding: 16px;
    background-color: #e8e8e8;
    border-radius: 0 0 6px 6px;
  }
  &__task-null {
    color: #231f20;
    font-size: 14px;
    letter-spacing: 0.3px;
    margin-bottom: 8px;
  }
  &__plus-icon {
    margin: 0 6px;
  }
}
