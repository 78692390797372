.inv-accounts {
  &__modal {
    display: flex;
    justify-content: center;
    align-items: center;

    &-title {
      display: flex;
      font-size: 14px;
      margin: 0 0 15px 0;
      color: black;

      &--toggle {
        margin-left: 10px;
      }
    }

    > div {
      width: 50%;
      flex: 0 1 50%;

      &:first-of-type {
        margin-right: 25px;
      }

      label {
        display: block;
        margin-bottom: 5px;
      }

      input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #d2d2d2;
        border-radius: 6px;
      }
    }
  }
}
