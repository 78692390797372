.Header_Base {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 15px 65px;
  border: 1px solid #d2d2d2;
  margin-top: -1px;
}

.header {
  &__detail-w {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1;

    &-sub-header {
      display: flex;
      align-items: center;
      position: fixed;
      top: 140px;
      width: 100%;
      height: 35px;
      background-color: #f2f2f2;
      border: 1px solid #d2d2d2;
      z-index: 1;

      &-img {
        cursor: pointer;
        width: 25px;
        height: 25px;
      }

      &--right {
        position: fixed;
        right: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    position: fixed;
    right: 13px;
    display: flex;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 20px;
        margin-right: 10px;
      }

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    &-proposal {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #255eba;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      margin-right: 28px;

      img {
        padding-right: 8px;
      }
    }

    &-finish {
      a {
        cursor: pointer;
        color: #255eba;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
        border-radius: 4px;
        background-color: #f2f2f2;
        padding: 8px 16px;
      }
    }

    &-alt {
      cursor: pointer;
      display: flex;
      flex: 0.33;
      margin-left: auto;
      justify-content: space-between;
      align-items: center;

      a {
        display: flex;
        cursor: pointer;
        align-items: center;
        background-color: #f2f2f2;
        border-radius: 4px;
        height: 32px;
        width: 140px;
        line-height: 17px;
        font-size: 14px;
        font-weight: 500;
        color: #255eba;
        justify-content: center;

        img {
          margin-right: 4px;
        }
      }

      &__divider {
        height: 24px;
        width: 2px;
        background-color: #e3e3e3;
      }
    }
  }
}

.ic-chart {
  height: 24px;
  width: 24px;
}

.page-title {
  color: #231f20;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.pd {
  padding-left: 8px;
  padding-right: 16px;
  border-right: 2px solid #e3e3e3;
}

.pd1 {
  padding-left: 16px;
  padding-right: 4px;
}

.profile-name {
  margin-top: 5px;
  margin-left: 16px;
  font-size: 18px;
}

.finsih-save-button {
  color: #898a8d;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-right: 24px;
  cursor: pointer;
}

.form-active-btn {
  height: 32px;
  width: 137px;
  border-radius: 4px;
  background-color: #255eba;
  color: #ffffff;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  margin-right: 24px;
}

.finish-btns {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  height: 64px;
  width: 600px;
}

.finish-font {
  color: #231f20;
}
