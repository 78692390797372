.tos-modal {
  &__body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 20px;

    &-text {
      font-size: 15px;
      color: #231f20;

      &--link {
        color: #1029b9;
        text-decoration: underline;
      }
    }
  }

  &__btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px 20px 20px 0;
    justify-content: flex-end;

    &-text {
      font-size: 14px;
      font-weight: $font-w-bold;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}
