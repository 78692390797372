.im-donut {
  &__wrapper {
    margin-top: 20px;
    width: 180px;
    height: 180px;
  }

  &__label {
    position: absolute;
    width: 180px;
    height: 180px;
    font-size: 15px;
    text-align: center;
    line-height: 16px;
    padding-top: 82px;
    &-center {
      padding-top: 75px;
      span {
        &::before {
          content: '\A';
          white-space: pre;
        }
      }
    }
  }
}
