.gb-actions {
  position: fixed;
  right: 0;
  left: 42px;
  z-index: 800;
  max-height: 55px;
  padding: 15px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c2c2c2;
  transition: left 300ms;

  .household--nav-open & {
    left: 290px;
  }

  &__left-w {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo-text,
  &__client-name {
    font-size: 16px;
    color: $c-nero;
    margin-right: 5px;
  }

  &__logo {
    height: 24px;
    margin-right: 10px;
  }

  &__right-w {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    cursor: pointer;

    img {
      max-width: 24px;
    }

    &__divider {
      position: relative;
      padding: 12px 0;
      margin: 0 12px;

      &:after {
        content: '';
        position: absolute;
        height: 24px;
        width: 1px;
        top: 0;
        opacity: 0.4;
        background-color: #898a8d;
      }
    }
  }
}
