$blue: #255eba;
$orange: rgb(246, 170, 51);
$grey: #f0f1f3;
$circ: 440;

.proposed-portfolio {
  &__view-details {
    position: absolute;
    top: 10px;
    right: 20px;
    text-align: right;
    width: 100%;
    color: $blue;
    cursor: pointer;
    font-family: $font-main;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;

    &__content-wrapper {
      padding: 20px;

      .close-btn {
        text-align: right;
        margin: 10px 0px 0px;
      }
    }
  }

  &__ratio-wrapper {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;

    &-noPad {
      padding-bottom: 0px;
      width: 40%;
      position: absolute;
    }
  }

  &__ratio-data {
    color: #231f20;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    border-radius: 4px;
    background-color: #f2f5fa;
    padding: 3px 10px;
  }

  &__graph-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    &-padding {
      padding-bottom: 6px;
    }

    &-edit {
      color: #8a8888;
      text-align: right;
      padding-bottom: 14px;
    }
  }

  &__graph {
    display: flex;

    &__divider {
      width: 1px;
      height: 100%;
      background: #e5e5e5;

      &-wrapper {
        width: 9%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
      }
    }

    &-relative {
      position: relative;
      margin: auto;
    }

    &-data-point {
      &-flex {
        display: flex;

        &__column {
          margin-right: 25px;
        }
      }

      flex: 1;
      margin-left: 12px;
      white-space: nowrap;
      align-self: center;
    }

    &-padding {
      padding-right: 15px;
    }

    &-target {
      position: absolute;
      left: 50%;
      bottom: -17%;
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
      white-space: nowrap;

      span {
        color: #231f20;
        font-size: 16.9px;
        font-weight: 500;
        line-height: 20px;
      }

      p {
        color: #8a8888;
        font-size: 8px;
        font-weight: 500;
        letter-spacing: 0.56px;
        line-height: 10px;
      }
    }

    &__data-wrapper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 7px;
    }

    &-name {
      color: #231f20;
      font-size: 14px;
      line-height: 16px;
    }

    &-data {
      color: #231f20;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      text-align: right;
    }

    &-divider {
      flex: 1;

      div {
        height: 99px;
        width: 1px;
        background-color: #e5e5e5;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__no-data {
      flex-direction: column;
      align-items: center;

      &-heading {
        color: #255eba;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        padding-bottom: 5px;
      }

      &-desc {
        color: #231f20;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        padding-bottom: 8px;
        font-weight: 500;
      }

      &-btn {
        a {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          text-align: center;
        }
      }
    }
  }

  &__balance {
    text-align: center;
    color: #231f20;
    font-size: 14px;
    line-height: 17px;
    padding-top: 24px;
    padding-bottom: 5px;

    &-bold {
      font-weight: bold;
      padding: 0px 3px;
    }
  }

  &__progress-bar {
    height: 10px;
    border-radius: 2px;
    background-color: $grey;

    &-active {
      background: $blue;
      height: 100%;
      border-radius: 2px;
    }
  }

  &__graph-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
