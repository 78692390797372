.link-emoney {
  &-w {
    margin-top: 24px;
  }

  &__header {
    &-row {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 13px;
      .row-w {
        height: 50px;
      }
      &-w--extended,
      &-w {
        display: -webkit-flex;
        display: flex;
        padding: 12px 18px 11px;
        text-decoration: none;
        font-size: 12px;
        -webkit-align-items: center;
        align-items: center;
        background: $c-white-smoke;
        border-bottom: solid 1px $c-gray-light;
      }
      &-w--extended {
        padding: 12px 34px 11px 18px;
      }
    }
  }
  &__scroll-list {
    overflow-y: scroll;
  }
  &__row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    position: relative;
    &-w {
      display: flex;
      padding: 13px 16px;
      text-decoration: none;
      font-size: 12px;
      align-items: center;
      height: 50px;
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1px solid #d2d2d2;
      }
    }

    label {
      color: $c-gray;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: 0.005em;
      span {
        &::before {
          content: '';
          display: block;
        }
      }
    }

    &-col--ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 3px;
    }

    &-col-name {
      display: flex;
      flex-direction: row;
      flex-basis: 50%;
      align-items: center;
      overflow: hidden;
      align-items: center;
      min-width: 0;
      &--color {
        color: $c-col-name;
      }
    }

    &-col-email,
    &-col-fName,
    &-col-lName,
    &-col-cityState {
      display: flex;
      text-align: left;
      justify-content: flex-end;
      overflow: hidden;
      align-items: center;
      flex-basis: 160px;
      min-width: 0;
      padding-right: 10px;
    }

    &-col-email {
      flex-basis: 300px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: $font-w-bold;
    line-height: 1.71;
    letter-spacing: normal;
    color: $c-nero;
  }
  &__searchBox {
    display: flex;
    margin-top: 13px;
  }

  &__header-w {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    &-col {
      flex: 3;
      flex-direction: column;
    }
  }
  &__nullView {
    font-size: 16px;
    line-height: 24px;
    margin: 7px 0px 7px 18px;
  }

  &__placeholder {
    &-w {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid $c-gray-light;
    }

    &-item {
      span {
        height: 10px;
        width: 80px;
        background-color: $c-gray-light;

        &:last-child {
          padding: 10px 0;
          width: 100%;
        }
      }

      &-column {
        display: flex;
        flex-direction: column;
      }

      &-row {
        display: flex;
        width: 43.7%;
        justify-content: space-between;
      }
    }
  }
  &__error-row {
    margin: 15px 0px 5px 0px;
  }
  &__error-title {
    line-height: 16px;
  }
  &__error-note {
    margin-top: 20px;
  }
  &__list {
    margin-left: 16px;
    &-item {
      list-style: disc;
      line-height: 16px;
    }
  }
}
