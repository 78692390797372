.pal__null {
  width: 100%;
  height: 10px;
  background-color: #e4e4e4;

  &--row {
    padding: 20px 16px !important;
  }

  &--name {
    max-width: 200px;
  }
  &--status,
  &--checkin {
    max-width: 120px;
  }

  &--hc {
    max-width: 0;
  }

  &--notes {
    max-width: 30px;
  }

  &--active {
    max-width: 50px;
  }
}
