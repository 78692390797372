.react-select {
  &__control {
    background-color: white !important;
    font-size: 14px;
    height: 36px;
    min-height: unset !important;
  }
  &__single-value {
    overflow: unset !important;
  }
  &__indicator-separator {
    display: none;
  }
  &-Error {
    > div:first-child {
      border-color: red;
    }
  }
}
