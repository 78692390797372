.search {
  &__w {
    width: 270px;
    border: 1px solid $c-gray-light;
    border-radius: 6px;
    align-items: center;
    display: flex;
    flex-direction: row;
    color: $c-icons-enabled;
    background-color: $c-white;
    overflow: hidden;
  }

  &__icon {
    margin-left: 8px;
  }

  &__input {
    font-size: 14px;
    line-height: 16px;
    color: $c-icons;
    border: none;
    flex: 1;
    padding: 10px 8px;
  }
}
