.gb-cover {
  background: url('../../../../images/guidebook/guidebook-cover-bg.png');
  min-height: 1040px;
  margin-bottom: 40px;
}

.gb-cover__logo-w {
  max-width: 350px;
  margin-left: 100px;
  min-height: 125px;
}

.gb-cover__logo--UC-logo {
  max-width: 100%;
  width: 100%;
}

.gb-cover__title {
  font-size: 48px;
  color: #255eba;
  font-family: sans-serif;
  font-weight: lighter;
  margin-top: 90px;
  margin-bottom: 60px;
  margin-left: 95px;
}

.gb-cover__footer {
  padding: 60px 95px;
  min-height: 225px;
}

.gb-cover h3 {
  font-family: sans-serif;
  font-size: 26px;
  margin-bottom: 25px;
}

.gb-cover span {
  font-family: sans-serif;
  display: block;
  color: #231f20;
  font-size: 16px;
  margin-top: 9px;
}
