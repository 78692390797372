.error {
  &-w {
    margin-top: 130px;
    margin-left: 220px;
    max-width: 663px;
    @media screen and (min-width: $media-xsmall-width) and (max-width: $media-small-width) {
      margin-left: 24px;
      margin-right: 24px;
    }

    @media screen and (min-width: $media-small-width) and (max-width: $media-medium-width) {
      margin-left: 52px;
      margin-right: 52px;
    }
  }
  &-statuscode {
    font-size: 46px;
    color: #255eba;
    font-weight: 500;
    letter-spacing: 0.005em;
  }
  &-title {
    font-size: 24px;
    line-height: 32px;
    margin-top: 40px;
    margin-bottom: 32px;
    font-weight: 500;
  }
  &-message {
    margin-bottom: 32px;
    line-height: 28px;
    max-width: 620px;
    a {
      color: #255eba;
      text-decoration: underline;
      margin: 0 5px;
      font-weight: 500;
    }
  }
  &-outage-info {
    margin-top: 26px;
    font-size: 14px;
    a {
      color: #255eba;
      text-decoration: underline;
    }
  }
}
