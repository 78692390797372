.message-null {
  &-w {
    display: flex;
    align-items: center;
    justify-content: center;

    &--message-page {
      flex: 3;
      text-align: center;
    }

    &--dashboard {
      margin-top: 78px;
      flex-direction: column;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 300px;

    &--error {
      width: unset;
    }
  }

  &-text {
    padding: 6px 12px 12px;
    color: #231f20;
    text-align: center;
    font-size: 14px;
    line-height: 16px;

    &__blue {
      color: $c-blue;
      padding: 12px 12px 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
