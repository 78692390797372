@import 'partnerLogin';
@import 'accounts';
@import 'editAccountModal';
@import 'assetsAllocation';
@import 'exerciseGraph';
@import 'investmentHistory';
@import 'assetAllocationNull';
@import 'exerciseModal';
@import 'marketplace';
@import 'ndsModal';
@import 'disabledModal';

.investment {
  &__inv-priorities {
    &-w {
      display: flex;
    }
    &-toggle-arrow {
      cursor: pointer;
      margin-left: 15px;
      &--up {
        transform: rotate(90deg);
      }
      &--down {
        transform: rotate(270deg);
      }
    }
  }
  &__allocation {
    &-w {
      margin-top: 18px;
    }

    &-amount {
      font-size: 18px;
      font-weight: $font-w-bold;
    }
  }

  &__accounts {
    &-w {
      margin-top: 18px;
    }

    &-amount {
      font-size: 18px;
      font-weight: $font-w-bold;
    }
  }

  &__partner {
    &-w {
      margin-top: 18px;
    }

    &-amount {
      font-size: 18px;
      font-weight: $font-w-bold;
    }
  }
  &__proposal {
    &-modal-cont {
      padding: 0 32px;
      margin-bottom: 32px;
      font-size: 16px;
    }
    &-btn-cont {
      padding: 32px 0;
    }
  }
}
