.c-settings {
  &__optionality {
    &-h {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
    &-text {
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d2d2d2;
    }
    &-btns {
      margin-top: 56px;
      display: flex;
      justify-content: flex-end;
    }
    &-modal {
      padding: 24px 24px 16px 24px;
      &-text {
        font-size: 14px;
        line-height: 20px;
        color: $c-nero;
      }
    }
    .a-settings {
      &__row {
        &--content {
          padding: 16px 0px;
        }
        &-toggle {
          &-w {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.toggle-caption {
  display: flex;
  align-items: center;
}
