.strategy-search {
  &__tags {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
  &__applied {
    cursor: pointer;
    min-height: 30px;
    background: #d7e7f6;
    padding: 0px 8px;
    line-height: 30px;
    border-radius: 3px;
    margin-top: 10px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  &__remove {
    vertical-align: middle;
    padding-left: 6px;
  }
}
