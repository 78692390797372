@import 'settingsBilling';
@import 'settingsContactInfo';
@import 'settingsHouseholdInfo';
@import 'settingsOptionality';
@import 'costOfServicesModal';
@import 'settingsLoginHistory';

.c-settings {
  width: calc(100% - 260px);
  padding: 24px;

  &-w {
    display: flex;
    min-height: 630px;
  }

  &__menu {
    width: 260px;
    border-right: 1px solid $c-gray-light;

    &-label {
      display: block;
      margin-bottom: 15px;
    }

    &-item,
    &-item--list {
      position: relative;
      cursor: pointer;
      padding: 15px;
      font-weight: $font-w-bold;
      border-bottom: 1px solid $c-gray-light;
      background-color: white;
      box-shadow: inset 0px 0 0 0 $c-blue;
      transition: background-color 300ms, box-shadow 300ms;

      &:hover {
        background-color: $c-white-smoke;
      }

      &--active {
        background-color: $c-white-smoke;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          height: 48px;
          width: 2px;
          background-color: $c-blue;
        }
      }
    }

    &-item--list {
      &:hover {
        cursor: unset;
      }
    }
  }

  &__contact-list {
    margin-left: 15px;

    &-name--active,
    &-name:hover {
      text-decoration: underline;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-weight: $font-w-light;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &-icon {
      font-size: 10px;
      padding: 3px 6px;
      border-radius: 4px;
      color: white;

      &--primary {
        background-color: $c-blue;
      }
      &--secondary {
        background-color: #a2a4b0;
      }
    }
  }
}
