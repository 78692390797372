.react-datepicker__input-container {
  input {
    cursor: pointer;
    width: 90%;
    font-weight: $font-w-light;
    font-size: 14px;
    background-color: transparent;
    border: none;
  }
  input[placeholder] {
    font: $font-main;
    font-size: 14px;
    width: 100%;
  }
}

.react-datepicker__header {
  background-color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 50%;
  background-color: $c-blue;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: $c-nero;
  font-weight: $font-w-bold;
}
.react-datepicker__month-container {
  border-bottom: 1px solid #d2d2d2;
}
.react-datepicker-popper {
  z-index: $tooltip-zindex;
  &__remove-date {
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px 10px 10px;
    &__text {
      font-size: 14px;
      color: #255eba;
      cursor: pointer;
    }
  }
}
