.team-management__modal {
  &-body {
    display: flex;
    padding: 22px 0;
    margin: 22px 0;

    &-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 68px;
      height: 68px;
      border-radius: 50%;
      border: 2px dashed #d2d2d2;
      margin: 0 27px;

      &--placeholder {
        display: block;
        width: 48px;
        height: 48px;
      }

      &--real {
        display: block;
        width: 68px;
        height: 68px;
        border-radius: 50%;
      }
    }

    &__input {
      height: 36px;
      width: 550px;
      margin-left: 25px;

      p {
        margin: 3px 0;
        color: #231f20;
      }

      .input {
        width: 100%;
        height: 36px;
        font-size: 14px;
        border: 1px solid #d2d2d2;
        border-radius: 4px;
        padding: 10px;
      }
    }

    &__search {
      margin: 8px 0 8px 16px;
    }
  }

  // &-body ends here

  &-text {
    margin: 0 24px;
    color: $c-nero;
  }

  &-noresult {
    margin: 48px 178px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-text {
      font-size: 16px;
      opacity: 1;
      font-weight: bold;

      &--blue {
        color: $c-blue;
      }

      &--gray {
        color: $c-nero;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        opacity: 0.5;
      }
    }

    p {
      margin-top: 12px;
      text-align: center;
    }
  }

  &-btns-w {
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px;
    margin-top: 60px;
  }

  &__no-border {
    border: none;
  }

  &__noresult {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 178px;
    margin-top: 48px;

    p {
      margin-top: 12px;
      color: #231f20;
      opacity: 0.5;
    }
  }
}
