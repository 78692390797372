.insurance-policy-modal {
  min-height: 230px;

  p {
    margin: 10px auto;
    color: $c-nero;
  }

  &__content {
    padding: 24px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px;
  }

  &__account-type {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    &-customselect {
      width: 100%;
    }
    &-h {
      color: $c-nero;
      font-size: 20px;
      line-height: 1.3;
      margin-top: 5px;
    }

    label {
      color: $c-nero;
      font-size: 14px;
      margin-bottom: 3px;
      line-height: 1;
    }

    select {
      display: block;
      padding: 10px;
      border: 1px solid $c-gray-light;
      height: 36px;
      font-size: 14px;
    }
  }

  &__account-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid $c-gray-light;
    margin-top: 35px;
    padding-top: 25px;
    > div {
      flex: 0 1 48%;
      display: flex;
      flex-direction: column;
      margin: 0px 0px 15px 0px;
      label {
        color: $c-nero;
        font-size: 14px;
        margin-bottom: 3px;
        line-height: 1;
      }
      ::before {
        bottom: -7px;
      }
    }
    &-input-error {
      border: solid 1px $c-red-dark;
    }
    &-error {
      font-size: 12px;
      font-style: italic;
      color: $c-red-dark;
    }
    &-required {
      cursor: pointer;
      a {
        font-weight: bold;
      }
      ::after {
        content: '*';
        color: $c-red-dark;
      }
    }
    &-customselect {
      max-width: 261px;
      padding-bottom: 16px;
      label {
        margin-bottom: -3px;
      }
    }
  }
}
