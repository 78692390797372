.hc-meeting {
  &__container-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-tab {
    font-size: 14px;
    font-weight: $font-w-bold;
    line-height: 17px;
    color: $c-nero;
    padding: 12px 16px;

    &:hover {
      cursor: pointer;
    }

    &-w {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 40px;
      background-color: #f5f5f5;
      border-top: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
      padding: 0 24px;
    }

    &--active {
      color: $c-active-border;
      border-bottom: 1px solid $c-active-border;
    }
  }

  &__active-badge {
    display: flex;

    &:after {
      content: 'Active';
      background-color: $c-green;
      color: white;
      font-size: 14px;
      border-radius: 4px;
      padding: 4px 10px;
    }
  }

  &__sub-header {
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--scores {
      display: flex;
      padding: 12px 24px 0px;
      justify-content: flex-end;
    }

    &--title {
      font-size: 20px;
      font-weight: $font-w-bold;
      line-height: 24px;
      color: $c-nero;
      &-names {
        display: flex;
        flex: 1;
        margin-left: 75px;
        flex-direction: row;
        &-w {
          margin-left: 20px;
        }
      }
      &-name {
        width: 100px;
        text-align: center;
        margin-right: 20px;
      }
      &-name-w {
        width: 50%;
      }
    }

    &--edit {
      font-size: 14px;
      font-weight: $font-w-bold;
      line-height: 17px;
      color: $c-blue;
      display: flex;
      align-items: center;
      cursor: pointer;
      &-cards {
        padding: 12px 24px 0px;
        display: flex;
        justify-content: flex-end;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__content-header {
    margin-top: -40px;
    width: 600px;
  }

  &__record--container {
    padding-left: 20px;
  }

  &__quick-action-w {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__quick-actions {
    margin-right: 20px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    &-links {
      padding-top: 18px;
      a {
        cursor: pointer;
        text-decoration: underline;
        padding-left: 4px;
      }
    }
  }

  &__left-header {
    font-size: 14px;

    &-w {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      font-weight: $font-w-bold;
    }
  }

  &__button-w {
    display: flex;
    padding: 20px 35px;
    justify-content: flex-end;
  }

  &__card-select-title {
    font-size: 14px;
    font-weight: $font-w-bold;
    line-height: 17px;
    color: $c-nero;
    margin-left: 8px;
  }
}
