.action-dropdown {
  &__w {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 24px;
    img {
      max-width: 24px;
    }
  }

  &__menu {
    position: absolute;
    z-index: 900;
    min-width: 180px;
    top: 32px;
    font-weight: 300;
    font-size: 14px;
    background-color: white;
    border-radius: 6px;
    border: solid $c-borders 1px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

    .action-dropdown__w--left & {
      left: -12px;

      &:after {
        left: 11px;
      }
      &:before {
        left: 10px;
      }
    }

    .action-dropdown__w--right & {
      right: -12px;

      &:after {
        right: 11px;
      }
      &:before {
        right: 10px;
      }
    }

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      top: -19px;
      border-color: transparent transparent white transparent;
      border-width: 10px;
    }

    &:before {
      top: -22px;
      border-color: transparent transparent #d2d2d2 transparent;
      border-width: 11px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 7px 12px;
    cursor: pointer;
    min-height: 45px;

    &--hover-disabled {
      &:hover {
        > svg path {
          fill: $c-gray;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2;
    }

    &:hover {
      text-decoration: underline;
    }

    img {
      max-width: 24px;
    }

    &-label {
      width: 100%;
      text-align: left;
      margin-left: 15px;
      color: $c-nero;
      cursor: pointer;
      text-decoration: none;

      &--selected {
        color: $c-active-btn;
      }
    }

    &--link {
      display: flex;
      align-items: center;
      margin: 0;

      &:hover {
        > svg path {
          fill: $c-blue;
        }
      }
    }
  }
}
