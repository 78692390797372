$c-name: '.row-w';

#{$c-name} {
  display: flex;
  padding: 13px 16px;
  text-decoration: none;
  font-size: 12px;
  align-items: center;

  &--hover {
    &:hover {
      background-color: $c-background-hover;
      cursor: pointer;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $c-borders;
  }
}
