@import 'hcGraph';
@import 'hcCard';
@import 'hcComment';
@import 'hcEditCommentModal';
@import 'hcCardCommentModal';
@import 'hcHistory';
@import 'hcMeeting';
@import 'hcNewMeeting';
@import 'hcCardRow';
@import 'hcScoreHistory';
@import 'hcScoring';
@import 'hcNullState';
@import 'hcScoringEmailModal';
@import 'hcNullStateRedo';
@import 'hcSendLinkModal';
@import 'hcNoCombinedCards';
@import 'hcPersonalPriorityTile';
@import 'sharedPriorityComments';
@import 'hcRevealCards/index.scss';
@import 'hcPrioritizationEmailModal';
//
@import './exercise/index';
@import './scoreHistory/index';
@import 'actionForm';
@import './results/index';
@import 'satisfaction';
@import 'sharedPriorityComments';
@import 'scoreSelector';
@import 'recordForm';
@import 'sharedPriorityActions';
@import 'actionRow';
@import 'scoreCommentTextArea';
@import 'draggableRow';
@import 'taskRow';
@import 'addTaskModal';
@import 'scorePreview';
@import 'editScoresModal';
@import 'deleteActionModal';
@import 'disabledView';

.hc {
  &__row {
    display: flex;
    margin: 26px 16px;

    &:not(:last-child):not(:first-child) {
      border-bottom: 1px solid #d2d2d2;
      padding-bottom: 26px;
    }

    &--col-sml {
      min-width: 275px;
    }

    &--col-lrg {
      width: 100%;
    }

    &--col-lrg + &--col-lrg {
      margin-left: 50px;
    }
  }

  &__ideal-date {
    h4 {
      color: $c-nero;
      font-weight: $font-w-bold;
      margin-bottom: 5px;
    }

    span {
      color: $c-nero;
      font-size: 12px;
    }
  }

  &__score-null {
    &-w {
      display: flex;
    }

    &-container {
      height: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cards {
      padding: 25px;
    }

    &-message {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &-line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background: #d2d1d2;

      &--none {
        margin: 20px 0;
        background: white;
      }
    }
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__ideal-zone-modal-text {
    margin-bottom: 12px;
  }
}

// TODO TEMP V1Styles delete with v1 removal
.hc-modal {
  p {
    margin: 10px auto;
    color: $c-nero;
  }

  &__content {
    padding: 24px;
  }

  &__header {
    &-tab {
      cursor: pointer;
      font-size: 14px;
      font-weight: $font-w-bold;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100px;
      width: 100%;
      top: 5px;
      position: relative;
      padding-bottom: 10px;

      h4 {
        font-size: 14px;
        margin-right: 20px;
        font-weight: $font-w-bold;
      }
    }

    &-selected {
      color: $c-blue-dark !important;
      border-bottom: 2px solid $c-blue-light;
    }
  }

  &__account-border {
    padding-bottom: 10px;
    border-bottom: 1px solid $c-gray-light;
  }

  &__email {
    &-box {
      height: 350px;
      border: 1px solid $c-gray-light;
      margin-top: 10px;
      overflow: scroll;
    }

    &-w {
      font-size: 11px;
      line-height: 14px;
      padding: 0 30px;
      color: #696969;
    }

    &-w p {
      margin-top: 10px;
      color: #696969;
    }

    &-w p.name-label {
      margin-top: 20px;
    }

    &-w span {
      font-weight: 500;
      color: #696969;
    }

    &-w a {
      color: #15c;
      text-decoration: underline;
    }

    &-image {
      width: 250px;
    }

    &-header {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__row {
    color: $c-nero;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    line-height: 18px;
    padding: 2px 0;

    &-label {
      width: 100px;
    }

    &-value {
      font-weight: $font-w-bold;
    }
    &-error {
      font-weight: $font-w-bold;
      color: $c-red-bright;
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;

    &-w {
      margin: 0 20px;
      width: 50%;
    }

    &-w.launch {
      margin: 0 10px 0 0;
      white-space: nowrap;
    }

    &-text {
      font-size: 14px;
      font-weight: $font-w-bold;
      line-height: 17px;
      margin-left: 5px;
    }
  }

  &__btn .btn {
    width: 100%;
  }

  &__copy {
    position: relative;
    top: -7px;
    left: 5px;
    font-weight: $font-w-bold;
    font-size: 14px;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-right: 25px;
    width: 100%;
    color: $c-blue;
  }

  &__account-type {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    &-h {
      color: $c-nero;
      font-size: 20px;
      line-height: 1.3;
      margin-top: 5px;
    }

    label {
      color: $c-nero;
      font-size: 14px;
      margin-bottom: 3px;
      line-height: 1;
    }

    select {
      display: block;
      padding: 10px;
      border: 1px solid $c-gray-light;
      height: 36px;
      font-size: 14px;
    }
  }
}
// TODO TEMP V1Styles delete with v1 removal
