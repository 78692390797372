/* Tabs override */

.react-tabs {
  &__tab-list {
    height: 40px;
    background-color: #f5f5f5;
    box-shadow: 0 1px 0 0 #e5e5e5;
    padding: 0px 10px;
    display: flex;
    border: unset;
    margin: 0px;
    border-top: 1px solid #d2d2d2;
  }

  &__tab {
    color: #231f20;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    padding-bottom: 8px;
  }

  &__tab--selected {
    color: #255eba;
    background: unset;
    border: unset;
    border-bottom: 2px solid #255eba;
    padding-bottom: 6px;
  }
}
