@import 'syncModal';
@import 'integrations';
@import 'teamManagement';
@import 'editTeamScreen';
@import 'addToTeamModal';
@import 'transferList';

.admin-settings {
  &__row-w {
    display: flex;
    flex-direction: column;
    min-height: 620px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid $c-gray-light;

    font-size: 14px;

    &--btns {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;
    }

    &--col {
      padding: 16px 20px;

      > *:first-child {
        display: flex;
        align-items: center;
        max-width: 390px;
        width: 100%;
        margin-right: 10px;
      }

      > *:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      h5 {
        margin-left: 15px;
        font-weight: $font-w-bold;
      }

      &-h {
        background-color: $c-white-smoke;
        color: $c-gray;
        font-size: 11px;
      }
    }

    &--cols {
      padding: 16px 20px;

      > * {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 215px;
      }

      > *:first-child {
        display: flex;
        align-items: center;
        max-width: 325px;
        width: 100%;
        margin-right: 10px;
      }

      > *:last-child {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100px;
      }

      h5 {
        margin-left: 15px;
        font-weight: $font-w-bold;
      }

      &-h {
        background-color: $c-white-smoke;
        color: $c-gray;
        font-size: 11px;
      }

      svg {
        width: 16px;
        cursor: pointer;
        vertical-align: middle;
        padding-bottom: 4px;

        path {
          fill: $c-gray;
        }
      }
    }
  }

  &__heading {
    font-size: 16px;
    color: $c-nero;

    span {
      color: $c-gray;
      font-size: 12px;
      display: block;
      margin: 16px 0 8px;
    }
  }

  &__team-name {
    padding-bottom: 5px;
    color: black;
  }

  &__team-email {
    padding-left: 15px;
    font-size: 13px;
    color: $c-gray;
    max-width: 100%;
    word-break: break-all;
  }

  &__team-title {
    color: $c-gray;
    font-weight: $font-w-bold;
  }

  &__team-delete {
    color: $c-gray;
    cursor: pointer;
  }

  &__team-delete:hover {
    color: $c-blue;
  }

  &__team-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px dashed $c-gray-light;
    color: $c-gray-light;
  }

  &__teams {
    display: flex;
    align-items: center;
    position: absolute;
  }

  &__team-member-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white;
    color: $c-gray-light;
    background-color: white;

    &.more {
      color: $c-gray;
    }
  }

  &__teams-w {
    display: flex;
    align-items: center;
    position: relative;
    height: 34px;
  }
}
