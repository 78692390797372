@import 'header';
@import 'welcome';
@import 'footer';
@import 'attributes';
@import 'priorityQuestion';
@import 'geographicPriority';
@import 'checkmark';
@import 'allocationDescription';
@import 'targetAllocation';
@import 'results';
@import 'priorityBar';
@import 'answerSummary';
@import 'updateAnswerModal';

.ivf {
  &-w {
    min-height: 100vh;
    padding-bottom: 60px;
  }
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 880px;
    margin: 40px auto;
  }
}
