.strategy-search__item {
  background: white;

  &-display {
    font-size: 16px;
    margin-top: 25px;
    &--fee {
      text-align: right;
    }

    &--name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 20px;
      min-height: 20px;
    }
  }

  h5 {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #676879;
  }

  &-col {
    display: flex;
    flex-direction: column;
    padding: 0px 25px 0px 25px;
    transition: all 300ms;
    &:hover {
      box-shadow: inset 1px 0px 0px 0px black;
    }
    &--expand {
      box-shadow: inset 1px 0px 0px 0px black;
      border-bottom: 1px solid lightgrey;
    }
  }

  &-null {
    display: flex;
    flex-direction: column;
    padding: 0px 25px 0px 25px;
    text-align: center;
    box-shadow: none;
    &--title {
      font-size: 24px;
      line-height: 32px;
      padding-top: 80px;
    }
    &--subtitle {
      font-size: 16px;
      line-height: 24px;
      padding-top: 12px;
    }
    &--clear {
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      color: #2178c4;
      padding: 20px 0 80px;
    }
  }

  &-details {
    cursor: pointer;
    height: 88px;
    display: flex;
    flex-direction: row;
    padding: 10px 0px 10px;
  }

  &-additional {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 0 25px;
    &--expand {
      display: flex;
    }
  }

  &-table {
    font-size: 16px;
    line-height: 24px;
    &--row {
      max-height: 40px;
      padding-bottom: 20px;
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &--item {
      margin-top: 16px;
    }
    &--custodian {
      width: 55%;
    }
    &--abp {
      flex-grow: 1;
    }
  }

  &-list {
    text-align: left;
    color: #22263f;
    span {
      line-height: 24px;
      height: 24px;
      font-size: 16px;
      &:empty:before {
        content: '\200b';
      }
    }
    &--item {
      span {
        display: table;
        padding: 4px 0px 4px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  &-collateral {
    color: #2178c4;
    overflow-y: hidden;
    max-height: 180px;
    transition: ease 300ms;
    margin-top: 5px;
    &--expand {
      max-height: 340px;
      overflow-y: scroll;
    }
  }

  &-custodian {
    min-width: 470px;
  }

  &-expandIcon {
    cursor: pointer;
    vertical-align: middle;
    &--rotate {
      transform: rotate(180deg);
    }
    transition: transform 300ms;
  }
}
