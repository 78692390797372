.loader {
  &__container {
    position: fixed;
    bottom: 20px;
    width: auto;
    left: 20%;
    transform: translateX(-50%);
    z-index: 800;
  }

  &__overlay {
    padding: 10px 20px;
    width: 100%;
    height: 50;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__syncloader {
    display: flex;
    width: 381px;
    border: 1px solid $c-gray-light;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
    &-spinner {
      img {
        width: 64px;
      }
      padding: 14px;
    }
    &-loader {
      flex: 2;
      padding: 24px 0px;
      &-action {
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: $c-blue;
      }
      &-description {
        color: $c-nero;
        line-height: 19px;
        font-size: 14px;
      }
    }
    &-bottom-btn {
      margin-top: 5px;
      color: $c-blue;
      cursor: pointer;
      a {
        text-decoration: underline;
        text-decoration-color: $c-blue;
      }
    }
    &-close {
      padding-top: 8px;
      padding-right: 8px;
      & svg {
        path {
          fill: $c-gray;
        }
      }
    }
  }
}
