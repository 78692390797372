$theme: #255eba;
$inactive: transparentize(#b8b8b8, 0.4);
$disabled: transparentize(#b8b8b8, 0.35);

.checkbox {
  &-w {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--no-toggle-allowed {
      cursor: default;
    }
  }

  &__label {
    font-size: 14px;
    color: $c-nero;
    width: 100%;
    transition: color 200ms;

    &--blue {
      color: $c-blue;
    }
  }

  &__tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 20px;
    width: 20px;

    .tooltip {
      line-height: 0;
    }
  }

  &__input {
    display: none;
  }

  &__check {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $inactive;
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    margin-right: 10px;
    &-rounded {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $inactive;
      background-color: white;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      border-radius: 50%;
      flex-shrink: 0;
      &--checked {
        border-color: #2178c4;
        background-color: #2178c4;
        &:after {
          content: '';
          background-image: url(../../images/icons/checkmark-thick.svg);
          background-color: #2178c4;
          background-size: 100%;
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: contain;
        }
        &:hover {
          border-color: #2178c4;
        }
      }
      &--grayed {
        border-color: white;
        background-color: #e5e5e5;
        &:after {
          content: '';
          background-image: url(../../images/icons/checkmark-thick.svg)
            no-repeat 0 0;
          // need to find specific gray
          background-color: #e5e5e5;
          background-size: 100%;
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: contain;
        }
        &:hover {
          border-color: #e5e5e5;
        }
      }
    }

    &:after {
      content: '';
      background: url(../../images/icons/general/check.png) no-repeat 0 0;
      background-size: 100%;
      display: inline-block;
      width: 16px;
      height: 16px;
      transform: scale(0);
      transition: transform 200ms;
    }

    &--checked {
      &:after {
        content: '';
        background: url(../../images/icons/general/active_check.png) no-repeat 0
          0;
        background-size: 100%;
        transform: scale(1);
      }
    }

    &--no-toggle-allowed {
      opacity: 0.3;
    }

    &--no-border {
      border: none;
    }
  }
}
