.hc {
  &__graph-w {
    // accounts for ideal zone icon
    padding-left: 40px;

    @media ($media-medium) {
      padding-left: 0;
    }
  }

  &__graph {
    position: relative;
    background-color: #f5f5f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 140px;
    border-radius: 4px;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;

    &-name {
      color: $c-nero;
      font-size: 20px;
      margin-bottom: 35px;
      text-align: center;
    }

    &-ideal {
      position: absolute;
      z-index: 501;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #93ebff38;
      width: 100%;
      height: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      transition: height 300ms;

      cursor: pointer;
      color: $c-blue;
      font-size: 10px;
      font-weight: $font-w-bold;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -15px;
        right: 0;
        height: 3px;
        border-bottom: 3px dashed $c-gray;
      }

      &:before {
        content: url('../../../images/icons/ic_target.png');
        position: absolute;
        left: -40px;
        bottom: -11px;
      }
    }

    &-score {
      display: flex;
      z-index: 500;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 0;
      margin-top: auto;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      transition: height 300ms;

      color: white;
      font-size: 36px;
      font-weight: $font-w-bold;
    }
  }
}
