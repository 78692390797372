.inv-allo {
  padding: 20px 16px;

  &__head {
    &-w {
      display: flex;
      justify-content: space-between;
    }
  }

  &__toggle {
    display: flex;
    justify-content: center;
    font-weight: $font-w-bold;

    > div {
      margin-right: 10px;
    }
  }

  &__toggle-max {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  &__graph {
    &-w {
      padding: 20px 0;
      display: flex;
    }

    &-doughnut {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 150px;

      &:first-of-type {
        border-right: 1px solid $c-gray-light;
      }

      &-data {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: $font-w-bold;
        margin-bottom: 17px;

        &-edit {
          cursor: pointer;
          position: absolute;
          right: 5px;
          color: $c-gray;
        }
      }

      &-text {
        margin-top: 20px;
        color: $c-gray;
        font-size: 11px;
        text-align: center;
      }
    }

    &-bar {
      width: 100%;
      display: flex;

      &--equity {
        background-color: $c-blue;
      }

      &--fix {
        background-color: $c-yellow;
      }

      &--cash {
        background-color: #ffde8c;
      }

      &--alt {
        background-color: #a2d134;
      }

      &--unclass {
        background-color: #8a8888;
      }

      > div {
        border-radius: 2px;
        height: 100%;
      }

      > span {
        margin-left: 8px;
        font-size: 14px;
      }

      &-w {
        display: inline-flex;
        width: calc(100% - 300px);
        padding-left: 35px;
        flex-direction: column;
      }

      &--label,
      &--data {
        display: flex;

        > *:first-child {
          width: 100%;
          max-width: 145px;
          margin-right: 5px;
        }

        > *:nth-child(2) {
          width: 100%;
          max-width: 345px;
          margin-right: 5px;
        }

        > *:nth-child(3) {
          text-align: right;
          min-width: 100px;
          width: calc(100% - 490px);
        }
      }

      &--label {
        font-weight: $font-w-bold;
        font-size: 14px;
        padding-bottom: 8px;
        border-bottom: 1px solid $c-gray-light;
      }

      &--data {
        padding: 8px 0;
        font-size: 14px;

        &:not(:last-child) {
          border-bottom: 1px solid $c-gray-light;
        }
      }
    }
  }
}
