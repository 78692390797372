$c-name: '.tile';

#{$c-name} {
  &-w {
    width: 100%;
    border: 1px solid $c-gray-light;
    border-radius: 6px;
    background-color: #ffffff;
    overflow: visible;
  }

  &__header {
    // Do not apply a background-color
    // use headerStyle prop if needed
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    min-height: 50px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: $font-w-bold;
    align-self: stretch;
    position: relative;

    &--border {
      border-bottom: 1px solid $c-gray-light;
    }

    &--large-text {
      font-size: 18px;
      padding: 0 8px;
    }

    &--anchor {
      position: relative;

      &:before {
        content: '';
        width: 2px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $c-blue;
      }

      img {
        position: relative;
        left: 0;
        transition: left 300ms;
      }

      &:hover img {
        left: 5px;
      }
    }

    &-label {
      display: flex;
      align-items: center;

      > svg {
        margin-right: 10px;

        path {
          fill: $c-gray;
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      color: $c-blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      > svg {
        path {
          fill: $c-blue;
        }
      }
    }

    &-left {
      display: flex;
      align-items: center;
      order: 1;
      color: $c-titles;
      font-weight: $font-w-bold;
    }

    &-middle {
      display: flex;
      align-items: center;
      justify-content: center;
      order: 1;
      color: $c-titles;
      font-weight: $font-w-bold;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      order: 2;
    }

    &-icon {
      margin-right: 5px;

      &--png {
        max-width: 40px;
        margin-right: 2px;
      }
    }
  }
}

#{$c-name}-link {
  display: flex;
  justify-content: flex-end;

  > div {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: $c-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    margin-right: 10px;

    &:hover {
      text-decoration: underline;
    }

    path {
      fill: $c-blue;
    }
  }
}

#{$c-name}-action {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    cursor: pointer;
    transition: color 300ms;

    &:hover {
      text-decoration: underline;
    }

    svg {
      color: $c-blue;
    }
  }

  &__menu {
    position: absolute;
    z-index: 500;
    display: none;
    width: 132px;
    left: 15px;
    top: 35px;
    font-size: 14px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid #d2d2d2;

    &--show {
      display: block;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      top: -19px;
      left: 11px;
      border-color: transparent transparent white transparent;
      border-width: 10px;
    }

    &:before {
      top: -22px;
      left: 10px;
      border-color: transparent transparent #d2d2d2 transparent;
      border-width: 11px;
    }

    .tile-action__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 12px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #d2d2d2;
      }

      &:hover {
        text-decoration: underline;
      }

      span {
        transition: color 300ms;
        padding: 5px 0;
      }

      &--active {
        cursor: unset;

        &:hover {
          text-decoration: none;
        }

        span {
          color: $c-blue;
        }
      }

      &--active svg path {
        fill: $c-blue;
      }
    }
  }
}
